<template>
  <div>
    <v-data-table
      ref="maintenanceTableSalesperson"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="id"
      class="elevation-2"
      show-select
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.data.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.id="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.id }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.phone="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ formatPhone(item.phone) }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-container
            class="pl-1 pt-2 pr-1 pb-1"
          >
            <!-- Id -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-id
                  ref="editedId"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerId.text"
                  :originalData="maintenanceState.originalData"
                  :autofocus="(editedIndex < 0)"
                  :disabled="(editedIndex >= 0)"
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Description / Name -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-description
                  ref="editedDescription"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerName.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Phone -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-phone
                  ref="editedPhone"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerPhone.text"
                  :originalData="maintenanceState.originalData"
                  @change="field_onChange"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainPhone from '@/components/maintenance/controls/Phone'

  export default {
    name: 'SalespersonTable',

    components: {
      DeleteDialog,
      ContextMenu,
      MaintainId,
      MaintainDescription,
      MaintainPhone,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'formatPhone',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      customers: get('customer/customers'),
      displayAsSingular: get('salesperson/displayAsSingular'),
      displayAsPlural: get('salesperson/displayAsPlural'),
      maintenanceState: get('salesperson/salespeople'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disableNew () {
        let disabled = true

        if (this.customers.selectedItem) {
          if ({}.hasOwnProperty.call(this.customers.selectedItem, 'id')) {
            disabled = this.customers.selectedItem.id.length <= 0
          }
        }

        return disabled
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        } else {
          this.editedItem.phone = this.formatPhone(this.editedItem.phone)
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerId.width = '10%'
        this.maintenanceState.headers.headerName.width = '64%'
        this.maintenanceState.headers.headerPhone.width = '25%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerName)
        this.headers.push(this.maintenanceState.headers.headerPhone)
        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        this.$store.dispatch('salesperson/retrieveAll')

        this.setupHeaders()
      },

      setDialogEdit (val) {
        this.dialogEdit = val
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedPhone) {
          disabled = this.$refs.editedPhone.$refs.textField.hasError
        }

        return disabled
      },

      field_onChange () {
        const dataItem = this.editedItem
        const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)

        if (originalData.length > 0) {
          if (dataItem.phone !== originalData[0].phone) {
            this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
          }
        }

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('salesperson/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.$store.dispatch('salesperson/update', this.editedItem)
          }
        }
      },
    },
  }
</script>

<style scoped>

</style>
