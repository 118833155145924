<template>
  <v-menu
    bottom
    left
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          bordered
          color="red"
          overlap
        >
          <template v-slot:badge>
            <span>{{ new_count }}</span>
          </template>

          <v-icon color="accent">mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list
      dense
    >
      <v-overlay :value="keyDataIsBusy">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        />
      </v-overlay>

      <v-list-item
        v-if="getLengthOf(notifications.data) <= 0"
      >
        <v-list-item-content
          class="pl-1"
        >
          <v-list-item-title
            class="ma-0 pa-0"
          >
            No {{ displayAsPlural }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-for="(item, i) in notifications.data"
        :key="i"
        dense
        @click.prevent="notification_onClick($event, item)"
      >
        <v-list-item-icon
          class="mr-0 pr-0"
        >
          <v-badge
            v-if="showUnread(item)"
            color="red"
            content="1"
            dot
            left
            overlap
          >
            <v-icon
              v-if="item.icon !== null"
              v-text="item.icon"
              color="secondary"
            />
            <v-icon
              v-else
              color="secondary"
            >
              mdi-floor-plan
            </v-icon>
          </v-badge>
          <div
            v-else
          >
            <v-icon
              v-if="item.icon !== null"
              v-text="item.icon"
              color="secondary"
            />
            <v-icon
              v-else
              color="secondary"
            >
              mdi-floor-plan
            </v-icon>
          </div>
        </v-list-item-icon>
        <v-list-item-content
          class="pl-1"
        >
          <v-list-item-title
            class="ma-0 pa-0"
          >
            {{ item.message }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { DataStore } from '@aws-amplify/datastore'
  import { Notification } from '@/models'

  export default {
    name: 'DefaultNotifications',

    data: () => ({
      selectedNotification: null,
    }),

    computed: {
      users: get('user/users'),
      notifications: get('notification/notifications'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      assemblies: get('assembly/assemblies'),
      takeoffs: get('takeoff/takeoffs'),
      displayAsPlural: get('notification/displayAsPlural'),
      new_count () {
        let count = 0

        if (this.notifications.data !== null) {
          count = this.notifications.data.filter((obj) => this.showUnread(obj) === true).length
        }

        return count
      },
      keyDataIsBusy () {
        // The watch on this field keeps it polling when the isBusy's change
        if (!this.customers.isBusy && !this.plans.isBusy && !this.divisions.isBusy && !this.assemblies.isBusy && !this.takeoffs.isBusy) {
          // If data is no longer busy and the user has selected a notification then navigate to the route
          if (this.selectedNotification !== null) {
            this.routeNotification()
          }
        }
        return this.customers.isBusy && this.plans.isBusy && this.divisions.isBusy && this.assemblies.isBusy
      },
    },

    watch: {
      keyDataIsBusy: {
        handler (val, oldVal) {},
      },
    },

    created () {
      this.subscription = DataStore.observe(Notification).subscribe(obj => {
        // console.info('Notifications sub')
        this.initialize()
      })

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/setSelectedCustomerPlan':
            this.selectedNotification = { ...mutation.payload }
            break
        }
      })

      this.initialize()
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      initialize () {
        this.$store.dispatch('notification/retrieveAll')
      },

      showUnread (item) {
        let unread = true

        if (item !== null) {
          if ({}.hasOwnProperty.call(item, 'read_by')) {
            const readBy = [...item.read_by]

            if (typeof this.users.user !== 'undefined') {
              if (this.users.user.attributes !== null) {
                if ({}.hasOwnProperty.call(this.users.user.attributes, 'email')) {
                  unread = !readBy.includes(this.users.user.attributes.email)
                }
              }
            }
          }
        }

        return unread
      },

      routeNotification () {
        if (this.selectedNotification !== null) {
          if ({}.hasOwnProperty.call(this.selectedNotification, 'route')) {
            const routeTo = { ...this.selectedNotification }

            this.$nextTick(() => {
              this.selectedNotification = null
              // this.$store.dispatch('app/refreshKeys')
              // console.log(`TLH route = ${routeTo.route}`, routeTo)
              this.$router.push(routeTo.route)
            })
          }
        }
      },

      async notification_onClick (evt, item) {
        evt.preventDefault()

        switch (item.route) {
          case '/components/takeoff/':
          case '/components/plans/':
            this.$store.dispatch('plan/setSelectedCustomerPlan', item)
            break
          default:
            break
        }

        this.$store.dispatch('notification/update', item)
      },
    },
  }
</script>
