// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      route('Dashboard'),

      // User Pages
      route('User Profile', '@/views/UserProfileView', 'components/profile'),

      // Admin Pages
      route('Customers', '@/views/CustomersView', 'components/customers'),
      route('PlansArchived', '@/views/PlansArchivedView', '/components/archived-plans/'),
      route('Imports', '@/views/ImportsView', 'components/imports'),
      route('Exports', '@/views/ExportsView', 'components/exports'),

      // Maintenance Pages
      route('Plans', '@/views/PlansView', 'components/plans'),
      route('Divisions', '@/views/DivisionsView', 'components/divisions'),
      route('Assemblies', '@/views/AssembliesView', 'components/assemblies'),
      route('PromptGroups', '@/views/PromptGroupsView', 'components/prompt-groups'),
      route('Categories', '@/views/CategoriesView', 'components/categories'),
      route('Parts', '@/views/PartsView', 'components/parts'),
      route('DeliveryLoads', '@/views/DeliveryLoadsView', 'components/delivery-loads'),
      route('Usages', '@/views/UsagesView', 'components/usages'),
      route('Projects', '@/views/ProjectsView', 'components/projects'),
      route('Folders', '@/views/FoldersView', 'components/folders'),
      route('Estimators', '@/views/EstimatorsView', 'components/estimators'),
      route('Salespeople', '@/views/SalespeopleView', 'components/salespeople'),

      // Tools Pages
      route('PlanBuilder', '@/views/PlanBuilderView', 'components/plan-builder'),
      route('Copy', '@/views/CopyView', 'components/copy'),
      route('Takeoff', '@/views/TakeoffView', 'components/takeoff'),
      route('GlobalReplace', '@/views/GlobalReplaceView', 'components/global-replace'),

      // Report Pages
      route('Reports', '@/views/ReportsView', 'components/reports'),

      // Components
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),

      // Maps
      route('Google Maps', null, 'maps/google'),
    ]),
  ],
})

router.beforeEach((to, from, next) => {
  const adminPages = store.getters['app/getAdminPages']()

  // If Admin Page and user is not an admin, take them back to from page
  if (!store.state.user.users.isAdmin) {
    for (const adminPage of adminPages) {
      if (adminPage.title.replaceAll(' ', '') === to.name.replaceAll(' ', '')) {
        next({
          path: '/',
          query: { redirect: to.fullPath },
        })
        break
      }
    }
  }

  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
