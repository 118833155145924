<template>
  <!-- TODO: Deprecate this component or fix MaintenancePage to use forms and tables components and use it instead of this -->
  <v-container
    :id="`${$attrs.id.toLowerCase().replaceAll(' ', '-')}-view`"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>{{ $attrs.id }}</h3>
                </v-col>

                <v-col
                  sm="4"
                  class="text-right"
                >
                  <slot name="actions" />
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container
              class="py-0 pb-5"
            >
              <!-- Key Selection & Key Actions Slots -->
              <v-row
                v-if="hasKeySelection"
                align="end"
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="8"
                  md="7"
                  lg="6"
                  xl="5"
                >
                  <slot name="keyselection" />
                </v-col>

                <v-col
                  class="text-right"
                >
                  <slot name="keyactions" />
                </v-col>
              </v-row>
              <!-- Content Slot -->
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <slot name="content" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import MaterialCard from '@/components/MaterialCard'

  export default {
    name: 'QuickQuotePage',

    components: {
      MaterialCard,
    },

    data () {
      return {
        appItem: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
      ]),
      hasKeySelection () {
        return !!(
          this.$slots.keyselection
        )
      },
      disableNew () {
        let disabled = true

        if (this.$attrs.selectedkey) {
          if ({}.hasOwnProperty.call(this.$attrs.selectedkey, 'id')) {
            disabled = this.$attrs.selectedkey.id.length <= 0
          }
        }

        return disabled
      },
    },

    created () {
      this.appItem = this.getAppItem(this.$attrs.optionsname)
      this.$store.dispatch('user/setPageName', this.$attrs.optionsname)
    },
  }
</script>

<style scoped>

</style>
