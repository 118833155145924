<template>
  <v-card>
    <v-card-title
      v-if="showTitle"
    >
      <v-container>
        <v-row no-gutters>
          <v-col
            cols="12"
          >
            <h2
              class="primary--text"
            >
              {{ formTitle }}
            </h2>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-container
        class="pl-1 pt-2 pr-1 pb-1"
      >
        <!-- Id & Status & Dates -->
        <v-row
          no-gutters
          class="mb-3"
        >
          <!-- ID -->
          <v-col
            cols="12"
            sm="3"
          >
            <maintain-id
              ref="editedId"
              v-model="internalValue"
              :label="maintenanceState.headers.headerId.text"
              :originalData="maintenanceState.originalData"
              :autofocus="(editedIndex < 0)"
              :disabled="(editedIndex >= 0) || users.isGuest"
              :required="true"
              @change="disableEdit = getDisableEdit()"
            />
          </v-col>

          <!-- Dates -->
          <v-col
            cols="12"
            offset-sm="1"
            sm="4"
          >
            <!-- TODO: Make StatusCode Component -->
            <div
              v-if="users.isGuest"
            >
              <span
                class="pa-0 ma-0 text-body-1 font-weight-regular"
              >
                {{ maintenanceState.headers.headerStatus.text }}:
                <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                  {{ (internalValue.status_cd ? maintenanceState.StatusCode[internalValue.status_cd] : '') }}
                </span>
              </span>
            </div>
            <select-enum-string
              v-else
              ref="statusCd"
              v-model="internalValue.status_cd"
              :label="maintenanceState.headers.headerStatus.text"
              :enum="maintenanceState.StatusCode"
              :required="true"
              @blur="disableEdit = getDisableEdit()"
              @change="status_onChange"
            />
            <div
              class="pa-0 ma-0 font-weight-regular"
            >
              Submitted:
              <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                {{ ( internalValue.submitted_date ? displayDate(internalValue.submitted_date) : '') }}
              </span>
            </div>
            <div
              v-if="internalValue.received_date"
              class="pa-0 ma-0 font-weight-regular"
            >
              Received:
              <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                {{ ( internalValue.received_date ? displayDate(internalValue.received_date) : '') }}
              </span>
            </div>
            <div
              v-if="internalValue.finish_date"
              class="pa-0 ma-0 font-weight-regular"
            >
              Completed:
              <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                {{ ( internalValue.finish_date ? displayDate(internalValue.finish_date) : '') }}
              </span>
            </div>
          </v-col>

          <!-- Notify Detec -->
          <v-col
            v-if="users.isCustomer"
            cols="12"
            offset-sm="1"
            sm="3"
          >
            <v-checkbox
              v-model="internalValue.notify"
              color="primary"
              dense
              :disabled="users.isGuest"
            >
              <template v-slot:label>
                <div
                  :class="($vuetify.theme.dark ? 'white--text text-body-1' : 'black--text text-body-1')"
                >
                  Notify Detec
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <!-- Description -->
        <v-row
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
            sm="12"
          >
            <maintain-description
              ref="editedDescription"
              v-model="internalValue"
              :label="maintenanceState.headers.headerDescription.text"
              :originalData="maintenanceState.originalData"
              :autofocus="(editedIndex >= 0)"
              :required="true"
              @change="disableEdit = getDisableEdit()"
            />
          </v-col>
        </v-row>
        <!-- Estimator & Return Code / Due Date -->
        <v-row
          no-gutters
          class="mb-3"
        >
          <v-col
            v-if="!users.isGuest"
            cols="12"
            sm="6"
          >
            <maintain-estimator
              :key="renderKey"
              ref="editedEstimator"
              v-model="internalValue"
              :label="maintenanceState.headers.headerEstimator.text"
              :originalData="maintenanceState.originalData"
              :combobox="true"
              @change="estimator_onChange"
            />
          </v-col>

          <v-col
            cols="12"
            offset-sm="1"
            sm="5"
          >
            <!-- TODO: Make ReturnTime Component -->
            <div
              v-if="returnTime_allowEdit()"
              class="pa-0 ma-0"
            >
              <select-enum-number
                ref="returnCd"
                v-model="internalValue.return_cd"
                :label="maintenanceState.headers.headerReturnTime.text"
                :enum="maintenanceState.ReturnCode"
                :required="true"
                :hint="getReturnCodeHint()"
                @blur="disableEdit = getDisableEdit()"
                @change="return_onChange"
              />

              <date-field
                v-if="!users.isGuest"
                ref="dueDate"
                v-model="internalValue.due_date"
                label="Due Date"
                :show-read-only-date="!users.isAdmin"
                @change="due_onChange"
              />
            </div>

            <div v-else>
              <span
                class="pa-0 ma-0 text-body-1 font-weight-regular"
              >
                {{ maintenanceState.headers.headerReturnTime.text }}:
                <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                  {{ (internalValue.return_cd ? maintenanceState.ReturnCode[internalValue.return_cd] : '') }}
                </span>
              </span>

              <div
                class="pa-0 ma-0 pb-1 font-weight-regular"
              >
                Due:
                <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                  {{ ( internalValue.due_date ? displayDate(internalValue.due_date) : '') }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- Salesperson -->
        <v-row
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
            :sm="(createSalesperson ? 5 : 6)"
          >
            <maintain-salesperson
              id="editedSalesperson"
              ref="editedSalesperson"
              :key="renderKey"
              v-model="internalValue"
              :label="maintenanceState.headers.headerSalesperson.text"
              :originalData="maintenanceState.originalData"
              :combobox="true"
              @change="salesperson_onChange"
            />
          </v-col>

          <v-col
            v-if="createSalesperson"
            cols="12"
            offset-sm="1"
            sm="2"
          >
            <v-text-field
              ref="salespersonId"
              v-model="internalValue.salesperson_id"
              required
              dense
              clearable
              class="font-weight-medium text-body-1"
              maxlength="10"
              :rules="[appRules.isPrimaryId, appRules.idExists(internalValue.salesperson_id, salespeople.data)]"
              @change="salespersonId_onChange"
              @click="input_onClick"
            >
              <template v-slot:label>
                <v-icon
                  class="required-icon"
                  x-small
                >
                  mdi-asterisk
                </v-icon>
                New ID
              </template>
            </v-text-field>
          </v-col>

          <v-col
            v-if="createSalesperson"
            cols="12"
            offset-sm="1"
            sm="3"
          >
            <v-btn
              text
              color="primary"
              class="font-weight-medium"
              :disabled="disableCreateSalesperson"
              @click="createSalesperson_onClick"
            >
              Create New Salesperson
            </v-btn>
          </v-col>
        </v-row>
        <!-- Upload Files -->
        <v-row
          v-if="users.isGuest || users.isAdmin"
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
            sm="8"
            class="text-left"
          >
            <v-file-input
              v-model="filesToUpload"
              truncate-length="50"
              multiple
              show-size
              dense
              @change="filesUploaded"
            >
              <template v-slot:label>
                <span class="font-weight-bold">
                  {{ displayAsSingular }} Documents to Upload
                </span>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <!-- Folder -->
        <v-row
          v-if="!users.isGuest"
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <maintain-folder
              :key="renderKey"
              ref="editedFolder"
              v-model="internalValue"
              label="Folder"
              :originalData="maintenanceState.originalData"
              :combobox="true"
              @change="disableEdit = getDisableEdit()"
            />
          </v-col>

          <v-col
            v-if="createFolder"
            cols="12"
            offset-sm="1"
            sm="5"
          >
            <v-btn
              text
              color="primary"
              class="font-weight-medium"
              @click="createFolder_onClick"
            >
              Create {{ displayAsSingularEstimator }}'s Folder
            </v-btn>
          </v-col>
        </v-row>
        <!-- SqFt -->
        <v-row
          v-if="!users.isGuest"
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              ref="editedHeatedSqFt"
              v-model="internalValue.heated_sqft"
              :label="maintenanceState.headers.headerHeatedSqFt.text"
              dense
              clearable
              class="font-weight-medium text-body-1 numeric-input"
              :rules="[appRules.isNumeric]"
              @blur="sqft_updated(internalValue)"
              @change="sqft_updated(internalValue)"
              @click="input_onClick"
            />
          </v-col>

          <v-col
            cols="12"
            offset-sm="1"
            sm="3"
          >
            <v-text-field
              ref="editedUnheatedSqFt"
              v-model="internalValue.unheated_sqft"
              :label="maintenanceState.headers.headerUnheatedSqFt.text"
              dense
              clearable
              class="font-weight-medium text-body-1 numeric-input"
              :rules="[appRules.isNumeric]"
              @blur="sqft_updated(internalValue)"
              @change="sqft_updated(internalValue)"
              @click="input_onClick"
            />
          </v-col>

          <v-col
            cols="12"
            sm="5"
          >
            <span
              class="pl-6 pt-0 pr-0 pb-0 ma-0 text-body-1 font-weight-regular"
            >
              Total sqft:
              <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                {{ ((internalValue.heated_sqft * 1) + (internalValue.unheated_sqft * 1)).toLocaleString() }}
              </span>
            </span>
          </v-col>
        </v-row>
        <!-- Address -->
        <v-row
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
          >
            <maintain-address
              ref="editedAddress"
              v-model="internalValue"
              :originalData="maintenanceState.originalData"
              @change="disableEdit = getDisableEdit()"
            />
          </v-col>
        </v-row>
        <!-- Note -->
        <v-row
          no-gutters
          class="mb-3"
        >
          <v-col
            cols="12"
          >
            <maintain-note
              ref="editedNote"
              v-model="internalValue"
              :label="maintenanceState.headers.headerNote.text"
              :originalData="maintenanceState.originalData"
              @change="disableEdit = getDisableEdit()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions
      v-if="showActions"
    >
      <v-spacer />
      <v-btn
        color="secondary"
        @click="cancelEdit"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        :disabled="disableEdit"
        @click="saveEdit"
      >
        OK
      </v-btn>
    </v-card-actions>

    <v-overlay :value="maintenanceState.isBusy">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import { Storage } from 'aws-amplify'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainEstimator from '@/components/maintenance/controls/Estimator'
  import MaintainSalesperson from '@/components/maintenance/controls/Salesperson'
  import MaintainFolder from '@/components/maintenance/controls/Folder'
  import MaintainAddress from '@/components/maintenance/controls/Address'
  import MaintainNote from '@/components/maintenance/controls/Note'
  import SelectEnumString from '@/components/maintenance/controls/SelectEnumString'
  import SelectEnumNumber from '@/components/maintenance/controls/SelectEnumNumber'
  import DateField from '@/components/maintenance/controls/DateField'

  export default {
    name: 'EditPlan',

    components: {
      DateField,
      MaintainId,
      MaintainDescription,
      MaintainEstimator,
      MaintainSalesperson,
      MaintainFolder,
      MaintainAddress,
      MaintainNote,
      SelectEnumString,
      SelectEnumNumber,
    },

    props: {
      showTitle: {
        type: Boolean,
        default: true,
      },
      showActions: {
        type: Boolean,
        default: true,
      },
      editedIndex: {
        type: Number,
        default: -1,
      },
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        disableEdit: true,
        renderKey: 0,
        filesToUpload: null,
        createSalesperson: false,
        newSalesperson: null,
        disableCreateSalesperson: true,
        newSalespersonAdded: false,
        createFolder: false,
        newFolder: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'getValidDate',
        'formatDate',
        'addDays',
        'getDocumentsPath',
      ]),
      ...mapGetters('folder', [
        'createFolderId',
        'createFolderDescription',
      ]),
      appRules: get('app/rules'),
      users: get('user/users'),
      customers: get('customer/customers'),
      folders: get('folder/folders'),
      estimators: get('estimator/estimators'),
      salespeople: get('salesperson/salespeople'),
      displayAsSingularEstimator: get('estimator/displayAsSingular'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      maintenanceState: get('plan/plans'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    created () {
      this.internalValue.notify = (this.editedIndex === -1)

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'salesperson/successfulUpdate':
            this.addSalesperson_onSuccess()
            break
          case 'folder/push2Data':
            if (this.newFolder) {
              this.estimator_onChange()
            }

            break
          default:
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      forceRerender () {
        this.$nextTick(() => {
          this.renderKey += 1
        })
      },

      getDisableEdit () {
        let disabled = (this.internalValue.updated ? !this.internalValue.updated : true)

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        // TODO: This is a hack, because I can't figure out how to assign a valid value and make hasError satisfied, get rid of newSalespersonAdded when this is done properly
        if (!this.newSalespersonAdded) {
          if (!disabled && this.$refs.editedSalesperson) {
            disabled = this.$refs.editedSalesperson.$refs.combobox.hasError
          }
        }

        if (!disabled && this.$refs.editedAddress) {
          disabled = this.$refs.editedAddress.hasError()
        }

        if (!disabled && this.$refs.returnCd) {
          disabled = this.$refs.returnCd.$refs.selectenum.hasError
        }

        if (!this.users.isGuest) {
          // Validation for non-Guests...
          if (!disabled && this.$refs.statusCd) {
            disabled = this.$refs.statusCd.$refs.selectenum.hasError
          } else {
            disabled = true
          }

          if (!disabled && this.$refs.editedEstimator) {
            disabled = this.$refs.editedEstimator.$refs.combobox.hasError
          }

          if (!disabled && this.$refs.editedFolder) {
            disabled = this.$refs.editedFolder.$refs.combobox.hasError
          }

          if (!disabled && this.$refs.editedHeatedSqFt) {
            disabled = this.$refs.editedHeatedSqFt.hasError
          }

          if (!disabled && this.$refs.editedUnheatedSqFt) {
            disabled = this.$refs.editedUnheatedSqFt.hasError
          }
        }

        return disabled
      },

      displayDate (thisDate) {
        return this.formatDate(thisDate)
      },

      setSelectedFolder (selectedItem) {
        this.$store.dispatch('folder/setSelected', selectedItem)
      },

      async getEstimatorsFolder () {
        this.newFolder = null
        let selectedFolder = null
        this.createFolder = false

        if (this.internalValue && typeof this.internalValue.estimator_id !== 'undefined') {
          if (this.internalValue.estimator_id.length > 0) {
            const folderId = this.createFolderId(this.internalValue.estimator_id)
            const selectedItems = await this.getDataById(this.folders.data, folderId)

            if (selectedItems && selectedItems.length > 0) {
              selectedFolder = selectedItems[0]
              this.internalValue.folder_id = folderId
              this.createFolder = false
            } else {
              selectedFolder = null
              this.internalValue.folder_id = null
              this.createFolder = true
            }
          }
        }

        this.setSelectedFolder(selectedFolder)
      },

      status_onChange () {
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
        this.$nextTick(() => {
          switch (this.internalValue.status_cd) {
            case 'progress':
              if (this.internalValue.received_date === null) {
                this.internalValue.received_date = this.getValidDate(null)
              }

              break
            case 'complete':
              if (this.internalValue.finish_date === null) {
                this.internalValue.finish_date = this.getValidDate(null)
              }

              break
            default:
              break
          }

          this.disableEdit = this.getDisableEdit()
        })
      },

      estimator_onChange () {
        this.getEstimatorsFolder()
        this.disableEdit = this.getDisableEdit()
      },

      returnTime_allowEdit () {
        // Allow Return Time Edit if Guest is uploading a new plan...
        let allowEdit = this.users.isGuest && this.editedIndex === -1

        // Allow Return Time Edit if Admin
        if (this.users.isAdmin) {
          allowEdit = true
        }

        return allowEdit
      },

      getReturnCodeHint () {
        let hint = null

        if (this.users.isGuest) {
          hint = `Due Date: ${this.displayDate(this.internalValue.due_date)}`
        }

        return hint
      },

      return_onChange () {
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
        this.$nextTick(() => {
          if (this.internalValue.return_cd) {
            this.internalValue.due_date = this.addDays(this.internalValue.submitted_date, this.internalValue.return_cd).toString()
          } else {
            this.internalValue.due_date = null
          }
          this.disableEdit = this.getDisableEdit()
        })
      },

      due_onChange (evt) {
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      async setCreateSalesperson () {
        this.createSalesperson = true

        if (this.internalValue && typeof this.internalValue.salesperson_id !== 'undefined') {
          if (this.internalValue.salesperson_id.length > 0) {
            const selectedItems = await this.getDataById(this.salespeople.data, this.internalValue.salesperson_id)

            if (selectedItems && selectedItems.length > 0) {
              this.createSalesperson = false
            } else {
              this.createSalesperson = true
            }
          }
        }
      },

      salesperson_onChange () {
        this.setCreateSalesperson()
        this.disableEdit = this.getDisableEdit()
      },

      salespersonId_onChange () {
        this.disableCreateSalesperson = true

        if (this.internalValue && {}.hasOwnProperty.call(this.internalValue, 'salesperson_id')) {
          if (this.$refs.editedSalesperson && this.$refs.editedSalesperson.$refs.combobox && this.$refs.editedSalesperson.$refs.combobox.hasError) {
            if (!this.$refs.salespersonId.hasError) {
              this.disableCreateSalesperson = false
            }
          }
        }
      },

      createSalesperson_onClick () {
        this.newSalesperson = { ...this.salespeople.empty }

        this.$store.dispatch('user/setStateAsUpdated', this.newSalesperson)

        this.internalValue.salesperson_description = this.$refs.editedSalesperson.getNewDescription()

        this.newSalesperson.id = this.internalValue.salesperson_id
        this.newSalesperson.salesperson_id = this.internalValue.salesperson_id
        this.newSalesperson.description = this.internalValue.salesperson_description

        this.$store.dispatch('salesperson/update', this.newSalesperson)
      },

      async addSalesperson_onSuccess () {
        this.newSalespersonAdded = true

        this.$store.dispatch('salesperson/setSelected', this.newSalesperson)
        await this.$store.dispatch('user/setStateAsUpdated', this.internalValue)

        this.createSalesperson = false
        this.disableEdit = this.getDisableEdit()

        this.$nextTick(() => {
          this.newSalespersonAdded = false
          // this.forceRerender()
        })
      },

      filesUploaded () {
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
        this.disableEdit = this.getDisableEdit()
      },

      async createFolder_onClick () {
        const folderId = this.createFolderId(this.internalValue.estimator_id)

        this.newFolder = { ...this.folders.empty }

        this.newFolder.estimator_id = this.internalValue.estimator_id
        this.newFolder.id = folderId
        this.newFolder.folder_id = folderId
        this.newFolder.description = this.createFolderDescription(this.estimators.selectedItem.name)

        await this.$store.dispatch('user/setStateAsUpdated', this.newFolder)

        this.$store.dispatch('folder/update', this.newFolder)
      },

      // TODO: Make SquareFoot control
      sqft_updated (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.heated_sqft !== originalData[0].heated_sqft || dataItem.unheated_sqft !== originalData[0].unheated_sqft) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }

        this.disableEdit = this.getDisableEdit()
      },

      doneEditing () {
        this.$emit('close')
      },

      cancelEdit () {
        this.$emit('cancel')
      },

      async uploadFiles () {
        const docPath = this.getDocumentsPath(this.internalValue)

        if (docPath.length > 0) {
          for (const currentFile of this.filesToUpload) {
            await Storage.put(`${docPath}/${currentFile.name}`, currentFile)
              .then((result) => {
                console.log(`File '${currentFile.name}' uploaded successfully`)
                this.internalValue.documents_count++
              })
              .catch(err => {
                this.$store.dispatch('error/setError', { name: 'Uploading Document', details: err })
              })
          }
        }
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.doneEditing()

          if (this.internalValue.updated) {
            this.internalValue.client_id = this.customers.selectedItem.client_id

            this.$store.dispatch('plan/update', this.internalValue)
          }

          if (this.filesToUpload !== null) {
            this.uploadFiles()
          }

          this.$emit('save')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.numeric-input >>> input {
  text-align: right;
  min-width: 150px;
}
</style>
