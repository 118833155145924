// Pathify
import { make } from 'vuex-pathify'
import _ from 'lodash'

const endpoint = 'https://1giaejlmcf.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Archive',
  displayAsPlural: 'Archives',
  archives: {
    data: [],
    plans: [],
    isBusy: false,
    selected: [],
    empty: {
      plan_id: null,
    },
  },
}

function getSuccessMessages (rootState, dataFromDB, actionText) {
  const successMessages = []

  if (dataFromDB.length > 0) {
    const dataResponse = dataFromDB[0]
    const totalPlanRows = dataResponse.total_plan_rows
    const totalDivisionRows = dataResponse.total_division_rows
    const totalAssemblyRows = dataResponse.total_assembly_rows
    const totalAssemblyItemRows = dataResponse.total_assembly_item_rows

    successMessages.push(`${totalPlanRows.toLocaleString()} ${(totalPlanRows === 1 ? rootState.plan.displayAsSingular : rootState.plan.displayAsPlural)} ${actionText} successfully`)
    successMessages.push(`${totalDivisionRows.toLocaleString()} ${(totalDivisionRows === 1 ? rootState.division.displayAsSingular : rootState.division.displayAsPlural)}`)
    successMessages.push(`${totalAssemblyRows.toLocaleString()} ${(totalAssemblyRows === 1 ? rootState.assembly.displayAsSingular : rootState.assembly.displayAsPlural)}`)
    successMessages.push(`${totalAssemblyItemRows.toLocaleString()} ${(totalAssemblyItemRows === 1 ? rootState.assemblyitem.displayAsSingular : rootState.assemblyitem.displayAsPlural)}`)
  }

  return successMessages
}
const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.archives.isBusy = isBusy
  },

  push2Data: (state, dataFromDB) => {
    state.archives.data = []
    state.archives.data = dataFromDB
    state.archives.isBusy = false
  },

  push2Plans: (state, dataFromDB) => {
    state.archives.plans = []
    state.archives.plans = dataFromDB
    state.archives.isBusy = false
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: () => {
    state.archives.data = []
  },

  retrieveAllPlans: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveArchivedPlans'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2Plans', jsonResponse.data)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  archivePlans: ({ commit, dispatch, rootState }) => {
    const thisAction = 'archivePlan'
    commit(setIsBusy, true)

    const selectedCustomerId = (typeof rootState.customer.customers.selectedItem.client_id === 'undefined' ? null : rootState.customer.customers.selectedItem.client_id)

    const selectedPlans = []
    for (const thisItem of rootState.plan.plans.selected) {
      selectedPlans.push({ plan_id: thisItem.plan_id })
    }

    if (selectedCustomerId && selectedPlans.length > 0) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${selectedCustomerId}`

      const options = {
        method: 'post',
        body: JSON.stringify(selectedPlans),
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2Data', jsonResponse.data)

            const toastColor = 'success'
            const successMessages = getSuccessMessages(rootState, jsonResponse.data, 'archived')

            dispatch('user/showToast',
              {
                title: `${rootState.plan.displayAsPlural} Archived`,
                messages: successMessages,
                variant: toastColor,
                timeout: 8000,
              },
              { root: true },
            )
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .then(() => {
          dispatch('plan/retrieveAll', null, { root: true })
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    } else {
      commit(setIsBusy, false)
    }
  },

  restorePlans: ({ commit, dispatch, rootState }) => {
    const thisAction = 'restorePlan'
    commit(setIsBusy, true)

    const selectedCustomerId = (typeof rootState.customer.customers.selectedItem.client_id === 'undefined' ? null : rootState.customer.customers.selectedItem.client_id)

    const selectedPlans = []
    for (const thisItem of state.archives.selected) {
      selectedPlans.push({ plan_id: thisItem.plan_id })
    }

    if (selectedCustomerId && selectedPlans.length > 0) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${selectedCustomerId}`

      const options = {
        method: 'post',
        body: JSON.stringify(selectedPlans),
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2Data', jsonResponse.data)

            const toastColor = 'success'
            const successMessages = getSuccessMessages(rootState, jsonResponse.data, 'restored')

            dispatch('user/showToast',
              {
                title: `${rootState.plan.displayAsPlural} Restored`,
                messages: successMessages,
                variant: toastColor,
                timeout: 8000,
              },
              { root: true },
            )
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .then(() => {
          dispatch('retrieveAllPlans')
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    } else {
      commit(setIsBusy, false)
    }
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
