// Pathify
import { make } from 'vuex-pathify'
import { importChunk } from '@/util/helpers'

const _ = require('lodash')

const endpoint = 'https://qdtthkrv49.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'
const setIsBusyAll = 'setIsBusyAll'

const state = {
  displayAsSingular: 'Assembly Item',
  displayAsPlural: 'Assembly Items',
  assemblyItems: {
    data: [],
    originalData: [],
    toImport: [],
    isBusy: false,
    isBusyAll: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    empty: {
      id: null,
      assemblyitem_id: null,
      client_id: null,
      plan_id: null,
      phase_id: null,
      assembly_id: null,
      vendor_id: null,
      category_description: null,
      part_id: null,
      description: null,
      part_id_description: null,
      order_quantity: 0,
      unit_cost: 0,
      unit_price: 0,
      extPrice: 0,
      manual_quantity: false,
      unit: null,
      delivery_load_id: '',
      delivery_load_description: '',
      usage_id: '',
      usage_description: '',
      waste_factor: 0,
      formula: '',
      note: '',
      tallySheet: [],
      parts: [],
      purchase_order_flag: false,
      round_factor: 0,
      service_type_id: null,
      sort_order: 0,
      tax_amount: 0,
      tax_id: null,
      cost_code_id: null,
      updated: false,
      _showDetails: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerCategory: {
        text: 'Category',
        align: 'start',
        value: 'vendor_id',
      },
      headerQuantity: {
        text: 'Quantity',
        align: 'end',
        value: 'order_quantity',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerUnitType: {
        text: 'Unit Type',
        align: 'start',
        value: 'unit',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerPartSKU: {
        text: 'Part/SKU',
        align: 'start',
        value: 'part_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerPartDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerDeliveryLoad: {
        text: 'Load',
        align: 'start',
        value: 'delivery_load_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerUsage: {
        text: 'Usage',
        align: 'start',
        value: 'usage_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerWaste: {
        text: 'Waste %',
        align: 'end',
        value: 'waste_factor',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap text-right',
      },
      headerUnitPrice: {
        text: 'Unit Price',
        align: 'end',
        value: 'unit_price',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerExtPrice: {
        text: 'Ext. Price',
        align: 'end',
        value: 'extPrice',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
    tabbedColumns: {
      description_header: 'Assembly Item Description',
      waste_header: 'Waste',
      formula_header: 'Formula',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.assemblyItems.isBusy = isBusy
  },

  setIsBusyAll: (state, isBusyAll) => {
    state.assemblyItems.isBusyAll = isBusyAll
  },

  push2All: (state, dataFromDB) => {
    state.assemblyItems.all = []

    // Get a copy of the original data, not a pointer to it
    for (const currentData of dataFromDB) {
      state.assemblyItems.all.push({ ...currentData })
    }

    state.assemblyItems.isBusyAll = false
  },

  push2Data: (state, dataFromDB) => {
    for (const currentData of dataFromDB) {
      // Make sure manual_quantity is boolean
      currentData.manual_quantity = Boolean(currentData.manual_quantity)
      // Make sure tallySheet is an actual JSON array and not a string
      currentData.tallySheet = JSON.parse(currentData.tallySheet)
      // Make sure updated is false
      currentData.updated = false
    }

    state.assemblyItems.data = []
    state.assemblyItems.data = dataFromDB

    // Get a copy of the original data, not a pointer to it
    state.assemblyItems.originalData = []
    for (const currentData of dataFromDB) {
      state.assemblyItems.originalData.push({ ...currentData })
    }

    state.assemblyItems.selected = []

    state.assemblyItems.isBusy = false
  },

  push2Import: (state, data2Import) => {
    state.assemblyItems.toImport = []
    state.assemblyItems.toImport = data2Import
    state.assemblyItems.isBusy = false
  },

  successfulUpdateAll: (state) => {},

  importCompleted: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: () => {
    state.assemblyItems.selected = []
    state.assemblyItems.selectedItem = { id: '', description: '' }
    state.assemblyItems.data = []
  },

  setSelected: ({ dispatch }, payload) => {
    if (payload) {
      state.assemblyItems.selected = []
      state.assemblyItems.selectedItem = { ...payload }
    } else {
      state.assemblyItems.selectedItem = { id: '', description: '' }
    }
  },

  retrieveAll: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0 && rootState.plan.plans.selectedItem.id.length > 0) {
      const thisAction = 'retrieveAllAssemblyItems'
      commit(setIsBusyAll, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}/${rootState.plan.plans.selectedItem.id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          commit('push2All', jsonResponse.data)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusyAll, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieve: ({ commit, dispatch, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      if (payload &&
        {}.hasOwnProperty.call(payload, 'client_id') &&
        {}.hasOwnProperty.call(payload, 'plan_id') &&
        {}.hasOwnProperty.call(payload, 'phase_id') &&
        {}.hasOwnProperty.call(payload, 'assembly_id')) {
        const thisAction = 'retrieveAssemblyItem'
        commit(setIsBusy, true)

        const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
        const url = `${actionEndPoint}/${payload.client_id}/${payload.plan_id}/${payload.phase_id}/${payload.assembly_id}`

        const options = {
          method: 'get',
        }

        fetch(url, options)
          .then(response => {
            const statusMessage = `${response.status}: "${response.statusText}"`

            if (!response.ok) {
              throw Error(statusMessage)
            }

            return response.json()
          })
          .then(jsonResponse => {
            if (_.isEmpty(jsonResponse.error)) {
              commit('push2Data', jsonResponse.data)
            } else {
              commit(setIsBusy, false)
            }

            resolve(jsonResponse)
          })
          .catch(error => {
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
            reject(error)
          })
      } else {
        resolve(null)
      }
    })
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updateAssemblyItems'
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    // Get a copy of the payload to format for the request body without effecting state
    const item2Update = { ...payload }

    item2Update.tallySheet = JSON.stringify(item2Update.tallySheet)

    const options = {
      method: 'post',
      body: JSON.stringify(item2Update),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          dispatch('retrieve', payload)
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${payload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  async updateAll ({ commit, dispatch, rootGetters, rootState, state }, updatedItems) {
    const thisAction = 'updateAssemblyItems'

    if (updatedItems.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      for (const item2Update of updatedItems) {
        item2Update.tallySheet = JSON.stringify(item2Update.tallySheet)
      }

      const options = {
        method: 'post',
        body: JSON.stringify(updatedItems),
      }

      let toastColor = 'success'
      let toastMessage = 'updated successfully'

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            dispatch('retrieve', updatedItems[0])
          } else {
            toastColor = 'danger'
            toastMessage = 'were not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsPlural} Updated - ${updatedItems.length} items ${toastMessage}, variant: ${toastColor}`)
          commit('successfulUpdateAll')
          dispatch('takeoff/setIsBusy', false, { root: true })
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Updated`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
           */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('takeoff/setIsBusy', false, { root: true })
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.assemblyItems.selected.length > 0) {
      const thisAction = 'deleteAssemblyItems'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.assemblyItems.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.assemblyItems.selected.length > 1) {
        toastPrefix = `${state.assemblyItems.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.assemblyItems.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            dispatch('retrieve', state.assemblyItems.selected[0])
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
           */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  importAll: async ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'updateAssemblyItems'

    if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const emptyItem = { ...state.assemblyItems.empty }
      dispatch('user/setStateAsUpdated', emptyItem, { root: true })

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(payload.length / 500)
      const maxSize = Math.ceil(payload.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Import = []
      let totalAttempted = 0
      let items2Import = []
      let item2Import = {}

      for (const currentItem of payload) {
        // Ensure we have all required data and the proper selected client
        item2Import = { ...emptyItem }

        item2Import.plan_id = currentItem.plan_id
        item2Import.phase_id = currentItem.phase_id
        item2Import.assembly_id = currentItem.assembly_id
        item2Import.vendor_id = currentItem.vendor_id
        item2Import.part_id = currentItem.part_id
        item2Import.description = currentItem.description
        item2Import.order_quantity = currentItem.order_quantity
        item2Import.unit = currentItem.unit
        item2Import.delivery_load_id = currentItem.delivery_load_id
        item2Import.usage_id = currentItem.usage_id
        item2Import.waste_factor = currentItem.waste_factor
        item2Import.formula = currentItem.formula

        items2Import.push(item2Import)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))

          totalAttempted += chunkSize
          chunkSize = 0
          items2Import = []
        }
      }

      if (chunkSize > 0) {
        chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))
        totalAttempted += chunkSize
      }

      let toastColor = 'success'
      let toastMessage = 'imported successfully'

      if (chunks2Import.length > 0) {
        // Once all promises are resolved we have a successful import, or display rejected error
        Promise.all(chunks2Import)
          .then(responses => {
            dispatch('importData/setImportCount', { sheetName: state.displayAsPlural, importCount: totalAttempted }, { root: true })
            dispatch('importData/setSelectedIsComplete', state.displayAsPlural, { root: true })
            commit('importCompleted')

            const selectedAssembly = { ...rootState.assembly.assemblies.selectedItem }
            dispatch('retrieve', selectedAssembly)

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)
              } else {
                toastColor = 'danger'
                toastMessage = 'were not imported successfully'
                console.error(`${thisAction} failed: items ${toastMessage}, variant: ${toastColor}`, responses)
                commit(setIsBusy, false)
                dispatch('importData/setIsBusy', false, { root: true })
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }
          })
          .catch(error => {
            console.error(`${thisAction} failed for ${state.displayAsSingular}`)
            commit(setIsBusy, false)
            dispatch('importData/setIsBusy', false, { root: true })
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        console.error(`${thisAction} failed for ${state.displayAsSingular} due to empty chunks`)
        commit(setIsBusy, false)
        dispatch('importData/setIsBusy', false, { root: true })

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `Import ${state.displayAsPlural}`,
            messages: [`No ${state.displayAsPlural.toLowerCase()} were imported`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } // client_id & payload length > 0
  },

  push2Import: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Push ${state.displayAsPlural} to Import`

    if (payload && {}.hasOwnProperty.call(payload, 'sheetData')) {
      if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.sheetData.length > 0) {
        commit(setIsBusy, true)

        const emptyItem = {
          id: null,
          assembly_id: null,
          phase_id: null,
          plan_id: null,
          description: null,
          vendor_id: null,
          part_id: null,
          note: '',
          order_quantity: 0,
          unit: '',
          delivery_load_id: null,
          usage_id: null,
          waste_factor: null,
          formula: null,
        }
        const items2Import = []
        let item2Import = {}

        for (const currentItem of payload.sheetData) {
          item2Import = { ...emptyItem }

          item2Import.id = null
          item2Import.plan_id = currentItem[rootState.plan.plans.tabbedColumns.id_header]
          item2Import.phase_id = currentItem[rootState.division.divisions.tabbedColumns.id_header]
          item2Import.assembly_id = currentItem[rootState.assembly.assemblies.tabbedColumns.id_header]
          item2Import.description = currentItem[state.assemblyItems.tabbedColumns.description_header]
          item2Import.vendor_id = currentItem[rootState.category.categories.tabbedColumns.id_header]
          item2Import.part_id = currentItem[rootState.part.parts.tabbedColumns.id_header]
          item2Import.delivery_load_id = currentItem[rootState.deliveryload.deliveryloads.tabbedColumns.id_header]
          item2Import.usage_id = currentItem[rootState.usage.usages.tabbedColumns.id_header]
          item2Import.waste_factor = currentItem[state.assemblyItems.tabbedColumns.waste_header]
          item2Import.formula = currentItem[state.assemblyItems.tabbedColumns.formula_header]

          items2Import.push(item2Import)
        }

        dispatch('importData/setRows', { sheetName: state.displayAsPlural, sheetRows: items2Import.length }, { root: true })
        commit('push2Import', items2Import)
      }
    }
  },

  executeImport: ({ dispatch }) => {
    dispatch('importAll', state.assemblyItems.toImport)
  },
}

const getters = {
  // payload = { plan_id, phase_id, assembly_id, assemblyitem_id }
  getOriginalAssemblyItem: (state) => (payload) => {
    if (payload) {
      if (payload.plan_id !== null && payload.phase_id !== null && payload.assembly_id !== null && payload.assemblyitem_id !== null) {
        return state.assemblyItems.originalData.filter(origItem =>
          origItem.plan_id.toString() === payload.plan_id.toString() &&
          origItem.phase_id.toString() === payload.phase_id.toString() &&
          origItem.assembly_id.toString() === payload.assembly_id.toString() &&
          origItem.assemblyitem_id.toString() === payload.assemblyitem_id.toString(),
        )
      } else {
        return []
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
