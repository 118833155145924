<template>
  <v-list
    expand
    nav
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(item, i) in items">
      <default-list-group
        v-if="showGroupItem(item)"
        :key="`group-${i}`"
        :item="item"
      />

      <default-list-item
        v-else-if="showItem(item)"
        :key="`item-${i}`"
        :item="item"
      />
    </template>
  </v-list>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'DefaultList',

    components: {
      DefaultListGroup: () => import('./ListGroup'),
      DefaultListItem: () => import('./ListItem'),
    },

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },

    computed: {
      users: get('user/users'),
    },

    methods: {
      showGroupItem (item) {
        if (item.items) {
          return this.showItem(item)
        } else {
          return false
        }
      },

      showItem (item) {
        let showIt = false

        if (this.users.isGuest) {
          showIt = item.guest
        } else {
          if (item.admin) {
            showIt = this.users.isAdmin
          } else {
            showIt = true
          }
        }

        return showIt
      },
    },
  }
</script>
