<template>
  <v-container
    id="Report-Print-Area"
    ref="document"
    tag="section"
    class="pl-0 pt-0 pr-0 pb-0 ml-0 mt-0 mr-0 mb-0 printable"
    style="min-width: 100%"
  >
    <v-row
      no-gutters
      justify="center"
    >
      <v-col
        cols="12"
      >
        <!-- Client information and logo card -->
        <v-card>
          <v-card-text
            id="cardText"
          >
            <v-row
              no-gutters
              class="text-h4 pt-2 pb-2"
              align="center"
              justify="center"
            >
              <v-col
                cols="12"
                sm="8"
              >
                <v-row
                  no-gutters
                  class="text-h4"
                >
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <h4>
                      <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">{{ internalValue.title }} for:  {{ plans.selectedItem.description }}</span>
                    </h4>
                  </v-col>
                </v-row>

                <!-- Report Plan Info -->
                <v-row
                  no-gutters
                  class="mt-5 mb-5"
                >
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <div class="text-h6">
                      Customer: {{ customers.selectedItem.description }}
                    </div>
                    <div class="text-h6">
                      <v-row
                        no-gutters
                        style="flex-wrap: nowrap;"
                      >
                        <v-col
                          cols="12"
                          sm="1"
                          class="flex-grow-1 flex-shrink-0"
                          style="min-width: 66px; max-width: 100%;"
                        >
                          Location:
                        </v-col>
                        <v-col
                          cols="12"
                          sm="11"
                          class="flex-grow-0 flex-shrink-1"
                        >
                          {{ plans.selectedItem.address1 }}<br />
                          <span v-if="plans.selectedItem.address2 && plans.selectedItem.address2.length > 0">{{ plans.selectedItem.address2 }}<br /></span>
                          <span v-if="plans.selectedItem.city && plans.selectedItem.city.length > 0">{{ plans.selectedItem.city }},</span>
                          {{ plans.selectedItem.state }} {{ plans.selectedItem.zip }}
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <div class="text-h6">
                      Estimator: {{ getName(estimators.data, plans.selectedItem.estimator_id, plans.selectedItem.estimator_name) }}
                    </div>
                    <div class="text-h6">
                      Salesperson: {{ getName(salespeople.data, plans.selectedItem.salesperson_id, plans.selectedItem.salesperson_name) }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <img
                  :key="logoKey"
                  :style="getLogoStyle(customers.selectedItem)"
                  :src="customers.selectedItem.client_logo_url"
                  alt="Client Logo"
                />
              </v-col>
            </v-row>

            <!-- Main report headers, group breaking and data -->
            <shipping-report-table
              v-if="displayShippingReport"
            />

            <material-report-table
              v-if="displayMaterialReport"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import ShippingReportTable from '@/components/maintenance/tables/ShippingReportTable'
  import MaterialReportTable from '@/components/maintenance/tables/MaterialReportTable'

  export default {
    name: 'ReportPrintArea',

    components: {
      ShippingReportTable,
      MaterialReportTable,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        logoKey: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      ...mapGetters('customer', [
        'getLogoStyle',
      ]),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      estimators: get('estimator/estimators'),
      salespeople: get('salesperson/salespeople'),
      displayShippingReport () {
        return this.internalValue.reportName === 'Shipping'
      },
      displayMaterialReport () {
        return this.internalValue.reportName === 'Material'
      },
    },

    watch: {
      internalValue: {
        handler () {
          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val) {
          this.internalValue = val
        },
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      retrieveReport (reportName) {
        switch (reportName) {
          case 'Shipping':
            this.$store.dispatch('shippingReport/retrieveAll', null)
            break
          case 'Material':
            this.$store.dispatch('materialReport/retrieveAll', null)
            break
          default:
            console.error('Report Name not handled')
            break
        }
      },

      async initialize () {
        const selectedCustomer = await this.getDataById(this.customers.data, this.customers.selectedItem.client_id)

        if (this.getLengthOf(selectedCustomer) > 0) {
          this.$store.dispatch('customer/refreshCustomer', selectedCustomer[0])
        }
      },

      getName (fromData, id2Get, defaultName) {
        let selectedName = defaultName
        const selectedItems = this.getDataById(fromData, id2Get)

        if (selectedItems && selectedItems.length > 0) {
          selectedName = selectedItems[0].name
        }

        return selectedName
      },
    },
  }
</script>

<style scoped>

</style>
