<template>
  <div>
    <v-data-table
      dense
      disable-sort
      :headers="headers"
      hide-default-footer
      :items="shippingReports.data"
      item-key="index"
      group-by="phaseDescription"
      :footer-props="{'items-per-page-options':[-1]}"
      :options="options"
    >
      <template v-slot:group.header="{items}">
        <th
          colspan="5"
        >
          {{ items[0].phaseDescription }}
        </th>
      </template>

      <template v-slot:item.order_quantity="{ item }">
        <div style="font-size: 1.2em;">{{ item.order_quantity.toLocaleString("en-US") }}</div>
      </template>

      <template v-slot:item.itemDescription="{ item }">
        <div>{{ getPartDescription(item) }}</div>
      </template>

      <template v-slot:item.unit="{ item }">
        <div>{{ item.unit }}</div>
        <div
          v-if="item.note.length > 0"
          class="primary--text font-italic font-weight-medium"
        >
          ( {{ item.note }} )
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ShippingReportTable',

    props: {
      value: {
        type: Array,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {
            text: 'Part Number',
            align: 'start',
            value: 'part_id',
            width: '10%',
            class: 'font-weight-bold text-no-wrap',
          },
          {
            text: 'Quantity',
            align: 'end',
            value: 'order_quantity',
            width: '10%',
            class: 'font-weight-bold text-no-wrap',
          },
          {
            text: 'Item Description',
            align: 'start',
            value: 'itemDescription',
            width: '40%',
            class: 'font-weight-bold text-no-wrap',
          },
          {
            text: 'Unit of Measure',
            align: 'start',
            value: 'unit',
            width: '40%',
            class: 'font-weight-bold text-no-wrap',
          },
        ],
      }
    },

    computed: {
      ...mapGetters('part', [
        'getVendorPart',
      ]),
      shippingReports: get('shippingReport/shippingReports'),
    },

    watch: {
      internalValue: {
        handler () {
          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val) {
          this.internalValue = val
        },
      },
    },

    methods: {
      getPartDescription (item) {
        let selectedDescription = item.itemDescription
        const thisPart = this.getVendorPart(item.vendor_id, item.part_id)

        if (thisPart && {}.hasOwnProperty.call(thisPart, 'special_order') && {}.hasOwnProperty.call(thisPart, 'description')) {
          if (!thisPart.special_order) {
            selectedDescription = thisPart.description
          }
        }

        return selectedDescription
      },
    },
  }
</script>

<style scoped>

</style>
