<template>
  <div>
    <v-data-table
      ref="maintenanceTableAssembly"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="id"
      class="elevation-5"
      :style="cssTableBorder"
      show-select
      show-expand
      :hide-default-footer="getLengthOf(maintenanceState.data) <= 0"
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-center"
            />

            <v-col
              cols="12"
              sm="8"
              class="pa-0 ma-0 text-center"
            >
              <h2
                class="primary--text"
              >
                {{ displayAsPlural }}
              </h2>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-if="getLengthOf(maintenanceState.data) > 0"
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="12"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.vendor_id="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ item.vendor_id }}
              </span>
            </template>
            <span>{{ item.category_description }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.order_quantity="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isFormulaError(item)"
              class="white--text float-left"
              color="secondary"
              x-small
              icon
              v-bind="attrs"
              v-on="on"
              @click="openEditDialog_onCalculatorClick(item)"
            >
              <v-icon>mdi-flask-empty-off-outline</v-icon>
            </v-btn>
          </template>
          <span>Formula Error: {{ getFormulaErrorMessage(item) }}</span>
        </v-tooltip>

        <div
          class="font-weight-medium"
          style="font-size: 1.5em;"
        >
          <v-tooltip
            v-if="item.manual_quantity"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                class="primary--text"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.order_quantity.toLocaleString("en-US") }}
              </span>
            </template>
            <span>Quantity Overridden</span>
          </v-tooltip>
          <span v-else>{{ item.order_quantity.toLocaleString("en-US") }}</span>
        </div>
      </template>

      <template v-slot:item.unit="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.unit }}
        </div>
      </template>

      <template v-slot:item.part_id="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              :class="`font-weight-medium text-body-1 ${(item.special_order ? 'primary--text text--darken-1' : '')}`"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.part_id }}
            </span>
          </template>
          <span>{{ maintenanceState.headers.headerCategory.text }}: {{ item.vendor_id }} - {{ getDescription(categories.data, item.vendor_id, item.category_description) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          :class="`font-weight-medium text-body-1 d-inline ${(item.special_order ? 'primary--text text--darken-1' : '')}`"
        >
          {{ getPartDescription(item) }}
        </div>

        <div
          v-if="getLengthOf(item.note) > 0"
          class="d-inline float-right pl-1 pt-0 pr-0 pb-0 ma-0"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="white--text"
                color="secondary"
                x-small
                icon
                v-bind="attrs"
                v-on="on"
                @click="openEditDialog_onNoteClick(item)"
              >
                <v-icon>mdi-notebook-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
        </div>

        <div
          v-if="getLengthOf(item.tallySheet) > 0"
          class="d-inline float-right pl-1 pt-0 pr-0 pb-0 ma-0"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="white--text"
                color="secondary"
                x-small
                icon
                v-bind="attrs"
                v-on="on"
                @click="openEditDialog_onTallySheetClick(item)"
              >
                <v-icon>mdi-format-columns</v-icon>
              </v-btn>
            </template>
            <span>Tally Sheet</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.delivery_load_id="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ item.delivery_load_id }}
              </span>
            </template>
            <span>{{ getDescription(deliveryloads.data, item.delivery_load_id, item.delivery_load_description) }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.usage_id="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ item.usage_id }}
              </span>
            </template>
            <span>{{ getDescription(usages.data, item.usage_id, item.usage_description) }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.unit_cost="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.unit_cost }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="menuEdit_onClick(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          class="pt-4 pb-4"
          :colspan="getLengthOf(headers) + 1"
          @contextmenu:row="openContextMenu"
        >
          <v-data-table
            :headers="detailHeaders"
            :items="[item]"
            :hide-default-footer="true"
            class="elevation-2"
          >
            <template v-slot:top>
              <h2
                class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
              >
                {{ item.description }} - {{ displayAsSingular }} Details
              </h2>
            </template>

            <template v-slot:item.unit>
              <div
                class="font-weight-medium text-body-1"
              >
                {{ item.unit }}
              </div>
            </template>

            <template v-slot:item.delivery_load_id>
              <div
                class="font-weight-medium text-body-1"
              >
                {{ item.delivery_load_id }} - {{ getDescription(deliveryloads.data, item.delivery_load_id, item.delivery_load_description) }}
              </div>
            </template>

            <template v-slot:item.usage_id>
              <div
                class="font-weight-medium text-body-1"
              >
                {{ item.usage_id }} - {{ getDescription(usages.data, item.usage_id, item.usage_description) }}
              </div>
            </template>

            <template v-slot:item.waste_factor>
              <div
                class="font-weight-medium text-body-1"
              >
                {{ item.waste_factor }}
              </div>
            </template>

            <template v-slot:item.unit_price>
              <div
                class="font-weight-medium text-body-1"
              >
                ${{ formatCurrency(item.unit_price) }}
              </div>
            </template>

            <template v-slot:item.extPrice>
              <div
                class="font-weight-medium text-body-1"
              >
                ${{ formatCurrency(item.extPrice) }}
              </div>
            </template>
            <!-- Formula -->
            <template v-slot:footer>
              <v-container>
                <v-row
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="1"
                    class="primary--text font-weight-bold text-no-wrap"
                    @click="openEditDialog_onCalculatorClick(item)"
                  >
                    <v-btn
                      icon
                      x-small
                      color="primary"
                    >
                      <v-icon>mdi-calculator</v-icon>
                    </v-btn>
                    Formula:
                  </v-col>

                  <v-col
                    cols="12"
                    sm="11"
                    class="font-weight-medium text-body-1 pl-2"
                  >
                    {{ item.formula }}
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-data-table>
        </td>
      </template>
      <!-- TODO: Footer for Ext. Price Total -->
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-container
            class="pl-1 pt-2 pr-1 pb-1"
          >
            <!-- Category -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-category
                  :key="renderKey"
                  ref="editedCategory"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerCategory.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  :combobox="true"
                  :required="true"
                  @change="category_onChange"
                />
              </v-col>
            </v-row>
            <!-- Part\SKU & Unit Type -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="6"
              >
                <maintain-part
                  :key="renderKey"
                  ref="editedPart"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerPartSKU.text"
                  :originalData="maintenanceState.originalData"
                  :combobox="true"
                  :required="true"
                  @change="part_onChange"
                />
              </v-col>

              <v-col
                sm="6"
              >
                <div
                  v-if="editedItem.special_order"
                  class="pl-6 pt-0 pr-0 pb-0 ma-0"
                >
                  <maintain-unit
                    ref="editedUnit"
                    v-model="editedItem"
                    :label="maintenanceState.headers.headerUnitType.text"
                    :originalData="maintenanceState.originalData"
                    @change="field_onChange"
                  />
                </div>
                <span
                  v-else
                  class="pl-6 pt-0 pr-0 pb-0 ma-0 text-body-1 font-weight-regular"
                >
                  {{ maintenanceState.headers.headerUnitType.text }}:
                  <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                    {{ editedItem.unit }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <!-- Part Description -->
            <v-row
              v-if="editedItem.special_order"
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="6"
              >
                <maintain-description
                  :key="renderKey"
                  ref="editedDescription"
                  v-model="editedItem"
                  label="Special Order Description"
                  :originalData="maintenanceState.originalData"
                  :combobox="true"
                  :required="true"
                  @change="field_onChange"
                />
              </v-col>
            </v-row>
            <!-- Quantity -->
            <v-tooltip
              bottom
              :disabled="!disableQuantity"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  no-gutters
                  class="mb-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <!-- Order Qty -->
                  <v-col
                    sm="3"
                  >
                    <v-text-field
                      v-model="editedItem.order_quantity"
                      :label="maintenanceState.headers.headerQuantity.text"
                      dense
                      clearable
                      class="font-weight-medium text-body-1"
                      :disabled="disableQuantity"
                      :rules="[appRules.isNumeric]"
                      @blur="quantity_onBlur"
                      @click="input_onClick"
                      @click:clear="quantity_onClear"
                    />
                  </v-col>
                  <!-- Manual Qty -->
                  <v-col
                    sm="1"
                  >
                    <v-checkbox
                      v-model="editedItem.manual_quantity"
                      label="Overridden"
                      color="primary"
                      class="pl-5 pt-0 pr-0 pb-0 ma-0 text-body-1 font-weight-regular"
                      dense
                      :disabled="disableQuantity"
                      @change="handleRecalculate(editedItem)"
                    />
                  </v-col>
                </v-row>
              </template>
              <span>Quantity Fields disabled due to Tally Sheet</span>
            </v-tooltip>
            <!-- Unit Price -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="editedUnitPrice"
                  v-model="editedItem.unit_price"
                  :label="maintenanceState.headers.headerUnitPrice.text"
                  dense
                  clearable
                  class="font-weight-medium text-body-1"
                  :rules="[appRules.isCurrency]"
                  @change="handleRecalculate(editedItem)"
                  @click="input_onClick"
                />
              </v-col>
            </v-row>
            <!-- Waste -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="editedWaste"
                  v-model="editedItem.waste_factor"
                  :label="maintenanceState.headers.headerWaste.text"
                  dense
                  clearable
                  class="font-weight-medium text-body-1"
                  :rules="[appRules.isCurrency]"
                  @change="handleRecalculate(editedItem)"
                  @click="input_onClick"
                />
              </v-col>

              <v-col
                sm="9"
              >
                <span
                  class="pl-6 pt-0 pr-0 pb-0 ma-0 text-body-1 font-weight-regular"
                >
                  Ext. Price:
                  <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                    ${{ formatCurrency(editedItem.extPrice) }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <!-- Delivery Load -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="6"
              >
                <maintain-delivery-load
                  :key="renderKey"
                  ref="editedDeliveryLoad"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerDeliveryLoad.text"
                  :originalData="maintenanceState.originalData"
                  :combobox="true"
                  @change="field_onChange"
                />
              </v-col>
            </v-row>
            <!-- Usage -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                :sm="(createUsage ? 5 : 6)"
              >
                <maintain-usage
                  :key="renderKey"
                  id="editedUsage"
                  ref="editedUsage"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerUsage.text"
                  :originalData="maintenanceState.originalData"
                  :combobox="true"
                  @change="usage_onChange"
                />
              </v-col>

              <v-col
                v-if="createUsage"
                cols="12"
                offset-sm="1"
                sm="2"
              >
                <v-text-field
                  ref="usageId"
                  v-model="editedItem.usage_id"
                  required
                  dense
                  clearable
                  class="font-weight-medium text-body-1"
                  maxlength="10"
                  :rules="[appRules.isPrimaryId, appRules.idExists(editedItem.usage_id, usages.data)]"
                  @change="usageId_onChange"
                  @click="input_onClick"
                >
                  <template v-slot:label>
                    <v-icon
                      class="required-icon"
                      x-small
                    >
                      mdi-asterisk
                    </v-icon>
                    New ID
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                v-if="createUsage"
                cols="12"
                offset-sm="1"
                sm="3"
              >
                <v-btn
                  text
                  color="primary"
                  class="font-weight-medium"
                  :disabled="disableCreateUsage"
                  @click="createUsage_onClick"
                >
                  Create New Usage
                </v-btn>
              </v-col>
            </v-row>
            <!-- Formula -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
              >
                <maintain-formula
                  ref="editedFormula"
                  v-model="editedItem"
                  label="Formula"
                  :originalData="maintenanceState.originalData"
                  @change="handleRecalculate(editedItem)"
                />
              </v-col>
            </v-row>
            <!-- Tally Sheet -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-tally-sheet
                  ref="editedTallySheet"
                  v-model="editedItem"
                  :originalData="maintenanceState.originalData"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Note -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-note
                  ref="editedNote"
                  v-model="editedItem"
                  label="Note"
                  :originalData="maintenanceState.originalData"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import MaintainCategory from '@/components/maintenance/controls/Category'
  import MaintainPart from '@/components/maintenance/controls/Part'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainUnit from '@/components/maintenance/controls/Unit'
  import MaintainDeliveryLoad from '@/components/maintenance/controls/DeliveryLoad'
  import MaintainUsage from '@/components/maintenance/controls/Usage'
  import MaintainFormula from '@/components/maintenance/controls/Formula'
  import MaintainTallySheet from '@/components/maintenance/controls/TallySheet'
  import MaintainNote from '@/components/maintenance/controls/Note'

  export default {
    name: 'AssemblyItemTable',

    components: {
      DeleteDialog,
      ContextMenu,
      MaintainCategory,
      MaintainPart,
      MaintainDescription,
      MaintainUnit,
      MaintainDeliveryLoad,
      MaintainUsage,
      MaintainFormula,
      MaintainTallySheet,
      MaintainNote,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        createUsage: false,
        newUsage: null,
        disableCreateUsage: true,
        newUsageAdded: false,
        renderKey: 0,
        search: null,
        headers: [],
        detailHeaders: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'formatCurrency',
      ]),
      ...mapGetters('part', [
        'getVendorPart',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      assemblies: get('assembly/assemblies'),
      categories: get('category/categories'),
      parts: get('part/parts'),
      deliveryloads: get('deliveryload/deliveryloads'),
      usages: get('usage/usages'),
      displayAsSingular: get('assemblyitem/displayAsSingular'),
      displayAsPlural: get('assemblyitem/displayAsPlural'),
      maintenanceState: get('assemblyitem/assemblyItems'),
      cssTableBorder () {
        // HACK! because I couldn't get the theme color to work from the sass style below
        return {
          'border-width': 'thin',
          'border-style': 'solid',
          'border-color': `${this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].secondary}`,
        }
      },
      disableNew () {
        let disabled = true

        if (this.assemblies.selectedItem) {
          if ({}.hasOwnProperty.call(this.assemblies.selectedItem, 'id')) {
            disabled = this.getLengthOf(this.assemblies.selectedItem.id) <= 0
          }
        }

        return disabled
      },
      disableQuantity () {
        let disabled = false

        if (this.editedItem) {
          if (this.editedItem.tallySheet) {
            disabled = this.editedItem.tallySheet.length > 0
          }
        }

        return disabled
      },
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular} - ${this.editedItem.description}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'usage/successfulUpdate':
            this.addUsage_onSuccess()
            break
          default:
            break
        }
      })

      this.initialize()
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerQuantity.width = '2%'
        this.maintenanceState.headers.headerUnitType.width = '2%'
        this.maintenanceState.headers.headerPartSKU.width = '4%'
        this.maintenanceState.headers.headerPartDescription.width = '80%'
        this.maintenanceState.headers.headerDeliveryLoad.width = '5%'
        this.maintenanceState.headers.headerUsage.width = '5%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerQuantity)
        this.headers.push(this.maintenanceState.headers.headerUnitType)
        this.headers.push(this.maintenanceState.headers.headerPartSKU)
        this.headers.push(this.maintenanceState.headers.headerPartDescription)
        this.headers.push(this.maintenanceState.headers.headerDeliveryLoad)
        this.headers.push(this.maintenanceState.headers.headerUsage)
        this.headers.push(this.appHeaders.headerActions)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeaders () {
        const detailHeaderDeliveryLoad = { ...this.maintenanceState.headers.headerDeliveryLoad }
        const detailHeaderUsage = { ...this.maintenanceState.headers.headerUsage }

        detailHeaderDeliveryLoad.width = '42%'
        detailHeaderDeliveryLoad.sortable = false
        detailHeaderDeliveryLoad.filterable = false
        detailHeaderUsage.width = '42%'
        detailHeaderUsage.sortable = false
        detailHeaderUsage.filterable = false
        this.maintenanceState.headers.headerWaste.width = '2%'
        this.maintenanceState.headers.headerUnitPrice.width = '4%'
        this.maintenanceState.headers.headerExtPrice.width = '8%'

        this.detailHeaders = []

        this.detailHeaders.push(detailHeaderDeliveryLoad)
        this.detailHeaders.push(detailHeaderUsage)
        this.detailHeaders.push(this.maintenanceState.headers.headerWaste)
        this.detailHeaders.push(this.maintenanceState.headers.headerUnitPrice)
        this.detailHeaders.push(this.maintenanceState.headers.headerExtPrice)
      },

      initialize () {
        this.setupHeaders()

        this.setupDetailHeaders()
      },

      getDescription (fromData, id2Get, defaultDescription) {
        let selectedDescription = defaultDescription

        const selectedItems = this.getDataById(fromData, id2Get)
        if (selectedItems.length > 0) {
          const selectedItem = selectedItems[0]
          if (selectedItem && {}.hasOwnProperty.call(selectedItem, 'description')) {
            selectedDescription = selectedItem.description
          }
        }

        return selectedDescription
      },

      // TODO: Move this to a common place, like a mixin or helpers.js?
      getPartDescription (item) {
        let selectedDescription = item.description

        if (!item.special_order) {
          const thisPart = this.getVendorPart(item.vendor_id, item.part_id)

          if (thisPart && {}.hasOwnProperty.call(thisPart, 'description')) {
            selectedDescription = thisPart.description
          }
        }

        return selectedDescription
      },

      recalculateTakeoffItem (dataItem) {
        this.$store.dispatch('takeoff/recalculateTakeoffItem', dataItem)
      },

      setDialogEdit (val) {
        this.dialogEdit = val
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)

        // Default values to last item
        const lastIndex = this.getLengthOf(this.maintenanceState.data) - 1
        if (lastIndex >= 0) {
          const lastAssemblyItem = this.maintenanceState.data[lastIndex]
          if (lastAssemblyItem) {
            this.editedItem.delivery_load_id = lastAssemblyItem.delivery_load_id
            this.editedItem.usage_id = lastAssemblyItem.usage_id
            this.editedItem.formula = lastAssemblyItem.formula
            this.editedItem.tallySheet = []
          }
        }
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      openEditDialog_onCalculatorClick (dataItem) {
        this.openEditDialog(dataItem)
        this.$nextTick(() => {
          this.$refs.editedFormula.setExpand(true)
          this.$refs.editedFormula.selectAll()
        })
      },

      openEditDialog_onTallySheetClick (dataItem) {
        this.openEditDialog(dataItem)
        this.$nextTick(() => {
          this.$refs.editedTallySheet.setFocus()
        })
      },

      openEditDialog_onNoteClick (dataItem) {
        this.openEditDialog(dataItem)
        this.$nextTick(() => {
          this.$refs.editedNote.selectAll()
        })
      },

      field_onChange () {
        const dataItem = this.editedItem
        const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)

        if (this.getLengthOf(originalData) > 0) {
          if (dataItem.order_quantity !== originalData[0].order_quantity ||
            dataItem.waste_factor !== originalData[0].waste_factor ||
            dataItem.unit_price !== originalData[0].unit_price ||
            dataItem.delivery_load_id !== originalData[0].delivery_load_id ||
            dataItem.usage_id !== originalData[0].usage_id) {
            this.$store.dispatch('user/setStateAsUpdated', dataItem)
          }
        }

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      part_onChange () {
        this.field_onChange()

        if (this.parts.selectedItem) {
          if ({}.hasOwnProperty.call(this.parts.selectedItem, 'unit')) {
            this.editedItem.unit = this.parts.selectedItem.unit
          }

          if ({}.hasOwnProperty.call(this.parts.selectedItem, 'unit_cost')) {
            this.editedItem.unit_price = this.parts.selectedItem.unit_cost
          }

          if ({}.hasOwnProperty.call(this.parts.selectedItem, 'special_order')) {
            this.editedItem.special_order = this.parts.selectedItem.special_order
          }

          this.$nextTick(() => {
            this.forceRerender()
          })
        }
      },

      handleRecalculate (dataItem) {
        this.recalculateTakeoffItem(dataItem)
        this.field_onChange()
      },

      category_onChange () {
        this.editedItem.part_id = null

        this.field_onChange()
      },

      quantity_onBlur () {
        const dataItem = this.editedItem
        const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)

        if (this.getLengthOf(originalData) > 0) {
          // TODO: This manual_quantity logic may need a lot of work.  Be careful when changing.
          if (dataItem.order_quantity !== originalData[0].order_quantity) {
            this.editedItem.manual_quantity = (dataItem.order_quantity !== null)
            this.recalculateTakeoffItem(dataItem)
          }
        }

        this.field_onChange()
      },

      quantity_onClear () {
        this.editedItem.manual_quantity = false

        this.$nextTick(() => {
          this.editedItem.order_quantity = 0
          this.handleRecalculate(this.editedItem)
        })
      },

      getFormulaErrorMessage (dataItem) {
        let errMsg = ''
        if ({}.hasOwnProperty.call(dataItem, 'formula_error')) {
          if ({}.hasOwnProperty.call(dataItem.formula_error, 'message')) {
            errMsg = dataItem.formula_error.message
          }
        }
        return errMsg
      },

      isFormulaError (dataItem) {
        let hasError = false
        if ({}.hasOwnProperty.call(dataItem, 'formula_error')) {
          hasError = true
        }
        return hasError
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedCategory) {
          disabled = this.$refs.editedCategory.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.editedPart) {
          disabled = this.$refs.editedPart.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedUnitPrice) {
          disabled = this.$refs.editedUnitPrice.hasError
        }

        if (!disabled && this.$refs.editedWaste) {
          disabled = this.$refs.editedWaste.hasError
        }

        if (!disabled && this.$refs.editedDeliveryLoad) {
          disabled = this.$refs.editedDeliveryLoad.$refs.combobox.hasError
        }

        // TODO: This is a hack, because I can't figure out how to assign a valid value and make hasError satisfied, get rid of newUsageAdded when this is done properly
        if (!this.newUsageAdded) {
          if (!disabled && this.$refs.editedUsage) {
            disabled = this.$refs.editedUsage.$refs.combobox.hasError
          }
        }

        return disabled
      },

      usage_onChange () {
        this.createUsage = true

        if (this.editedItem && typeof this.editedItem.usage_id !== 'undefined') {
          if (this.editedItem.usage_id.length > 0) {
            const selectedItems = this.getDataById(this.usages.data, this.editedItem.usage_id)

            if (selectedItems && selectedItems.length > 0) {
              this.createUsage = false
            } else {
              this.createUsage = true
            }
          }
        }

        this.field_onChange()
      },

      usageId_onChange () {
        this.disableCreateUsage = true

        if (this.editedItem && {}.hasOwnProperty.call(this.editedItem, 'usage_id')) {
          if (this.$refs.editedUsage && this.$refs.editedUsage.$refs.combobox && this.$refs.editedUsage.$refs.combobox.hasError) {
            if (!this.$refs.usageId.hasError) {
              this.disableCreateUsage = false
            }
          }
        }
      },

      createUsage_onClick () {
        this.newUsage = { ...this.usages.empty }

        this.$store.dispatch('user/setStateAsUpdated', this.newUsage)

        this.editedItem.usage_description = this.$refs.editedUsage.getNewDescription()

        this.newUsage.id = this.editedItem.usage_id
        this.newUsage.usage_id = this.editedItem.usage_id
        this.newUsage.description = this.editedItem.usage_description

        this.$store.dispatch('usage/update', this.newUsage)
      },

      addUsage_onSuccess () {
        this.newUsageAdded = true

        this.$store.dispatch('usage/setSelected', this.newUsage)

        this.createUsage = false
        this.disableEdit = this.getDisableEdit()

        this.$nextTick(() => {
          this.newUsageAdded = false
          // this.forceRerender()
        })
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('assemblyitem/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
        this.$nextTick(() => {
          // TODO: Category.selectAll to setFocus When Edit Dialog opened
          // this.$refs.editedCategory.selectAll()
        })
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.$refs.editedTallySheet.cleanup()

            this.editedItem.plan_id = this.assemblies.selectedItem.plan_id
            this.editedItem.phase_id = this.assemblies.selectedItem.phase_id
            this.editedItem.assembly_id = this.assemblies.selectedItem.assembly_id
            if (!this.editedItem.special_order) {
              this.editedItem.description = this.parts.selectedItem.description
            }

            this.$nextTick(() => {
              this.$store.dispatch('assemblyitem/update', this.editedItem)
            })
          }
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  .table-bordered
    border-width: thin
    border-style: solid
    // border-color: lighten(var(--v-primary-color), 33)
    // border-color: var(--v-primary-color)
    // border-color: lighten(blue, 33)
    // border-color: var(--v-primary-lighten4)
</style>
