<template>
  <v-container
    id="reports-view"
    fluid
    tag="section"
  >
    <v-row
      id="report-filters"
      justify="center"
      class="non-printable"
    >
      <v-col cols="12">
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row class="mb-6 pb-6">
                <v-col sm="8">
                  <h3>{{ appItem.title }}</h3>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <!-- Customer Dropdown -->
                <v-col
                  v-if="customers.data.length > 1"
                  lg="4"
                  md="8"
                  sm="12"
                  class="mt-0 mb-0 pt-0 pb-0"
                >
                  <select-customer
                    id="2-maintain-customer"
                    v-model="customers.selectedItem"
                    :appendhint="`to select from its ${displayAsPluralPlan}`"
                  />
                </v-col>

                <!-- Plan Dropdown  -->
                <v-col
                  lg="4"
                  md="8"
                  sm="12"
                  class="mt-0 mb-0 pt-0 pb-0"
                >
                  <select-plan
                    id="2-maintain"
                    v-model="plans.selectedItem"
                    :selectedkey="plans.selectedItem"
                    appendhint="to view its report"
                  />
                </v-col>

                <!-- Report Dropdown -->
                <v-col
                  lg="4"
                  md="8"
                  sm="12"
                  class="mt-0 mb-0 pt-0 pb-0"
                >
                  <div
                    class="primary--text font-weight-medium text-body-5"
                  >
                    Reports
                  </div>
                  <v-combobox
                    v-model="selectedReport"
                    :items="reportItems"
                    item-text="title"
                    item-value="reportName"
                    dense
                    outlined
                    hint="Select a report"
                    class="mt-0 mb-0"
                    @change="retrieveReport"
                  />
                </v-col>
              </v-row>

              <v-row
                no-gutters
                class="ml-1 mt-4 mb-3"
              >
                <!-- Filter Delivery Loads Dropdown -->
                <v-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <v-select
                    v-model="selectedDeliveryLoads"
                    :items="deliveryloads.planLoads"
                    item-text="description"
                    item-value="load_id"
                    label="Filter by Delivery Load"
                    return-object
                    multiple
                    small-chips
                    deletable-chips
                    outlined
                    @change="filterDeliveryLoads_onChange"
                  >
                    <template>
                      <v-list-item
                        ripple
                        @click="toggleSelectAll"
                      >
                        <v-list-item-action>
                          <v-icon :color="selectedDeliveryLoads.length > 0 ? 'secondary' : ''">
                            {{ selectAllIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Select All
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2" />
                    </template>
                  </v-select>
                </v-col>

                <!-- Report Actions - Print to PDF -->
                <v-col
                  lg="2"
                  md="2"
                  class="d-flex justify-center"
                >
                  <v-btn
                    color="primary"
                    min-width="100"
                    @click="generatePDF"
                  >
                    Export to PDF
                  </v-btn>
                </v-col>

                <!-- Report Actions - Export to Excel -->
                <v-col
                  lg="2"
                  md="2"
                  class="d-flex justify-center"
                >
                  <download-excel
                    class="btn btn-default"
                    :data="shippingReports.data"
                    worksheet="Shipping Usage"
                    name="ShippingUsageList.xls"
                  >
                    <v-btn
                      color="primary"
                      min-width="100"
                    >
                      Export to Excel
                    </v-btn>
                  </download-excel>
                </v-col>

                <!-- ToDo: May need to make this a v-if and only show when shipping report -->
                <!-- Report Actions - Print to POS -->
                <v-col
                  lg="2"
                  md="2"
                  class="d-flex justify-center"
                >
                  <v-btn
                    v-if="selectedReport.reportName === 'Shipping'"
                    color="primary"
                    min-width="100"
                    @click="generatePOS"
                  >
                    Export to POS
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>

    <!-- Report Print Area -->
    <v-row
      no-gutters
      justify="center"
      class="printable"
      style="min-width: 100%;"
    >
      <v-col
        cols="12"
      >
        <report-print-area
          id="reportPrintArea"
          ref="reportPrintArea"
          v-model="selectedReport"
        />
      </v-col>
    </v-row>

    <!-- Overlay is used to display spinner while page is loading -->
    <v-overlay :value="isBusy">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import MaterialCard from '@/components/MaterialCard'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import SelectPlan from '@/components/keys/SelectPlan'
  import ReportPrintArea from '@/components/maintenance/forms/ReportPrintArea'

  export default {
    name: 'ReportsView',

    components: {
      MaterialCard,
      SelectCustomer,
      SelectPlan,
      ReportPrintArea,
    },

    data () {
      return {
        appItem: null,
        selectedReport: {
          title: 'Material Usage Report',
          reportName: 'Material',
        },
        reportItems: [
          {
            title: 'Material Usage Report',
            reportName: 'Material',
          },
          {
            title: 'Material Shipping Report',
            reportName: 'Shipping',
          },
        ],
        selectedDeliveryLoads: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
        'getDataById',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      deliveryloads: get('deliveryload/deliveryloads'),
      shippingReports: get('shippingReport/shippingReports'),
      materialReports: get('materialReport/materialReports'),
      displayAsPluralPlan: get('plan/displayAsPlural'),
      exportPOS: get('export/exports'),
      selectedAllDeliveryLoads () {
        return this.selectedDeliveryLoads.length === this.deliveryloads.planLoads.length
      },
      selectedSomeDeliveryLoads () {
        return this.selectedDeliveryLoads.length > 0 && !this.selectedAllDeliveryLoads
      },
      selectAllIcon () {
        if (this.selectedAllDeliveryLoads) return 'mdi-close-box'
        if (this.selectedSomeDeliveryLoads) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      isBusy () {
        return this.deliveryloads.isBusy || this.shippingReports.isBusy || this.materialReports.isBusy
      },
    },

    created () {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/setSelected':
            // subscribe to plan/setSelected, so we know a plan has been changed
            this.plan_onChange()
            break
        }
      })

      this.initialize()
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      initialize () {
        this.appItem = this.getAppItem(this.$options.name)
        this.$store.dispatch('user/setPageName', this.$options.name)

        this.plan_onChange()
      },

      setSelectedPlanLoads (selectedItems) {
        this.$store.dispatch('deliveryload/setSelectedPlanLoads', selectedItems)
      },

      plan_onChange () {
        if (this.plans.selectedItem && {}.hasOwnProperty.call(this.plans.selectedItem, 'id')) {
          // Retrieve the delivery loads for the plan selected
          this.$store.dispatch('deliveryload/retrievePlanDeliveryLoads', { plan_id: this.plans.selectedItem.id })

          this.$nextTick(() => {
            this.retrieveReport()
          })
        }
        this.selectedDeliveryLoads = []
        this.setSelectedPlanLoads(this.selectedDeliveryLoads)
      },

      filterDeliveryLoads_onChange () {
        this.setSelectedPlanLoads(this.selectedDeliveryLoads)

        switch (this.selectedReport.reportName) {
          case 'Shipping':
            this.$store.dispatch('shippingReport/setDataFilteredOnDeliveryLoads')
            break
          case 'Material':
            this.$store.dispatch('materialReport/setDataFilteredOnDeliveryLoads')
            break
          default:
            console.error('Report Name not handled')
            break
        }
      },

      // Toggle is fired whenever a load(s) is selected. It updates the selectedDeliverLoads array
      // with the loads that are checked.
      toggleSelectAll () {
        this.$nextTick(() => {
          if (this.selectedAllDeliveryLoads) {
            this.selectedDeliveryLoads = []
          } else {
            this.selectedDeliveryLoads = this.deliveryloads.planLoads.slice()
          }
          this.filterDeliveryLoads_onChange()
        })
      },

      retrieveReport () {
        this.setSelectedPlanLoads(this.selectedDeliveryLoads)
        this.$refs.reportPrintArea.retrieveReport(this.selectedReport.reportName)
      },

      // This wait is used by generatePDF to collapse the left navigation before we print the PDF
      // using the browser.
      async wait (ms = 0) {
        document.getElementById('leftNavIcon').click()
        return new Promise(resolve => setTimeout(resolve, ms))
      },

      // TODO: It seems the proper way to handle this is with css (@media print), but I can't get print to work properly without leftNavIcon.click
      // Here we collapse the left navigation (wait), before opening the print window for the browser.
      // This way the print window will only show the report and not the left frame.
      // After the print window is closed, we return navigation to the way it was.
      async generatePDF () {
        await this.wait(50)

        window.print()

        await this.wait(50)
      },

      generatePOS: async function () {
        const FileSaver = require('file-saver')
        let exportData = null
        let fileExtension = null
        let errorMessage

        switch (this.customers.selectedItem.pos_export_type) {
          case 'BMS':
            exportData = await this.$store.dispatch('exportPOS/toBMS', this.shippingReports)
            fileExtension = '.bms'

            if (exportData !== null) {
              const file = new File([exportData], this.customers.selectedItem.description + fileExtension, { type: 'text/plain;charset=utf-8' })
              FileSaver.saveAs(file)
            }

            break
          case 'BisTrack':
            this.$store.dispatch('exportPOS/toBisTrack')
            break
          default:
            errorMessage = `Cannot export POS file. Client ${this.customers.selectedItem.description} not setup properly.`
            this.$store.dispatch('error/setError', { name: 'Generate POS', details: errorMessage })
        }
      },
    },
  }
</script>

<style>
.black--text /deep/ label {
  color: black;
}
</style>
