<template>
  <div>
    <v-select
      ref="selectenum"
      v-model="internalValue"
      :items="options"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :rules="prepRules()"
      :hint="(hint ? `${hint}` : null)"
      :persistent-hint="(hint ? true : false)"
      @change="onChange"
      @click:clear="onClear"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>
    </v-select>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'SelectEnumString',

    props: {
      value: {
        type: String,
        default: null,
      },
      enum: {
        type: Object,
        default: () => ({ text: '', value: null }),
        required: true,
      },
      hint: {
        type: String,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      appRules: get('app/rules'),
      options () {
        return Object.entries(this.enum)
          .map(([key, value]) => ({ text: value, value: key }))
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.internalValue = val
        },
      },
    },

    methods: {
      onChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      onClear () {
        this.internalValue = null
        this.onChange(null)
      },

      prepRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }

        return rules
      },
    },
  }
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
