<template>
  <div>
    <v-data-table
      ref="maintenanceTablePreviewImport"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      item-key="table_id"
      sort-by="table_id"
      class="elevation-2"
      show-select
      :loading="maintenanceState.isBusy"
      :search="search"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-center"
            >
              <v-btn
                small
                color="secondary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="maintenanceState.selected.length<=0"
                @click="remove_onClick"
              >
                Remove Selected
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="8"
              class="pa-0 ma-0 text-center"
            >
              <h2
                class="primary--text"
              >
                Preview {{ displayAsSingular }}
              </h2>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-4 mb-0 pt-4 pb-4"
                :disabled="maintenanceState.data.length<=0"
                @click="executeImport_onClick"
              >
                Execute {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.data.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.vendor_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.vendor_id }}
        </div>
      </template>

      <template v-slot:item.part_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.part_id }}
        </div>
      </template>

      <template v-slot:item.pv_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.pv_id }}
        </div>
      </template>

      <template v-slot:item.plan_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.plan_id }}
        </div>
      </template>

      <template v-slot:item.phase_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.phase_id }}
        </div>
      </template>

      <template v-slot:item.assembly_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.assembly_id }}
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.id }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.unit="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.unit }}
        </div>
      </template>

      <template v-slot:item.delivery_load_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.delivery_load_id }}
        </div>
      </template>

      <template v-slot:item.usage_id="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.usage_id }}
        </div>
      </template>

      <template v-slot:item.waste_factor="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.waste_factor }}
        </div>
      </template>

      <template v-slot:item.order_quantity="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.order_quantity }}
        </div>
      </template>

      <template v-slot:item.formula="{ item }">
        <div
          :class="getItemClass()"
        >
          {{ item.formula }}
        </div>
      </template>
    </v-data-table>
    <!-- Dialogs -->
    <confirmation-dialog
      ref="importConfirmationDialog"
      @confirm="processImport"
    >
      <template #title>
        <h2 class="primary--text">
          Confirm {{ displayAsSingular }}
        </h2>
      </template>

      <template #message>
        <div class="text-center">
          Are you sure you want to {{ displayAsSingular.toLowerCase() }}
          <span class="primary--text">{{ maintenanceState.data.length.toLocaleString() }}</span>
          rows<br />into the
          <span class="secondary--text">{{ maintenanceState.selectedImportType.table_name }}</span>
          table<br />for the
          <span class="primary--text">{{ customers.selectedItem.description }}</span>
          Customer?
        </div>
      </template>
    </confirmation-dialog>

    <confirmation-dialog
      ref="removeConfirmationDialog"
      @confirm="removeSelected"
    >
      <template #title>
        <h2 class="primary--text">
          Remove Selected
        </h2>
      </template>

      <template #message>
        <div>
          Are you sure you want to remove
          <span class="primary--text">{{ maintenanceState.selected.length.toLocaleString() }}</span>
          rows from this<br />
          <span class="secondary--text">{{ maintenanceState.selectedImportType.description }}</span>
          {{ displayAsSingular.toLowerCase() }}?
        </div>
      </template>
    </confirmation-dialog>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import ConfirmationDialog from '@/components/maintenance/ConfirmationDialog'

  export default {
    name: 'PreviewImportTable',

    components: {
      ConfirmationDialog,
    },

    data () {
      return {
        search: null,
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      customers: get('customer/customers'),
      displayAsSingular: get('importData/displayAsSingular'),
      displayAsPlural: get('importData/displayAsPlural'),
      maintenanceState: get('importData/imports'),
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'importData/successfulSetup':
            this.setupHeaders()
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      setupHeadersIdDescriptionImport () {
        this.maintenanceState.headers.headerId.width = '15%'
        this.maintenanceState.headers.headerDescription.width = '85%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
      },

      setupHeadersPromptImport () {
        // Changing the text, so let's get a copy instead of directly changing the header text
        const headerId = { ...this.maintenanceState.headers.headerId }
        const headerDescription = { ...this.maintenanceState.headers.headerDescription }

        this.maintenanceState.headers.headerPromptGroup.width = '15%'
        headerId.width = '15%'
        headerDescription.width = '70%'

        headerId.text = 'Variable'
        headerDescription.text = 'Prompt'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerPromptGroup)
        this.headers.push(headerId)
        this.headers.push(headerDescription)
      },

      setupHeadersPartImport () {
        this.maintenanceState.headers.headerCategoryId.width = '15%'
        this.maintenanceState.headers.headerId.width = '15%'
        this.maintenanceState.headers.headerDescription.width = '55%'
        this.maintenanceState.headers.headerUnit.width = '15%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerCategoryId)
        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.maintenanceState.headers.headerUnit)
      },

      setupHeadersDivisionImport () {
        this.maintenanceState.headers.headerPlanId.width = '15%'
        this.maintenanceState.headers.headerId.width = '15%'
        this.maintenanceState.headers.headerDescription.width = '70%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerPlanId)
        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
      },

      setupHeadersAssemblyImport () {
        this.maintenanceState.headers.headerPlanId.width = '15%'
        this.maintenanceState.headers.headerDivisionId.width = '15%'
        this.maintenanceState.headers.headerId.width = '15%'
        this.maintenanceState.headers.headerDescription.width = '40%'
        this.maintenanceState.headers.headerPromptGroup.width = '15%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerPlanId)
        this.headers.push(this.maintenanceState.headers.headerDivisionId)
        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.maintenanceState.headers.headerPromptGroup)
      },

      setupHeadersAssemblyItemImport () {
        // Changing the text, so let's get a copy instead of directly changing the header text
        const headerPlanId = { ...this.maintenanceState.headers.headerPlanId }
        const headerDivisionId = { ...this.maintenanceState.headers.headerDivisionId }
        const headerAssemblyId = { ...this.maintenanceState.headers.headerAssemblyId }
        const headerCategoryId = { ...this.maintenanceState.headers.headerCategoryId }
        const headerPartId = { ...this.maintenanceState.headers.headerPartId }
        const headerDeliveryLoadId = { ...this.maintenanceState.headers.headerDeliveryLoadId }
        const headerWaste = { ...this.maintenanceState.headers.headerWaste }
        const headerQuantity = { ...this.maintenanceState.headers.headerQuantity }

        headerPlanId.width = '5%'
        headerDivisionId.width = '5%'
        headerAssemblyId.width = '5%'
        headerCategoryId.width = '5%'
        headerPartId.width = '5%'
        this.maintenanceState.headers.headerDescription.width = '30%'
        this.maintenanceState.headers.headerUnit.width = '5%'
        headerDeliveryLoadId.width = '5%'
        this.maintenanceState.headers.headerUsageId.width = '5%'
        headerWaste.width = '5%'
        headerQuantity.width = '5%'
        this.maintenanceState.headers.headerFormula.width = '20%'

        headerPlanId.text = 'Pl.'
        headerDivisionId.text = 'Div.'
        headerAssemblyId.text = 'As.'
        headerCategoryId.text = 'Cat.'
        headerPartId.text = 'Part'
        headerDeliveryLoadId.text = 'Load'
        headerWaste.text = '%'
        headerQuantity.text = 'Qty'

        this.headers = []

        this.headers.push(headerPlanId)
        this.headers.push(headerDivisionId)
        this.headers.push(headerAssemblyId)
        this.headers.push(headerCategoryId)
        this.headers.push(headerPartId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.maintenanceState.headers.headerUnit)
        this.headers.push(headerDeliveryLoadId)
        this.headers.push(this.maintenanceState.headers.headerUsageId)
        this.headers.push(headerWaste)
        this.headers.push(headerQuantity)
        this.headers.push(this.maintenanceState.headers.headerFormula)
      },

      setupHeaders () {
        let errorDetails

        switch (this.maintenanceState.selectedImportType.id) {
          case 'Legacy-Categories':
          case 'Legacy-Delivery-Loads':
          case 'Legacy-Usages':
          case 'Legacy-Prompt-Groups':
          case 'Legacy-Plans':
            this.setupHeadersIdDescriptionImport()
            break
          case 'Legacy-Parts':
            this.setupHeadersPartImport()
            break
          case 'Legacy-Prompts':
            this.setupHeadersPromptImport()
            break
          case 'Legacy-Divisions':
            this.setupHeadersDivisionImport()
            break
          case 'Legacy-Assemblies':
            this.setupHeadersAssemblyImport()
            break
          case 'Legacy-Assembly-Items':
            this.setupHeadersAssemblyItemImport()
            break
          default:
            errorDetails = `Import Type ${this.maintenanceState.selectedImportType.description} not supported`
            console.error(`setupHeaders failed - ${errorDetails}`)
            break
        }
      },

      initialize () {
        this.setupHeaders()
      },

      getItemClass () {
        return (this.maintenanceState.selectedImportType.id === 'Legacy-Assembly-Items' ? 'font-weight-medium text-body-2' : 'font-weight-medium text-body-1')
      },

      removeSelected () {
        this.$store.dispatch('importData/removeSelected')
      },

      remove_onClick () {
        this.$refs.removeConfirmationDialog.setDisplayDialog(true)
      },

      processImport () {
        this.$store.dispatch('importData/process')
      },

      executeImport_onClick () {
        this.$refs.importConfirmationDialog.setDisplayDialog(true)
      },
    },
  }
</script>

<style scoped>

</style>
