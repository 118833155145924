import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import JsonExcel from 'vue-json-excel'

import Amplify from 'aws-amplify'
import AwsExports from './aws-exports'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'

applyPolyfills().then(() => {
  defineCustomElements(window)
})

Amplify.configure(AwsExports)

Vue.component('downloadExcel', JsonExcel)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    input_onClick (evt) {
      evt.target.setSelectionRange(0, evt.target.value.length)
    },

    getLengthOf (thisObject, forEl = 'unknown') {
      let thisLength = 0

      if (thisObject !== null) {
        thisLength = thisObject.length
      }

      return thisLength
    },

    getStatusColor (statusCd, isDarkTheme) {
      let statusColor = null

      switch (statusCd) {
        case 'submitted':
          if (isDarkTheme) {
            statusColor = 'color: lightgreen;'
          } else {
            statusColor = 'color: darkgreen;'
          }

          break
        case 'hold':
          if (isDarkTheme) {
            statusColor = 'color: yellow;'
          } else {
            statusColor = 'color: darkgoldenrod;'
          }

          break
        case 'cancelled':
          if (isDarkTheme) {
            statusColor = 'color: lightcoral;'
          } else {
            statusColor = 'color: darkred;'
          }

          break
        /*
        case 'complete':
          statusColor = 'color: green;'
          break
         */
        default:
          statusColor = ''
          break
      }

      return statusColor
    },
  },
})
sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
