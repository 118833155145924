var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navigation-label',{attrs:{"label":(_vm.$attrs.label ? _vm.$attrs.label : _vm.displayAsSingular),"displayAsSingular":_vm.displayAsSingular,"displayAsPlural":_vm.displayAsPlural,"tooltip":(_vm.displayAsSingular + " Maintenance"),"routeTo":"../assemblies"}}),_c('div',{staticClass:"d-inline pl-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","color":"secondary"},on:{"click":_vm.duplicate_onClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1)]}}])},[_c('span',[_vm._v("Duplicate "+_vm._s(_vm.displayAsSingular))])])],1),_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"11"}},[_c('v-combobox',{staticClass:"ml-1 mt-0 mb-0",attrs:{"id":("key-assembly-" + (_vm.$attrs.id)),"items":_vm.assemblies.data,"item-text":"description","item-value":"id","autofocus":"","outlined":"","dense":"","clearable":"","hint":("Select an " + _vm.displayAsSingular + " " + (_vm.$attrs.appendhint)),"loading":_vm.assemblies.isBusy,"rules":[_vm.appRules.validOption(_vm.internalValue, _vm.assemblies.data, (_vm.displayAsSingular + " does not exist in the list."))]},on:{"change":_vm.onChange},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1),_c('v-col',{class:_vm.colClass,attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","color":"primary","disabled":_vm.disablePrevious},on:{"click":_vm.previous_onClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-skip-previous-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Previous "+_vm._s(_vm.displayAsSingular))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","color":"primary","disabled":_vm.disableNext},on:{"click":_vm.next_onClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-skip-next-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Next "+_vm._s(_vm.displayAsSingular))])])],1)],1)],1),_c('v-dialog',{key:_vm.renderKey,ref:"editDialog",attrs:{"width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('edit-assembly',{ref:"formEditAssembly",attrs:{"edited-index":_vm.editedIndex,"showTitle":true,"showActions":true,"duplicate-mode":true},on:{"cancel":_vm.cancelEdit,"save":_vm.saveEdit},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }