<template>
  <div>
    <v-data-table
      ref="maintenanceTableCustomer"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="id"
      class="elevation-2"
      show-select
      show-expand
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.data.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.id="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.id }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.client_logo_url="{ item }">
        <v-img
          class="d-inline d-flex align-center"
          width="50"
          :src="item.client_logo_url"
        />
      </template>

      <template v-slot:item.address1="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.address1 }}
        </div>
      </template>

      <template v-slot:item.phone="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ formatPhone(item.phone) }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          :disabled="isDisabled(item.id)"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          class="pt-4 pb-4"
          :colspan="getLengthOf(headers) + 1"
          @contextmenu:row="openContextMenu"
        >
          <!-- Report Settings -->
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              offset-sm="2"
              sm="2"
            >
              <!-- TODO: Make this a component and reuse here and in Edit Dialog -->
              <h3>Report Settings</h3>
              <!-- Logo Height -->
              <div
                class="pt-4 mb-3"
              >
                <v-text-field
                  v-model="item.logo_max_height"
                  label="Logo Max Height"
                  class="font-weight-medium text-body-1"
                  dense
                  clearable
                  maxlength="10"
                  @blur="item_onBlur(item)"
                  @click="input_onClick"
                />
              </div>
              <!-- Logo Width -->
              <div
                class="mb-3"
              >
                <v-text-field
                  v-model="item.logo_max_width"
                  label="Logo Max Width"
                  class="font-weight-medium text-body-1"
                  dense
                  clearable
                  maxlength="10"
                  @blur="item_onBlur(item)"
                  @click="input_onClick"
                />
              </div>
              <!-- Export Type -->
              <div
                class="mb-3"
              >
                <pos-export-type
                  ref="customerPosExportType"
                  v-model="item.pos_export_type"
                  label="POS Export Type"
                  @change="posExportType_onChange($event, item)"
                />
              </div>
              <!-- BMS Customer # -->
              <div
                v-if="item.pos_export_type === 'BMS'"
                class="mb-3"
              >
                <v-text-field
                  v-model="item.bms_customer_nbr"
                  label="BMS Customer #"
                  class="font-weight-medium text-body-1"
                  dense
                  clearable
                  maxlength="10"
                  @blur="item_onBlur(item)"
                  @click="input_onClick"
                />
              </div>
            </v-col>
            <!-- Client Logo -->
            <v-col
              cols="12"
              offset-sm="3"
              sm="5"
            >
              <img
                :key="(item.logo_max_height ? item.logo_max_height : 0) + (item.logo_max_width ? item.logo_max_width : 0)"
                :style="getLogoStyle(item)"
                :src="item.client_logo_url"
                alt="Client Logo"
              />
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-container
            class="pl-1 pt-2 pr-1 pb-1"
          >
            <!-- Id -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-id
                  ref="editedId"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerId.text"
                  :originalData="maintenanceState.originalData"
                  :autofocus="(editedIndex < 0)"
                  :disabled="(editedIndex >= 0)"
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Description / Name -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-description
                  ref="editedDescription"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerName.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Logo -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-file-input
                  label="Customer Logo"
                  prepend-icon="mdi-camera"
                  accept="image/png, image/jpg, image/jpeg, image/bmp"
                  chips
                  show-size
                  @change="uploadLogo"
                />
              </v-col>
            </v-row>
            <!-- Address -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-address
                  ref="editedAddress"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerAddress.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Phone -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-phone
                  ref="editedPhone"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerPhone.text"
                  :originalData="maintenanceState.originalData"
                  @change="field_onChange"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :use-customer-detail="false"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />

    <div style="display: none;">
      <v-list-item
        id="navToChildMaintenance"
        to="../plans"
      />
    </div>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainAddress from '@/components/maintenance/controls/Address'
  import MaintainPhone from '@/components/maintenance/controls/Phone'
  import { Storage } from 'aws-amplify'
  import PosExportType from '@/components/maintenance/controls/PosExportType'

  export default {
    name: 'CustomerTable',

    components: {
      PosExportType,
      DeleteDialog,
      ContextMenu,
      MaintainId,
      MaintainDescription,
      MaintainAddress,
      MaintainPhone,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'formatPhone',
      ]),
      ...mapGetters('customer', [
        'getLogoStyle',
      ]),
      appHeaders: get('app/headers'),
      displayAsSingular: get('customer/displayAsSingular'),
      displayAsPlural: get('customer/displayAsPlural'),
      maintenanceState: get('customer/customers'),
      disableNew () {
        // TODO: Future use of disable new Customer may be needed
        return false
      },
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        } else {
          this.editedItem.phone = this.formatPhone(this.editedItem.phone)
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerId.width = '10%'
        this.maintenanceState.headers.headerName.width = '28%'
        this.maintenanceState.headers.headerLogo.width = '1%'
        this.maintenanceState.headers.headerAddress.width = '34%'
        this.maintenanceState.headers.headerPhone.width = '25%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerName)
        this.headers.push(this.maintenanceState.headers.headerLogo)
        this.headers.push(this.maintenanceState.headers.headerAddress)
        this.headers.push(this.maintenanceState.headers.headerPhone)
        this.headers.push(this.appHeaders.headerActions)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      initialize () {
        this.$store.dispatch('customer/retrieveAll')

        this.setupHeaders()
      },

      uploadLogo (logo) {
        // TODO: don't actually upload until user accepts changes
        let logoPath
        if (logo) {
          logoPath = `customer/${this.editedItem.client_id}/${logo.name}`
        }

        if (logoPath.length > 0) {
          Storage.put(logoPath, logo)
            .then(() => {
              this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
              this.editedItem.client_logo = logo.name
            })
            .catch(err => {
              this.$store.dispatch('error/setError', { name: 'Uploading Logo', details: err })
            })
        }
      },

      setDialogEdit (val) {
        this.dialogEdit = val
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedAddress) {
          disabled = this.$refs.editedAddress.hasError()
        }

        if (!disabled && this.$refs.editedPhone) {
          disabled = this.$refs.editedPhone.$refs.textField.hasError
        }

        return disabled
      },

      isDisabled (itemId) {
        if (itemId === '0001') {
          return true
        } else {
          return false
        }
      },

      setAsUpdated (dataItem) {
        const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)

        if (originalData.length > 0) {
          if (dataItem.phone !== originalData[0].phone ||
            dataItem.logo_max_height !== originalData[0].logo_max_height ||
            dataItem.logo_max_width !== originalData[0].logo_max_width ||
            dataItem.pos_export_type !== originalData[0].pos_export_type ||
            dataItem.bms_customer_nbr !== originalData[0].bms_customer_nbr
          ) {
            this.$store.dispatch('user/setStateAsUpdated', dataItem)
          }
        }
      },

      async item_onBlur (dataItem) {
        await this.setAsUpdated(dataItem)

        if (dataItem.updated) {
          this.$store.dispatch('customer/update', dataItem)
        }
      },

      posExportType_onChange (evt, dataItem) {
        if (dataItem && this.$refs.customerPosExportType) {
          if (!this.$refs.customerPosExportType.$refs.combobox.hasError) {
            if (dataItem.pos_export_type !== 'BMS') {
              dataItem.bms_customer_nbr = null
            }
            dataItem.pos_export_type = evt.id
            this.item_onBlur(dataItem)
          }
        }
      },

      field_onChange () {
        this.setAsUpdated(this.editedItem)

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('customer/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.$store.dispatch('customer/update', this.editedItem)
          }
        }
      },

      row_onDblClick (dataItem) {
        this.$store.dispatch('customer/setSelected', dataItem)

        this.$nextTick(() => {
          document.getElementById('navToChildMaintenance').click()
        })
      },
    },
  }
</script>

<style scoped>

</style>
