// Pathify
import { make } from 'vuex-pathify'
import { Storage } from 'aws-amplify'

const _ = require('lodash')

const endpoint = 'https://ik3ry7hl15.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Customer',
  displayAsPlural: 'Customers',
  customers: {
    data: [],
    originalData: [],
    isBusy: false,
    isFirst: false,
    isLast: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    selectedFrom: { id: '', description: '', client_id: '' },
    setLast: false,
    empty: {
      id: null,
      client_id: null,
      description: null,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      email: '',
      phone: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      client_logo: null,
      client_logo_url: null,
      logo_max_height: null,
      logo_max_width: null,
      pos_export_type: null,
      bms_customer_nbr: null,
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerId: {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerName: {
        text: 'Name',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerLogo: {
        text: '',
        align: 'start',
        value: 'client_logo_url',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerAddress: {
        text: 'Address',
        align: 'start',
        value: 'address1',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerPhone: {
        text: 'Phone #',
        align: 'start',
        value: 'phone',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.customers.isBusy = isBusy
  },

  setSelected: (state, payload) => {
    state.customers.selected = []
    state.customers.selectedItem = { ...payload }
  },

  previous: (state, payload) => {},

  next: (state, payload) => {},

  setIsFirst: (state) => {
    let isFirst = false
    const selectedObject = state.customers.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.customers.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[0], 'id')) {
          const firstId = thisData[0].id

          if (firstId === selectedId) {
            isFirst = true
          }
        }
      } else {
        isFirst = true
      }
    } else {
      isFirst = true
    }

    state.customers.isFirst = isFirst
  },

  setIsLast: (state) => {
    let isLast = false
    const selectedObject = state.customers.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.customers.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[thisData.length - 1], 'id')) {
          const lastId = thisData[thisData.length - 1].id

          if (lastId === selectedId) {
            isLast = true
          }
        }
      } else {
        isLast = true
      }
    } else {
      isLast = true
    }

    state.customers.isLast = isLast
  },

  push2Data: async (state, dataFromDB) => {
    let itemFromDB
    let logoPath

    state.customers.data = []

    // Get a copy of the original data, not a pointer to it
    state.customers.originalData = []
    for (const currentData of dataFromDB) {
      // Copy Current Item to alter it
      itemFromDB = { ...currentData }

      if (itemFromDB.client_logo) {
        if (itemFromDB.client_logo.length > 0) {
          logoPath = `customer/${itemFromDB.client_id}/${itemFromDB.client_logo}`
          itemFromDB.client_logo_url = await Storage.get(logoPath)
        }
      }

      // Push altered data to data & originalData
      state.customers.data.push({ ...itemFromDB })
      state.customers.originalData.push({ ...itemFromDB })
    }

    state.customers.selected = []

    state.customers.isBusy = false
  },

  addAllOption: (state) => {
    const optionAll = { ...state.customers.empty }

    optionAll.id = 'ALL'
    optionAll.client_id = 'ALL'
    optionAll.description = 'All Customers'

    state.customers.data = []
    state.customers.data.push(optionAll)

    for (const currentData of state.customers.originalData) {
      state.customers.data.push({ ...currentData })
    }
  },

  removeAllOption: (state) => {
    state.customers.data = []

    for (const currentData of state.customers.originalData) {
      state.customers.data.push({ ...currentData })
    }
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: ({ commit, dispatch }) => {
    state.customers.selected = []
    state.customers.selectedItem = { id: '', description: '', client_id: '' }
    state.customers.data = []
    dispatch('folder/setEmpty', null, { root: true })
    dispatch('plan/setEmpty', null, { root: true })
    dispatch('category/setEmpty', null, { root: true })
    commit('setIsFirst')
    commit('setIsLast')
  },

  setFrom: ({ commit, dispatch, rootState }, payload) => {
    dispatch('plan/setEmptyFrom', null, { root: true })

    if (payload) {
      state.customers.selectedFrom = { ...payload }
      dispatch('plan/retrieveFrom', null, { root: true })
    } else {
      state.customers.selectedFrom = { id: '', description: '', client_id: '' }
    }
  },

  setDefault: async ({ dispatch, rootGetters }, payload) => {
    const defaultId = payload.default_client_id
    const dataFromDB = payload.dataFromDB

    if (defaultId !== null & dataFromDB !== null) {
      if (defaultId.length > 0 && dataFromDB.length > 0) {
        const defaultData = await rootGetters['app/getDataById'](payload.dataFromDB, defaultId)
        if (defaultData) {
          if (defaultData.length > 0) {
            dispatch('setFrom', defaultData[0])
            dispatch('setSelected', defaultData[0])
          }
        }
      }
    }
  },

  setSelected: ({ commit, dispatch, rootState }, payload) => {
    dispatch('folder/setEmpty', null, { root: true })
    dispatch('plan/setEmpty', null, { root: true })
    dispatch('category/setEmpty', null, { root: true })

    if (payload) {
      commit('setSelected', payload)
      dispatch('app/retrieveAll', null, { root: true })
    } else {
      state.customers.selectedItem = { id: '', description: '', client_id: '' }
    }
    commit('setIsFirst')
    commit('setIsLast')
  },

  refreshCustomer: ({ commit }, payload) => {
    if (payload) {
      commit('setSelected', payload)
    } else {
      state.customers.selectedItem = { id: '', description: '', client_id: '' }
    }
  },

  previous: ({ commit, dispatch, rootState }) => {
    let previousObject = null
    const selectedId = state.customers.selectedItem.id

    for (const existingObject of state.customers.data) {
      if (existingObject.id === selectedId) {
        break
      }

      previousObject = { ...existingObject }
    }

    if (previousObject) {
      rootState.assembly.assemblies.setLast = true
      rootState.division.divisions.setLast = true
      rootState.plan.plans.setLast = true
      dispatch('setSelected', previousObject)

      commit('previous', previousObject)
    }
  },

  next: ({ commit, dispatch }) => {
    let nextObject = null
    const selectedId = state.customers.selectedItem.id

    for (let thisIndex = state.customers.data.length - 1; thisIndex >= 0; thisIndex--) {
      const existingObject = state.customers.data[thisIndex]

      if (existingObject.id === selectedId) {
        break
      }

      nextObject = { ...existingObject }
    }

    if (nextObject) {
      dispatch('setSelected', nextObject)

      commit('next', nextObject)
    }
  },

  determineSelected: ({ dispatch, rootState }, dataFromDB) => {
    const thisAction = `Determine Selected ${state.displayAsSingular}`

    // Previous/Next logic
    if (dataFromDB.length > 0) {
      state.customers.setLast = false

      const selectedItem = state.customers.selectedItem
      if (selectedItem.id.length <= 0 && selectedItem.description.length <= 0) {
        dispatch('setDefault', { default_client_id: rootState.user.users.default.client_id, dataFromDB: dataFromDB })
      } else {
        const selectedFrom = state.customers.selectedFrom
        if (selectedFrom.id.length <= 0 && selectedFrom.description.length <= 0) {
          dispatch('setFrom', dataFromDB[0])
        }
      }
    }
  },

  retrieveAll: ({ commit, dispatch, rootGetters, rootState }) => {
    const thisAction = 'retrieveAllClients'
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}`

    const options = {
      method: 'get',
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        let data2Push

        if (_.isEmpty(jsonResponse.error)) {
          data2Push = rootGetters['user/getAllowedCustomers'](jsonResponse.data)
        } else {
          // toastColor = 'danger'
          // toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          commit(setIsBusy, false)
        }

        return data2Push
      })
      .then(data2Push => {
        try {
          commit('push2Data', data2Push)
        } catch (error) {
          console.error(`${thisAction} failed`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        }

        return data2Push
      })
      .then(async dataFromDB => {
        dispatch('determineSelected', dataFromDB)
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updateClient'
    commit(setIsBusy, true)

    payload.client_id = payload.id
    payload.name = payload.description

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.customers.selectedItem && {}.hasOwnProperty.call(state.customers.selectedItem, 'id')) {
            if (payload.client_id === state.customers.selectedItem.id) {
              dispatch('setSelected', payload)
            }

            if (payload.client_id === state.customers.selectedFrom.id) {
              dispatch('setFrom', payload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.customers.selected.length > 0) {
      const thisAction = 'deleteClient'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${state.customers.selected[0].client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.customers.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.customers.selected.length > 1) {
        toastPrefix = `${state.customers.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.customers.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.customers.selectedItem && {}.hasOwnProperty.call(state.customers.selectedItem, 'id')) {
              for (const currentData of state.customers.selected) {
                if (currentData.client_id === state.customers.selectedItem.id) {
                  dispatch('setSelected', state.customers.data[0])
                }

                if (currentData.client_id === state.customers.selectedFrom.id) {
                  dispatch('setFrom', state.customers.data[0])
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  addAllOption: ({ commit }) => {
    commit('addAllOption')
  },

  removeAllOption: ({ commit }) => {
    commit('removeAllOption')
  },
}

const getters = {
  getLogoStyle: (state) => (item) => {
    let logoStyle = ''

    if (item) {
      if (item.logo_max_height) {
        if (item.logo_max_height > 0) {
          logoStyle = `max-height: ${item.logo_max_height}px; `
        }
      }

      if (item.logo_max_width) {
        if (item.logo_max_width > 0) {
          logoStyle = `max-width: ${item.logo_max_width}px; `
        }
      }
    }

    return logoStyle
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
