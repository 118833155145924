<template>
  <div>
    <!-- TODO: Is it smart to use this component  for common code in QQ tables? Mostly working, but something doesn't seem right. -->
    <slot name="datatable" />

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <slot name="editcontent" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="$attrs.items"
      :display-as-singular="$attrs.singular"
      :display-as-plural="$attrs.plural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="$attrs.items"
      :display-as-singular="$attrs.singular"
      :display-as-plural="$attrs.plural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'

  export default {
    name: 'MaintenanceTable',

    components: {
      DeleteDialog,
      ContextMenu,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
      }
    },

    computed: {
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },

      formTitle () {
        return this.editedIndex === -1 ? `New ${this.$attrs.singular}` : `Edit ${this.$attrs.singular}`
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          // TODO: Add other fields? or remove this and always emit input change and let parent decide what to do?
          // if (val.description === oldVal.description) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },

      value: {
        handler (val, oldVal) {
          // if (val.description === oldVal.description) return

          this.internalValue = val
        },
        deep: true,
      },

      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    methods: {
      addItem () {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.$attrs.items.empty)

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.$attrs.items.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.dialogEdit = true
      },

      headersContains (headers, containsValue) {
        if (headers) {
          for (const header of headers) {
            if (header.value === containsValue) {
              return true
            }
          }
        }

        return false
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$emit('delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.$attrs.items.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.dialogEdit = false

        if (this.editedItem.updated) {
          this.$emit('update', this.editedItem)
        }
      },
    },
  }
</script>

<style scoped>

</style>
