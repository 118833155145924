var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"dense":"","disable-sort":"","headers":_vm.headers,"hide-default-footer":"","items":_vm.shippingReports.data,"item-key":"index","group-by":"phaseDescription","footer-props":{'items-per-page-options':[-1]},"options":_vm.options},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(items[0].phaseDescription)+" ")])]}},{key:"item.order_quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"1.2em"}},[_vm._v(_vm._s(item.order_quantity.toLocaleString("en-US")))])]}},{key:"item.itemDescription",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getPartDescription(item)))])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.unit))]),(item.note.length > 0)?_c('div',{staticClass:"primary--text font-italic font-weight-medium"},[_vm._v(" ( "+_vm._s(item.note)+" ) ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }