// Pathify
import { make } from 'vuex-pathify'

const _ = require('lodash')

const endpoint = 'https://bdoxfezzbj.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Estimator',
  displayAsPlural: 'Estimators',
  estimators: {
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    empty: {
      id: null,
      estimator_id: null,
      client_id: null,
      description: null,
      name: null,
      note: '',
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerId: {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerName: {
        text: 'Name',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.estimators.isBusy = isBusy
  },

  push2Data: (state, dataFromDB) => {
    state.estimators.data = []
    state.estimators.data = dataFromDB

    // Get a copy of the original data, not a pointer to it
    state.estimators.originalData = []
    for (const currentData of dataFromDB) {
      state.estimators.originalData.push({ ...currentData })
    }

    state.estimators.selected = []

    state.estimators.isBusy = false
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  retrieveAll: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveAllEstimator'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          commit('push2Data', jsonResponse.data)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updateEstimator'
    commit(setIsBusy, true)

    payload.estimator_id = payload.id
    payload.name = payload.description

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.estimators.selectedItem && {}.hasOwnProperty.call(state.estimators.selectedItem, 'id')) {
            if (payload.estimator_id === state.estimators.selectedItem.id) {
              dispatch('setSelected', payload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${payload.name}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.estimators.selected.length > 0) {
      const thisAction = 'deleteEstimator'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${state.estimators.selected[0].client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.estimators.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.estimators.selected.length > 1) {
        toastPrefix = `${state.estimators.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.estimators.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.estimators.selectedItem && {}.hasOwnProperty.call(state.estimators.selectedItem, 'id')) {
              for (const currentData of state.estimators.selected) {
                if (currentData.estimator_id === state.estimators.selectedItem.id) {
                  dispatch('setSelected', state.estimators.data[0])
                  break
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.name}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
           */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
