<template>
  <div>
    <v-data-table
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="id"
      class="elevation-2"
      show-select
      show-expand
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.data.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.id="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.id }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.unit="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.unit }}
        </div>
      </template>

      <template v-slot:item.special_order="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          <v-simple-checkbox
            v-model="item.special_order"
            color="primary"
            disabled
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length+1"
          @contextmenu:row="openContextMenu"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                sm="8"
                offset-sm="2"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeaders"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ item.description }} - {{ displayAsSingular }} Pricing Details
                    </h2>
                  </template>

                  <template v-slot:item.unit_cost>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.unit_cost }}
                    </div>
                  </template>

                  <template v-slot:item.price_level_1>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.price_level_1 }}
                    </div>
                  </template>

                  <template v-slot:item.price_level_2>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.price_level_2 }}
                    </div>
                  </template>

                  <template v-slot:item.price_level_3>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.price_level_3 }}
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-container
            class="pl-1 pt-2 pr-1 pb-1"
          >
            <!-- Id -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-id
                  ref="editedId"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerId.text"
                  :originalData="maintenanceState.originalData"
                  :autofocus="(editedIndex < 0)"
                  :disabled="(editedIndex >= 0)"
                  :required="true"
                  maxlength="20"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Description -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-description
                  ref="editedDescription"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerDescription.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Special Order -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-checkbox
                  v-model="editedItem.special_order"
                  :label="maintenanceState.headers.headerSpecialOrder.text"
                  color="primary"
                  class="pl-0 pt-0 pr-0 pb-0 ma-0 text-body-1 font-weight-regular"
                  dense
                  @change="field_onChange"
                />
              </v-col>
            </v-row>
            <!-- Unit -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <maintain-unit
                  ref="editedUnit"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerUnit.text"
                  :originalData="maintenanceState.originalData"
                  @change="field_onChange"
                />
              </v-col>
            </v-row>
            <!-- Unit Cost -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="editedUnitCost"
                  v-model="editedItem.unit_cost"
                  :label="maintenanceState.headers.headerCost.text"
                  dense
                  class="font-weight-medium text-body-1 numeric-input"
                  :rules="[appRules.isCurrency]"
                  @change="field_onChange"
                  @click="input_onClick"
                />
              </v-col>
            </v-row>
            <!-- Price Leve 1 -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="editedPriceLevel1"
                  v-model="editedItem.price_level_1"
                  :label="maintenanceState.headers.headerPriceLevel1.text"
                  dense
                  class="font-weight-medium text-body-1 numeric-input"
                  :rules="[appRules.isCurrency]"
                  @change="field_onChange"
                  @click="input_onClick"
                />
              </v-col>
            </v-row>
            <!-- Price Level 2 -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="editedPriceLevel2"
                  v-model="editedItem.price_level_2"
                  :label="maintenanceState.headers.headerPriceLevel2.text"
                  dense
                  class="font-weight-medium text-body-1 numeric-input"
                  :rules="[appRules.isCurrency]"
                  @change="field_onChange"
                  @click="input_onClick"
                />
              </v-col>
            </v-row>
            <!-- Price Level 3 -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="editedPriceLevel3"
                  v-model="editedItem.price_level_3"
                  :label="maintenanceState.headers.headerPriceLevel3.text"
                  dense
                  class="font-weight-medium text-body-1 numeric-input"
                  :rules="[appRules.isCurrency]"
                  @change="field_onChange"
                  @click="input_onClick"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainUnit from '@/components/maintenance/controls/Unit'

  export default {
    name: 'PartTable',

    components: {
      DeleteDialog,
      ContextMenu,
      MaintainId,
      MaintainDescription,
      MaintainUnit,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
        detailHeaders: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      categories: get('category/categories'),
      displayAsSingular: get('part/displayAsSingular'),
      displayAsPlural: get('part/displayAsPlural'),
      maintenanceState: get('part/parts'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disableNew () {
        let disabled = true

        if (this.categories.selectedItem) {
          if ({}.hasOwnProperty.call(this.categories.selectedItem, 'id')) {
            disabled = this.categories.selectedItem.id.length <= 0
          }
        }

        return disabled
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerId.width = '20%'
        this.maintenanceState.headers.headerDescription.width = '54%'
        this.maintenanceState.headers.headerSpecialOrder.width = '10%'
        this.maintenanceState.headers.headerUnit.width = '14%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.maintenanceState.headers.headerSpecialOrder)
        this.headers.push(this.maintenanceState.headers.headerUnit)
        this.headers.push(this.appHeaders.headerActions)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeaders () {
        this.maintenanceState.headers.headerCost.width = '25%'
        this.maintenanceState.headers.headerPriceLevel1.width = '25%'
        this.maintenanceState.headers.headerPriceLevel2.width = '25%'
        this.maintenanceState.headers.headerPriceLevel3.width = '25%'

        this.detailHeaders = []

        this.detailHeaders.push(this.maintenanceState.headers.headerCost)
        this.detailHeaders.push(this.maintenanceState.headers.headerPriceLevel1)
        this.detailHeaders.push(this.maintenanceState.headers.headerPriceLevel2)
        this.detailHeaders.push(this.maintenanceState.headers.headerPriceLevel3)
      },

      initialize () {
        this.$store.dispatch('part/retrieveAll')

        this.setupHeaders()

        this.setupDetailHeaders()
      },

      setDialogEdit (val) {
        this.dialogEdit = val
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedUnitCost) {
          disabled = this.$refs.editedUnitCost.hasError
        }

        if (!disabled && this.$refs.editedPriceLevel1) {
          disabled = this.$refs.editedPriceLevel1.hasError
        }

        if (!disabled && this.$refs.editedPriceLevel2) {
          disabled = this.$refs.editedPriceLevel2.hasError
        }

        if (!disabled && this.$refs.editedPriceLevel3) {
          disabled = this.$refs.editedPriceLevel3.hasError
        }

        return disabled
      },

      field_onChange () {
        const dataItem = this.editedItem
        const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)

        if (originalData.length > 0) {
          if (dataItem.unit !== originalData[0].unit ||
            dataItem.unit_cost !== originalData[0].unit_cost ||
            dataItem.special_order !== originalData[0].special_order ||
            dataItem.price_level_1 !== originalData[0].price_level_1 ||
            dataItem.price_level_2 !== originalData[0].price_level_2 ||
            dataItem.price_level_3 !== originalData[0].price_level_3) {
            this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
          }
        }

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('part/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.editedItem.vendor_id = this.categories.selectedItem.vendor_id

            this.$store.dispatch('part/update', this.editedItem)
          }
        }
      },
    },
  }
</script>

<style scoped>
  .numeric-input >>> input {
    text-align: right;
    min-width: 150px;
  }
/*
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
 */
</style>
