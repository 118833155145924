<template>
  <div>
    <v-data-table
      ref="maintenanceTablePrompt"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="sort_order"
      class="elevation-5"
      :style="cssTableBorder"
      show-select
      :hide-default-footer="maintenanceState.data.length <= 0"
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-center"
            />

            <v-col
              cols="12"
              sm="8"
              class="pa-0 ma-0 text-center"
            >
              <h2
                class="primary--text"
              >
                {{ getPromptGroupTitle() }}
              </h2>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-if="maintenanceState.data.length > 0"
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="12"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.sort_order="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.sort_order }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.value="{ item }">
        <v-text-field
          v-model="item.value"
          dense
          class="font-weight-medium text-body-1 numeric-input"
          @blur="value_onBlur(item)"
          @click="input_onClick"
          @keydown.enter.prevent="setFocusToIndex(3)"
        />
      </template>

      <template v-slot:item.variable="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.variable }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          tabindex="-1"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          tabindex="-1"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-container
            class="pl-1 pt-2 pr-1 pb-1"
          >
            <!-- Sort Order -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-sort-order
                  ref="editedSortOrder"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerSortOrder.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Description -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-description
                  ref="editedDescription"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerPrompt.text"
                  :originalData="maintenanceState.originalData"
                  autofocus
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Value -->
            <v-row
              v-if="showValueColumn"
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="4"
              >
                <maintain-value
                  ref="editedValue"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerValue.text"
                  :originalData="maintenanceState.originalData"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
            <!-- Variable -->
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col
                cols="12"
                sm="12"
              >
                <maintain-variable
                  ref="editedVariable"
                  v-model="editedItem"
                  :label="maintenanceState.headers.headerVariable.text"
                  :originalData="maintenanceState.originalData"
                  :required="true"
                  @change="disableEdit = getDisableEdit()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import MaintainSortOrder from '@/components/maintenance/controls/SortOrder'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainValue from '@/components/maintenance/controls/Value'
  import MaintainVariable from '@/components/maintenance/controls/Variable'
  import mathExp from 'math-expression-evaluator'
  import _ from 'lodash'

  export default {
    name: 'PromptTable',

    components: {
      DeleteDialog,
      ContextMenu,
      MaintainSortOrder,
      MaintainDescription,
      MaintainValue,
      MaintainVariable,
    },

    props: {
      parentItem: {
        type: Object,
        default: null,
      },
      showValueColumn: {
        type: Boolean,
        default: true,
      },
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      assemblies: get('assembly/assemblies'),
      promptGroups: get('promptgroup/promptGroups'),
      displayAsSingular: get('prompt/displayAsSingular'),
      displayAsPlural: get('prompt/displayAsPlural'),
      maintenanceState: get('prompt/prompts'),
      cssTableBorder () {
        return {
          'border-width': 'thin',
          'border-style': 'solid',
          'border-color': `${this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].secondary}`,
        }
      },
      disableNew () {
        let disabled = true

        if (this.parentItem) {
          if ({}.hasOwnProperty.call(this.parentItem, 'id')) {
            disabled = this.parentItem.id.length <= 0
          }
        }

        return disabled
      },
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'prompt/successfulUpdate':
            this.$store.dispatch('takeoff/recalculateTakeoff')
            break
          case 'prompt/successfulDelete':
            this.retrieve()
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerValue.width = '30%'
        this.maintenanceState.headers.headerVariable.width = '24%'

        this.headers = []

        if (this.showValueColumn) {
          this.maintenanceState.headers.headerPrompt.width = '45%'

          this.headers.push(this.maintenanceState.headers.headerPrompt)
          this.headers.push(this.maintenanceState.headers.headerValue)
          this.headers.push(this.maintenanceState.headers.headerVariable)
        } else {
          this.maintenanceState.headers.headerPrompt.width = '75%'

          this.headers.push(this.maintenanceState.headers.headerSortOrder)
          this.headers.push(this.maintenanceState.headers.headerPrompt)
          this.headers.push(this.maintenanceState.headers.headerVariable)
        }

        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        this.setupHeaders()
      },

      getKeys (dataItem) {
        dataItem.client_id = this.parentItem.client_id
        dataItem.pv_id = this.parentItem.pv_id

        if ({}.hasOwnProperty.call(this.parentItem, 'plan_id')) {
          dataItem.plan_id = this.parentItem.plan_id
        }
        if ({}.hasOwnProperty.call(this.parentItem, 'phase_id')) {
          dataItem.phase_id = this.parentItem.phase_id
        }
        if ({}.hasOwnProperty.call(this.parentItem, 'assembly_id')) {
          dataItem.assembly_id = this.parentItem.assembly_id
        }

        return dataItem
      },

      retrieve () {
        const dataItem = this.getKeys({})

        this.$nextTick(() => {
          if (this.showValueColumn) {
            this.$store.dispatch('prompt/retrieve', dataItem).then((response) => {
              if (response) {
                if (_.isEmpty(response.error)) {
                  this.$store.dispatch('takeoff/recalculateTakeoff')
                } else {
                  this.$store.dispatch('error/setError', { name: 'Retrieve Prompts', details: response.error })
                }
              }
            }).catch(error => {
              this.$store.dispatch('error/setError', { name: 'Retrieve Prompts Caught', details: error })
            })
          } else {
            this.$store.dispatch('prompt/retrieveByPromptGroupId', dataItem)
          }
        })
      },

      getPromptGroupTitle () {
        let promptGroupTitle

        if (this.maintenanceState.data.length > 0) {
          const promptGroup = this.getDataById(this.promptGroups.data, this.maintenanceState.data[0].pv_id)

          if (promptGroup.length > 0) {
            promptGroupTitle = promptGroup[0].description
          } else {
            promptGroupTitle = this.maintenanceState.data[0].prompt_group
          }
        } else {
          promptGroupTitle = this.parentItem.prompt_description
        }

        return promptGroupTitle
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      setKeyAndUpdate (dataItem) {
        dataItem = { ...this.getKeys(dataItem) }

        this.$nextTick(() => {
          this.$store.dispatch('prompt/update', dataItem)
        })
      },

      calculateValue (value, oldValue) {
        let newValue = value
        const mathOperations = ['+', '-', '*', '/']

        if (mathOperations.some(op => value.includes(op))) {
          if (mathOperations.some(op => value.startsWith(op))) {
            newValue = mathExp.eval(`${oldValue}${value}`)
          } else {
            newValue = mathExp.eval(value)
          }
        }
        return newValue
      },

      value_onBlur (dataItem) {
        const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)

        if (originalData.length > 0) {
          if (dataItem.value !== originalData[0].value) {
            dataItem.value = this.calculateValue(dataItem.value, originalData[0].value)

            this.$store.dispatch('user/setStateAsUpdated', dataItem)

            this.setKeyAndUpdate(dataItem)
          }
        }
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedSortOrder) {
          disabled = this.$refs.editedSortOrder.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedValue) {
          disabled = this.$refs.editedValue.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedVariable) {
          disabled = this.$refs.editedVariable.$refs.textField.hasError
        }

        return disabled
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('prompt/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.setKeyAndUpdate(this.editedItem)
          }
        }
      },

      row_onDblClick (dataItem) {
        // this.setSelected(dataItem)
        // document.getElementById('navToChildMaintenance').click()
      },

      setFocusToIndex (advanceIndexBy) {
        const form = event.target.form
        const index = [...form].indexOf(event.target)
        form.elements[index + advanceIndexBy].focus()
        form.elements[index + advanceIndexBy].select()
      },
    },
  }
</script>

<style scoped>
  .numeric-input >>> input {
    text-align: right;
    min-width: 150px;
  }
</style>
