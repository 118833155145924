<template>
  <maintenance-page
    :optionsname="$options.name"
    :singular="displayAsSingular"
    :plural="displayAsPlural"
    :headers="headers"
    :items="maintenanceState"
    :selectedkey="customers.selectedItem"
    @update="onUpdate"
    @delete="onDelete"
  >
    <template #keyselection>
      <select-customer
        v-if="customers.data.length > 1"
        id="2-maintain"
        v-model="customers.selectedItem"
        :appendhint="`to view its ${displayAsPlural}`"
      />
    </template>
  </maintenance-page>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DeliveryLoadsView',

    data () {
      return {
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsSingular: get('deliveryload/displayAsSingular'),
      displayAsPlural: get('deliveryload/displayAsPlural'),
      maintenanceState: get('deliveryload/deliveryloads'),
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerId.width = '10%'
        this.maintenanceState.headers.headerDescription.width = '89%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        this.$store.dispatch('deliveryload/retrieveAll')

        this.setupHeaders()
      },

      onUpdate (dataItem) {
        this.$store.dispatch('deliveryload/update', dataItem)
      },

      onDelete () {
        this.$store.dispatch('deliveryload/delete')
      },
    },
  }
</script>
