<template>
  <div>
    <v-data-table
      ref="maintenanceTablePlan"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      item-key="id"
      :item-class="getRowClass"
      sort-by="id"
      class="elevation-2"
      show-select
      group-by="folder_description"
      :show-expand="headersContains(headers,'data-table-expand')"
      :loading="getIsBusy"
      :search="search"
      :footer-props="{'items-per-page-options':[5,10,15,30,-1]}"
      :options="options"
      hide-default-footer
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="getLengthOf(maintenanceState.data) > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    @click="refresh_onClick"
                  >
                    <v-icon class="pr-8">mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh {{ displayAsPlural }}</span>
              </v-tooltip>

              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- Header slots -->
      <template v-slot:header.status_cd="{ header }">
        <v-menu
          v-if="!header.sortable"
          transition="slide-y-transition"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- @click.prevent="sortStatus_onClick" -->
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-filter-variant
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in maintenanceState.StatusCode"
              :key="i"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        {{ header.text }}
      </template>

      <template v-slot:group.header="{items, isOpen, toggle}">
        <!-- TODO: Double Click nav to Folder Maint -->
        <th
          colspan="100%"
          @click="toggle"
        >
          <v-icon
            color="secondary"
          >
            {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          {{ items[0].folder_id ? items[0].folder_description : 'Unassigned' }}
        </th>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.client_id="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-medium text-body-1"
              v-on="on"
            >
              {{ getCustomerName(item.client_id) }}
            </div>
          </template>
          <span>{{ displayAsSingularCustomer }} ID: {{ item.client_id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.id="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.id }}
        </div>
      </template>

      <template v-slot:item.description="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.estimator_name="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.estimator_name }}
        </div>
      </template>
      <!-- TODO: Change color if Rush -->
      <template v-slot:item.due_date="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ displayDate(item.due_date) }}
        </div>
      </template>

      <template v-slot:item.status_cd="{ item }">
        <div
          v-if="item.submitted_date || item.received_date || item.finish_date"
          class="font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
              >
                {{ (item.status_cd ? maintenanceState.StatusCode[item.status_cd] : '') }}
              </span>
            </template>
            <div
              v-if="item.submitted_date"
            >
              Submitted: {{ displayDate(item.submitted_date) }}
            </div>
            <div
              v-if="item.received_date"
            >
              Received: {{ displayDate(item.received_date) }}
            </div>
            <div
              v-if="item.finish_date"
            >
              Completed: {{ displayDate(item.finish_date) }}
            </div>
          </v-tooltip>
        </div>
        <div
          v-else
          class="font-weight-medium text-body-1"
          :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
        >
          {{ (item.status_cd ? maintenanceState.StatusCode[item.status_cd] : '') }}
        </div>
      </template>

      <template v-slot:item.salesperson_name="{ item }">
        <div
          class="font-weight-medium text-body-1"
          @dblclick="row_onDblClick(item)"
        >
          {{ item.salesperson_name }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="getLengthOf(headers)+1"
          @contextmenu:row="openContextMenu"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeadersDates"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ item.description }} - {{ displayAsSingular }} Details
                    </h2>
                  </template>

                  <template v-slot:item.due_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                      @dblclick="row_onDblClick(item)"
                    >
                      {{ displayDate(item.due_date) }}
                    </div>
                  </template>

                  <template v-slot:item.submitted_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                      @dblclick="row_onDblClick(item)"
                    >
                      {{ displayDate(item.submitted_date) }}
                    </div>
                  </template>

                  <template v-slot:item.received_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                      @dblclick="row_onDblClick(item)"
                    >
                      {{ displayDate(item.received_date) }}
                    </div>
                  </template>

                  <template v-slot:item.finish_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                      @dblclick="row_onDblClick(item)"
                    >
                      {{ displayDate(item.finish_date) }}
                    </div>
                  </template>
                </v-data-table>

                <v-data-table
                  :headers="detailHeadersNames"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:item.estimator_name="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.estimator_name }}
                    </div>
                  </template>

                  <template v-slot:item.salesperson_name="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.salesperson_name }}
                    </div>
                  </template>
                </v-data-table>

                <v-data-table
                  :headers="detailHeadersFooter"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:item.heated_sqft>
                    <div
                      class="font-weight-medium text-body-1 text-right"
                    >
                      {{ item.heated_sqft.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.unheated_sqft>
                    <div
                      class="font-weight-medium text-body-1 text-right"
                    >
                      {{ item.unheated_sqft.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.total_sqft>
                    <div
                      class="font-weight-medium text-body-1 text-right"
                    >
                      {{ (item.heated_sqft + item.unheated_sqft).toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.address>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address1 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address2 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ (item.city ? `${item.city},` : '') }} {{ item.state }} {{ item.zip }}
                    </div>
                  </template>

                  <template v-slot:item.note>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.note }}
                    </div>
                  </template>

                  <template v-slot:footer>
                    <list-documents
                      :key="item.documents_count"
                      label="Documents"
                      :value="item"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <edit-plan
        ref="formEditPlan"
        v-model="editedItem"
        :edited-index="editedIndex"
        :showTitle="true"
        :showActions="true"
        @cancel="cancelEdit"
        @close="closeDialog"
      />
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :showArchiveOption="true"
      @archiveitem="menuArchive_onClick"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />

    <div style="display: none;">
      <v-list-item
        id="navToChildMaintenance"
        to="../divisions"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rowClass {
  text-align: left;
}
.numeric-input >>> input {
  text-align: right;
  min-width: 150px;
}
/*
  .tile:hover {
    background-color: var(--secondary-color);
  }
  .itemClass {
    @extend %font-weight-medium;
    @extend %text-body-1;
  }
 */
</style>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import EditPlan from '@/components/maintenance/forms/EditPlan'
  import ListDocuments from '@/components/maintenance/controls/ListDocuments'

  export default {
    name: 'PlanTable',

    props: {
      enableAllCustomers: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    components: {
      DeleteDialog,
      ContextMenu,
      EditPlan,
      ListDocuments,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        options: {
          itemsPerPage: -1,
        },
        headers: [],
        detailHeadersDates: [],
        detailHeadersNames: [],
        detailHeadersFooter: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'getValidDate',
        'formatDate',
        'generatePlanId',
        'addDays',
      ]),
      appHeaders: get('app/headers'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      maintenanceState: get('plan/plans'),
      displayAsSingularCustomer: get('customer/displayAsSingular'),
      customers: get('customer/customers'),
      archives: get('archive/archives'),
      getIsBusy () {
        return this.maintenanceState.isBusy || this.archives.isBusy
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disableNew () {
        // TODO: Future use of disable new Plan may be needed
        return false
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()
    },

    mounted () {
      this.collapseGroups()
    },

    methods: {
      setupHeaders () {
        this.headers = []

        if (this.enableAllCustomers) {
          this.maintenanceState.headers.headerCustomer.width = '20%'
          this.maintenanceState.headers.headerDescription.width = '30%'
          this.maintenanceState.headers.headerEstimator.width = '20%'
          this.maintenanceState.headers.headerStatus.width = '20%'
          this.maintenanceState.headers.headerDueDate.width = '10%'

          /*
          this.maintenanceState.headers.headerStatus.filter = statusFilter
          ,
            statusFilter: value => {
              if (!this.calories) return true

              return value < parseInt(this.calories)
            },
           */
          this.headers.push(this.maintenanceState.headers.headerCustomer)
          this.headers.push(this.maintenanceState.headers.headerDescription)
          this.headers.push(this.maintenanceState.headers.headerEstimator)
          this.headers.push(this.maintenanceState.headers.headerStatus)
          this.headers.push(this.maintenanceState.headers.headerDueDate)
        } else {
          this.maintenanceState.headers.headerId.width = '10%'
          this.maintenanceState.headers.headerDescription.width = '38%'
          this.maintenanceState.headers.headerEstimator.width = '28%'
          this.maintenanceState.headers.headerStatus.width = '12%'
          this.maintenanceState.headers.headerDueDate.width = '10%'

          this.headers.push(this.maintenanceState.headers.headerId)
          this.headers.push(this.maintenanceState.headers.headerDescription)
          this.headers.push(this.maintenanceState.headers.headerEstimator)
          this.headers.push(this.maintenanceState.headers.headerStatus)
          this.headers.push(this.maintenanceState.headers.headerDueDate)
        }

        this.headers.push(this.maintenanceState.headers.headerFolderId)
        this.headers.push(this.maintenanceState.headers.headerFolderDescription)
        this.headers.push(this.appHeaders.headerActions)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeadersDates () {
        const headerDueDate = { ...this.maintenanceState.headers.headerDueDate }
        const headerSubmittedDate = { ...this.maintenanceState.headers.headerSubmittedDate }
        const headerReceivedDate = { ...this.maintenanceState.headers.headerReceivedDate }
        const headerCompletedDate = { ...this.maintenanceState.headers.headerCompletedDate }

        headerDueDate.width = '25%'
        headerDueDate.sortable = false
        headerSubmittedDate.width = '25%'
        headerSubmittedDate.sortable = false
        headerReceivedDate.width = '25%'
        headerReceivedDate.sortable = false
        headerCompletedDate.width = '25%'
        headerCompletedDate.sortable = false

        this.detailHeadersDates = []

        this.detailHeadersDates.push(headerDueDate)
        this.detailHeadersDates.push(headerSubmittedDate)
        this.detailHeadersDates.push(headerReceivedDate)
        this.detailHeadersDates.push(headerCompletedDate)
      },

      setupDetailHeadersNames () {
        const headerEstimator = { ...this.maintenanceState.headers.headerEstimator }
        const headerSalesperson = { ...this.maintenanceState.headers.headerSalesperson }

        headerEstimator.width = '40%'
        headerEstimator.sortable = false
        headerSalesperson.width = '60%'
        headerSalesperson.sortable = false

        this.detailHeadersNames = []

        this.detailHeadersNames.push(headerEstimator)
        this.detailHeadersNames.push(headerSalesperson)
      },

      setupDetailHeadersFooter () {
        this.maintenanceState.headers.headerHeatedSqFt.width = '10%'
        this.maintenanceState.headers.headerUnheatedSqFt.width = '10%'
        this.maintenanceState.headers.headerTotalSqFt.width = '10%'
        this.maintenanceState.headers.headerAddress.width = '25%'
        this.maintenanceState.headers.headerNote.width = '45%'

        this.detailHeadersFooter = []

        this.detailHeadersFooter.push(this.maintenanceState.headers.headerHeatedSqFt)
        this.detailHeadersFooter.push(this.maintenanceState.headers.headerUnheatedSqFt)
        this.detailHeadersFooter.push(this.maintenanceState.headers.headerTotalSqFt)
        this.detailHeadersFooter.push(this.maintenanceState.headers.headerAddress)
        this.detailHeadersFooter.push(this.maintenanceState.headers.headerNote)
      },

      initialize () {
        this.$store.dispatch('plan/retrieveAll')
        this.$store.dispatch('folder/retrieveAll')
        this.$store.dispatch('estimator/retrieveAll')
        this.$store.dispatch('salesperson/retrieveAll')

        this.setupHeaders()

        this.setupDetailHeadersDates()
        this.setupDetailHeadersNames()
        this.setupDetailHeadersFooter()
      },

      collapseGroups () {
        const table = this.$refs.maintenanceTablePlan
        const keys = Object.keys(table.$vnode.componentInstance.openCache)
        keys.forEach(dataGroup => {
          if (dataGroup) {
            table.$vnode.componentInstance.openCache[dataGroup] = false
          }
        })
      },

      getRowClass () {
        return 'rowClass'
        // return 'font-weight-medium text-body-1'
      },

      setDialogEdit (val) {
        this.dialogEdit = val
      },

      refresh_onClick () {
        this.$store.dispatch('plan/retrieveAll')
      },

      displayDate (thisDate) {
        return this.formatDate(thisDate)
      },

      getCustomerName (clientId) {
        let customerName = ''
        const customerData = this.getDataById(this.customers.data, clientId)

        if (this.getLengthOf(customerData) > 0) {
          customerName = customerData[0].description
        }

        return customerName
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)
        this.editedItem.id = this.generatePlanId(this.maintenanceState.originalData)
        this.editedItem.plan_id = this.editedItem.id
        this.editedItem.status_cd = 'submitted'
        this.editedItem.submitted_date = this.getValidDate(null)
        this.editedItem.due_date = this.addDays(this.editedItem.submitted_date, this.editedItem.return_cd).toString()

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      sqft_onBlur (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)
          if (this.getLengthOf(originalData) > 0) {
            if (dataItem.heated_sqft !== originalData[0].heated_sqft || dataItem.unheated_sqft !== originalData[0].unheated_sqft) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
      },

      forceRerender () {
        this.renderKey += 1
        // this.$refs.formEditPlan.forceRerender()
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = true
        this.dialogEdit = true
      },

      headersContains (headers, containsValue) {
        if (headers) {
          for (const header of headers) {
            if (header.value === containsValue) {
              return true
            }
          }
        }

        return false
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('plan/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuArchive_onClick () {
        this.$store.dispatch('archive/archivePlans')
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.$refs.formEditPlan.saveEdit()
      },

      closeDialog () {
        this.dialogEdit = false
      },

      row_onDblClick (dataItem) {
        if (!this.enableAllCustomers) {
          this.$store.dispatch('plan/setSelected', dataItem)

          this.$nextTick(() => {
            document.getElementById('navToChildMaintenance').click()
          })
        }
      },
    },
  }
</script>
