<template>
  <v-container
    :id="`${displayAsPlural.toLowerCase().replaceAll(' ', '-')}-view`"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>{{ displayAsSingular }} Builder</h3>
                </v-col>

                <v-col
                  sm="4"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    @click="nextStep_onClick"
                  >
                    {{ nextStep }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container
              class="py-0 pb-5"
            >
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <v-stepper v-model="wizardStep">
                    <v-stepper-header>
                      <v-stepper-step
                        step=1
                        :complete="wizardStep > 1"
                        :editable="wizardStep > 1"
                        :color="(wizardStep > 1 ? 'secondary' : 'primary')"
                      >
                        Create a {{ displayAsSingular }}
                      </v-stepper-step>

                      <v-divider />

                      <v-stepper-step
                        step=2
                        :complete="wizardStep > 2"
                        :editable="wizardStep > 2"
                        :color="(wizardStep > 2 ? 'secondary' : 'primary')"
                      >
                        Copy Assemblies
                      </v-stepper-step>

                      <v-divider />

                      <v-stepper-step
                        step=3
                        :complete="wizardStep > 3"
                        :editable="wizardStep > 3"
                        :color="(wizardStep > 3 ? 'secondary' : 'primary')"
                      >
                        Perform the Takeoff
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- Create a Plan -->
                      <v-stepper-content step=1>
                        <v-row
                          v-if="customers.data.length > 1"
                          no-gutters
                        >
                          <v-col
                            sm="12"
                            md="8"
                            lg="6"
                          >
                            <select-customer
                              id="2-maintain-customer"
                              v-model="customers.selectedItem"
                              :appendhint="`to add to its ${displayAsPlural}`"
                              @change="customer_onChange"
                            />
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col
                            cols="12"
                          >
                            <edit-plan
                              ref="formEditPlan"
                              v-model="editedItem"
                              :showTitle="false"
                              :showActions="false"
                            />
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                      <!-- Copy Assemblies -->
                      <v-stepper-content step=2>
                        <copy-for-takeoff
                          ref="formCopyForTakeoff"
                        />
                      </v-stepper-content>
                      <!-- Perform the Takeoff -->
                      <v-stepper-content step=3>
                        <perform-takeoff
                          ref="formPerformTakeoff"
                        />
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <template #actions>
            <v-container class="py-0">
              <v-row>
                <v-col
                  sm="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    @click="nextStep_onClick"
                  >
                    {{ nextStep }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import MaterialCard from '@/components/MaterialCard'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import EditPlan from '@/components/maintenance/forms/EditPlan'
  import CopyForTakeoff from '@/components/maintenance/forms/CopyForTakeoff'
  import PerformTakeoff from '@/components/maintenance/forms/PerformTakeoff'

  export default {
    name: 'PlanBuilderView',

    components: {
      MaterialCard,
      SelectCustomer,
      EditPlan,
      CopyForTakeoff,
      PerformTakeoff,
    },

    data () {
      return {
        appItem: null,
        wizardStep: 1,
        lastCompletedStep: 0,
        steps: {
          one: 'New Plan',
          two: 'Copy',
          three: 'Takeoff',
        },
        editedItem: {},
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
        'generatePlanId',
        'getValidDate',
        'addDays',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      plans: get('plan/plans'),
      nextStep () {
        let nextStep = 'Next Step'

        switch (this.wizardStep.toString()) {
          case '1':
            nextStep = `Next Step: ${this.steps.two}`
            break
          case '2':
            nextStep = `Next Step: ${this.steps.three}`
            break
          case '3':
            nextStep = `Next Step: ${this.steps.one}`
            break
          default:
            nextStep = 'Next Step'
            break
        }

        return nextStep
      },
    },

    created () {
      this.appItem = this.getAppItem(this.$options.name)
      this.$store.dispatch('user/setPageName', this.$options.name)
      this.addItem()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/successfulUpdate':
            if (this.lastCompletedStep === 1) {
              this.completeThisStep()
            }

            break
          case 'takeoff/successfulUpdate':
            if (this.lastCompletedStep === 2) {
              this.completeThisStep()
            }

            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      addItem () {
        this.editedItem = Object.assign({}, this.plans.empty)
        this.editedItem.id = this.generatePlanId(this.plans.originalData)
        this.editedItem.plan_id = this.editedItem.id
        this.editedItem.status_cd = 'submitted'
        this.editedItem.submitted_date = this.getValidDate(null)
        this.editedItem.due_date = this.addDays(this.editedItem.submitted_date, this.editedItem.return_cd).toString()

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      customer_onChange () {
        this.$nextTick(() => {
          this.$store.dispatch('customer/setFrom', this.customers.selectedItem)
        })
      },

      completeThisStep () {
        this.editedItem.plan_id = this.editedItem.id
        this.$store.dispatch('plan/setSelected', this.editedItem)

        this.$nextTick(() => {
          this.wizardStep = parseInt(this.wizardStep, 0) + 1
        })
      },

      nextStep_onClick () {
        switch (this.wizardStep.toString()) {
          case '1':
            if (!this.$refs.formEditPlan.getDisableEdit()) {
              this.lastCompletedStep = 1
              this.editedItem.client_id = this.customers.selectedItem.client_id

              this.$store.dispatch('plan/update', this.editedItem)
            }

            break
          case '2':
            if (!this.$refs.formCopyForTakeoff.disableSubmit()) {
              this.lastCompletedStep = 2
              this.$refs.formCopyForTakeoff.submit_onClick()
            }

            break
          case '3':
            this.addItem()
            this.lastCompletedStep = 3
            this.wizardStep = 1

            break
          default:
            this.lastCompletedStep = 0
            this.wizardStep = 1

            break
        }
      },
    },
  }
</script>
