<template>
  <v-container
    id="global-replace-view"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <v-overlay :value="isBusy">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          />
        </v-overlay>

        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>Global Replace {{ titleSuffix }}</h3>
                </v-col>

                <v-col
                  sm="4"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    :disabled="disableNextStep"
                    @click="nextStep_onClick"
                  >
                    {{ nextStep }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container
              class="py-0 pb-5"
            >
              <!-- Help Row -->
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-center pb-2"
                >
                  This wizard will assist you in making bulk changes to the data in your database, quickly and easily.
                </v-col>
              </v-row>

              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <v-stepper v-model="wizardStep">
                    <v-stepper-header>
                      <v-stepper-step
                        step=1
                        :complete="wizardStep > 1"
                        :editable="wizardStep > 1"
                        :color="(wizardStep > 1 ? 'secondary' : 'primary')"
                      >
                        {{ steps.one }}
                      </v-stepper-step>

                      <v-divider />

                      <v-stepper-step
                        step=2
                        :complete="wizardStep > 2"
                        :editable="wizardStep > 2"
                        :color="(wizardStep > 2 ? 'secondary' : 'primary')"
                      >
                        {{ steps.two }}
                      </v-stepper-step>

                      <v-divider />

                      <v-stepper-step
                        step=3
                        :complete="wizardStep > 3"
                        :editable="wizardStep > 3"
                        :color="(wizardStep > 3 ? 'secondary' : 'primary')"
                      >
                        {{ steps.three }}
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- Step 1 -->
                      <v-stepper-content step=1>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            class="text-center"
                          >
                            Select the type of data you would like to replace and the Plan(s) in which you would like to make your changes.
                          </v-col>
                        </v-row>

                        <v-row
                          no-gutters
                          align="center"
                        >
                          <v-col
                            cols="12"
                            sm="3"
                          >
                            <v-select
                              v-model="takeoffs.selectedDataType"
                              :items="dataTypes"
                              item-text="text"
                              item-value="value"
                              label="Data Type"
                              class="pl-1 pt-6 pb-0"
                              return-object
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, index }">
                                <v-icon>{{ item.icon }}</v-icon>
                                <span
                                  class="pl-3"
                                >
                                  {{ item.text }}
                                </span>
                              </template>
                            </v-select>
                          </v-col>

                          <v-col
                            cols="12"
                            offset-sm="1"
                            sm="8"
                          >
                            <v-checkbox
                              v-model="takeoffs.with_quantities_only"
                            >
                              <template v-slot:label>
                                <div
                                  :class="($vuetify.theme.dark ? 'white--text' : 'black--text')"
                                >
                                  With Quantities Only
                                </div>
                              </template>
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="customers.data.length > 1"
                          no-gutters
                        >
                          <v-col
                            sm="12"
                            md="8"
                            lg="6"
                          >
                            <select-customer
                              id="2-maintain-customer"
                              v-model="customers.selectedItem"
                              :appendhint="`to view its ${displayAsPlural}`"
                            />
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col
                            cols="12"
                          >
                            <navigation-label
                              label="Plan(s)"
                              :displayAsSingular="displayAsSingular"
                              :displayAsPlural="displayAsPlural"
                              :tooltip="`${displayAsSingular} Maintenance`"
                              routeTo="../plans"
                            />
                            <v-select
                              v-model="plans.selected"
                              :items="plans.data"
                              item-text="description"
                              item-value="plan_id"
                              class="pl-1"
                              return-object
                              multiple
                              small-chips
                              deletable-chips
                              outlined
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  ripple
                                  @click="toggleSelectAll"
                                >
                                  <v-list-item-action>
                                    <v-icon :color="plans.selected.length > 0 ? 'secondary' : ''">
                                      {{ selectAllIcon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Select All
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                      <!-- Step 2 -->
                      <v-stepper-content step=2>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            class="text-center"
                          >
                            Select the Divisions and Assemblies where you would like your replacements to be made.
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col
                            cols="12"
                          >
                            <v-treeview
                              v-model="plans.treeBranchesSelected"
                              return-object
                              selectable
                              selected-color="primary"
                              item-disabled="locked"
                              :items="plans.assemblyTreeData"
                              item-text="text"
                              shaped
                              hoverable
                              dense
                              open-on-click
                              transition
                              :open="initiallyOpen"
                            >
                              <template v-slot:prepend="{ item, open }">
                                <v-icon
                                  v-if="!item.file"
                                  color="secondary"
                                >
                                  {{ displayIcon(open, item) }}
                                </v-icon>
                                <v-icon
                                  v-else
                                  color="secondary"
                                >
                                  {{ 'mdi-file-question' }}
                                </v-icon>
                              </template>
                            </v-treeview>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                      <!-- Step 3 -->
                      <v-stepper-content step=3>
                        <v-row
                          no-gutters
                          class="mb-4"
                        >
                          <v-col
                            cols="12"
                            class="text-center"
                          >
                            Use the table below to specify the data to replace.
                          </v-col>
                        </v-row>

                        <v-row no-gutters>
                          <v-col
                            cols="12"
                          >
                            <global-replace-table
                              v-model="items2Replace"
                              :displayAsSingular="`Takeoff ${getDisplayAsSingular()}`"
                              :displayAsPlural="`Takeoff ${getDisplayAsPlural()}`"
                            />
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <template #actions>
            <v-container class="py-0">
              <v-row>
                <v-col
                  sm="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    :disabled="disableNextStep"
                    @click="nextStep_onClick"
                  >
                    {{ nextStep }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </material-card>

        <v-dialog
          ref="statusDialog"
          v-model="displayDialog"
          :width="dialogWidth"
          scrollable
          @keydown.esc.prevent="statusDialog_onClose"
          @keydown.enter.prevent="statusDialog_onClose"
        >
          <v-card>
            <v-card-title>
              <v-container>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-right pl-0 pt-0 pr-4 pb-0 text-no-wrap"
                              style="width:10%;"
                            >
                              <h3
                                class="primary--text"
                              >
                                Items Replaced
                              </h3>
                            </th>
                            <th
                              class="text-left pl-0 pt-0 pr-0 pb-0"
                              style="width:45%;"
                            >
                              <h3
                                class="primary--text"
                              >
                                Previous Value
                              </h3>
                            </th>
                            <th
                              class="text-left pl-0 pt-0 pr-0 pb-0"
                              style="width:45%;"
                            >
                              <h3
                                class="primary--text"
                              >
                                New Value
                              </h3>
                            </th>
                          </tr>
                        </thead>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(replacedItem, index) in takeoffs.itemsReplaced"
                            :key="index"
                          >
                            <td
                              :class="`${($vuetify.theme.dark ? 'white--text' : 'black--text')} font-weight-medium text-body-1 pl-0 pt-0 pr-4 pb-0 text-right`"
                              style="width:10%;"
                            >
                              {{ replacedItem.replacedCount}}
                            </td>
                            <td
                              :class="`${($vuetify.theme.dark ? 'white--text' : 'black--text')} font-weight-medium text-body-1 pl-0 pt-0 pr-0 pb-0`"
                              style="width:45%;"
                            >
                              {{ getItemDescription('old', replacedItem) }}
                            </td>
                            <td
                              :class="`${($vuetify.theme.dark ? 'white--text' : 'black--text')} font-weight-medium text-body-1 pl-0 pt-0 pr-0 pb-0`"
                              style="width:45%;"
                            >
                              {{ getItemDescription('new', replacedItem) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                @click="statusDialog_onClose"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import MaterialCard from '@/components/MaterialCard'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import NavigationLabel from '@/components/maintenance/controls/NavigationLabel'
  import GlobalReplaceTable from '@/components/maintenance/tables/GlobalReplaceTable'

  export default {
    name: 'GlobalReplaceView',

    components: {
      MaterialCard,
      SelectCustomer,
      NavigationLabel,
      GlobalReplaceTable,
    },

    data () {
      return {
        appItem: null,
        wizardStep: 1,
        lastCompletedStep: 0,
        steps: {
          one: 'Data Type & Plan(s)',
          two: 'Divisions & Assemblies',
          three: 'Replace',
        },
        initiallyOpen: [],
        items2Replace: [],
        displayDialog: false,
        dataTypes: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
        'getDataById',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      takeoffs: get('takeoff/takeoffs'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      displayAsSingularPart: get('part/displayAsSingular'),
      displayAsPluralParts: get('part/displayAsPlural'),
      displayAsSingularLoad: get('deliveryload/displayAsSingular'),
      displayAsPluralLoads: get('deliveryload/displayAsPlural'),
      titleSuffix () {
        let suffix = ''

        if (this.takeoffs.selectedDataType) {
          suffix = ` - ${this.takeoffs.selectedDataType.text}`
        }

        return suffix
      },
      selectedAllPlans () {
        return this.plans.selected.length === this.plans.data.length
      },
      selectedSomePlans () {
        return this.plans.selected.length > 0 && !this.selectedAllPlans
      },
      selectAllIcon () {
        if (this.selectedAllPlans) return 'mdi-close-box'
        if (this.selectedSomePlans) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      disableNextStep () {
        let disabled = true

        switch (this.wizardStep.toString()) {
          case '1':
            if (this.takeoffs.selectedDataType && this.plans.selected.length > 0) {
              disabled = false
            }
            break
          case '2':
            if (this.plans.treeBranchesSelected.length > 0) {
              disabled = false
            }
            break
          case '3':
            if (this.items2Replace.length > 0) {
              disabled = false
            }
            break
          default:
            disabled = true
            break
        }

        return disabled
      },
      nextStep () {
        let nextStep = 'Next Step'

        switch (this.wizardStep.toString()) {
          case '1':
            nextStep = `Next Step: ${this.steps.two}`
            break
          case '2':
            nextStep = `Next Step: ${this.steps.three}`
            break
          case '3':
            nextStep = `Execute ${this.steps.three}`
            break
          default:
            nextStep = 'Next Step'
            break
        }

        return nextStep
      },
      isBusy () {
        let replaceIsBusy = false

        if (this.plans.isBusy || this.takeoffs.isBusy) {
          replaceIsBusy = true
        }

        return replaceIsBusy
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '90%'
            break
          case 'md':
            newWidth = '85%'
            break
          case 'lg':
            newWidth = '80%'
            break
          case 'xl':
            newWidth = '75%'
            break
        }
        return newWidth
      },
    },

    created () {
      this.appItem = this.getAppItem(this.$options.name)
      this.$store.dispatch('user/setPageName', this.$options.name)

      this.dataTypes.push(this.takeoffs.dataType.delivery_load)
      this.dataTypes.push(this.takeoffs.dataType.usage)
      this.dataTypes.push(this.takeoffs.dataType.part)
      this.takeoffs.selectedDataType = this.takeoffs.dataType.part

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/initializeAssemblyTreeData':
            this.lastCompletedStep = 1
            this.completeThisStep()
            break
          case 'takeoff/push2Parts':
          case 'takeoff/push2DeliveryLoads':
            this.lastCompletedStep = 2
            this.completeThisStep()
            break
          case 'takeoff/replaceResults':
            this.lastCompletedStep = 3

            this.$nextTick(() => {
              this.wizardStep = 1
              this.displayDialog = true
            })
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      getDisplayAsSingular () {
        switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
          case this.takeoffs.dataType.delivery_load.value.toLowerCase():
            return this.displayAsSingularLoad
          default:
            return this.displayAsSingularPart
        }
      },

      getDisplayAsPlural () {
        switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
          case this.takeoffs.dataType.delivery_load.value.toLowerCase():
            return this.displayAsPluralLoads
          default:
            return this.displayAsPluralParts
        }
      },

      completeThisStep () {
        this.$nextTick(() => {
          this.wizardStep = parseInt(this.wizardStep, 0) + 1
        })
      },

      nextStep_onClick () {
        let payload

        switch (this.wizardStep.toString()) {
          case '1':
            this.$store.dispatch('plan/retrieveAssemblyTree')
            break
          case '2':
            this.$store.dispatch('takeoff/retrieveTakeoffParts')
            break
          case '3':
            payload = {
              items2Replace: this.items2Replace,
            }
            this.$store.dispatch('takeoff/replaceTakeoffParts', payload)
            break
          default:
            this.lastCompletedStep = 0
            this.wizardStep = 1

            break
        }
      },

      toggleSelectAll () {
        this.$nextTick(() => {
          if (this.selectedAllPlans) {
            this.plans.selected = []
          } else {
            this.plans.selected = this.plans.data.slice()
          }
        })
      },

      displayIcon (open, item) {
        return open ? item.state.open_icon : item.state.close_icon
      },

      prepareTree () {
        this.$nextTick(() => {
          this.initiallyOpen.push({ id: 0, text: 'All Selected Plans' })
        })
      },

      getItemDescription (forItem, payload) {
        let itemDescription = 'unknown'
        let item2Replace = ''

        switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
          case this.takeoffs.dataType.delivery_load.value.toLowerCase():
            item2Replace = this.getDataById(this.items2Replace, `${payload.old_id}`)
            break
          default:
            item2Replace = this.getDataById(this.items2Replace, `${payload.old_vendor_id}::${payload.old_part_id}`)
            break
        }

        if (item2Replace.length > 0) {
          switch (forItem.toLowerCase()) {
            case 'old':
              itemDescription = item2Replace[0].description
              break
            case 'new':
              itemDescription = item2Replace[0].newValue_description
              break
            default:
              break
          }
        }

        return itemDescription
      },

      statusDialog_onClose () {
        this.displayDialog = false
        this.items2Replace = []
      },
    },
  }
</script>
