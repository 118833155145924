// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Notification, Customer, UserSettings, SystemMessages } = initSchema(schema);

export {
  Notification,
  Customer,
  UserSettings,
  SystemMessages
};