<template>
  <div class="ma-0 pa-0">
    <v-container
      v-if="!users.isGuest || notifications.systemMessages.length > 0"
      class="ma-0 pa-0"
    >
      <!-- Notifications -->
      <v-row
        v-if="notifications.systemMessages.length > 0"
        no-gutters
      >
        <v-col
          class="text-center"
          sm="12"
        >
          <v-card
            outlined
            color="transparent"
          >
            <v-alert
              v-for="(n, i) in notifications.systemMessages"
              :key="i"
              :icon="n.icon"
              :type="n.type"
            >
              {{ n.message }}
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
      <!-- Default Dashboard Image -->
      <v-row
        v-if="!displayDashboard && notifications.systemMessages.length <= 0"
        no-gutters
        class="pt-16 pb-16"
        :style="{ 'background-image': 'url(' + image + ')',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '95%',
        }"
      >
        <v-col
          class="text-center pt-16 pb-16"
          offset-sm="5"
          sm="1"
        >
          <v-img
            :src="require('@/assets/quick-quote-logo.png')"
            class="align-center mt-16 mb-16"
            width="150"
          />
        </v-col>

        <v-col
          class="text-center"
          sm="6"
        />
      </v-row>
    </v-container>
    <!-- Plans Dashboard -->
    <quick-quote-page
      v-if="displayDashboard"
      id="Dashboard"
      :optionsname="$options.name"
    >
      <template #keyselection>
        <select-customer
          v-if="getLengthOf(customers.data) > 1 && users.isAdmin"
          id="2-maintain"
          v-model="customers.selectedItem"
          :appendhint="`to view its ${displayAsPlural}`"
          :enable-all="true"
        />
      </template>

      <template #content>
        <plan-table
          v-if="users.isAdmin && dataReady"
          ref="dashboardAllPlansTable"
          :enable-all-customers="true"
        />

        <plans-dashboard-table
          v-if="users.isGuest"
          ref="dashboardPlanTable"
        />
      </template>
    </quick-quote-page>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import QuickQuotePage from '@/components/QuickQuotePage'
  import PlansDashboardTable from '@/components/dashboard/tables/PlansDashboardTable'
  import PlanTable from '@/components/maintenance/tables/PlanTable'

  export default {
    name: 'DashboardView',

    components: {
      PlanTable,
      QuickQuotePage,
      PlansDashboardTable,
    },

    data () {
      return {}
    },

    computed: {
      ...get('user', [
        'image',
        'images',
      ]),
      users: get('user/users'),
      notifications: get('notification/notifications'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      displayAsPlural: get('plan/displayAsPlural'),
      displayDashboard () {
        return this.users.isAdmin || this.users.isGuest
      },
      dataReady () {
        return this.getLengthOf(this.customers.data) > 0 && this.getLengthOf(this.plans.data) > 0
      },
    },

    created () {
      this.$store.dispatch('user/setPageName', this.$options.name)
    },

    mounted () {
      this.$store.dispatch('notification/retrieveAllSystemMessages')
    },
  }
</script>
