<template>
  <div>
    <navigation-label
      :label="($attrs.label ? $attrs.label : displayAsSingular)"
      :displayAsSingular="displayAsSingular"
      :displayAsPlural="displayAsPlural"
      :tooltip="`${displayAsSingular} Maintenance`"
      routeTo="../assemblies"
    />

    <div class="d-inline pl-4">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            color="secondary"
            v-bind="attrs"
            v-on="on"
            @click="duplicate_onClick"
          >
            <v-icon>mdi-content-duplicate</v-icon>
          </v-btn>
        </template>
        <span>Duplicate {{ displayAsSingular }}</span>
      </v-tooltip>
    </div>
    <v-container
      class="pa-0 ma-0"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          sm="12"
          md="11"
        >
          <v-combobox
            :id="`key-assembly-${$attrs.id}`"
            v-model="internalValue"
            :items="assemblies.data"
            item-text="description"
            item-value="id"
            autofocus
            outlined
            dense
            clearable
            class="ml-1 mt-0 mb-0"
            :hint="`Select an ${displayAsSingular} ${$attrs.appendhint}`"
            :loading="assemblies.isBusy"
            :rules="[appRules.validOption(internalValue, assemblies.data, `${displayAsSingular} does not exist in the list.`)]"
            @change="onChange"
          />
        </v-col>

        <v-col
          cols="12"
          sm="1"
          :class="colClass"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="disablePrevious"
                v-on="on"
                @click="previous_onClick"
              >
                <v-icon>mdi-skip-previous-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Previous {{ displayAsSingular }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="disableNext"
                v-on="on"
                @click="next_onClick"
              >
                <v-icon>mdi-skip-next-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Next {{ displayAsSingular }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
    >
      <edit-assembly
        ref="formEditAssembly"
        v-model="editedItem"
        :edited-index="editedIndex"
        :showTitle="true"
        :showActions="true"
        :duplicate-mode="true"
        @cancel="cancelEdit"
        @save="saveEdit"
      />
    </v-dialog>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import NavigationLabel from '@/components/maintenance/controls/NavigationLabel'
  import EditAssembly from '@/components/maintenance/forms/EditAssembly'

  export default {
    name: 'SelectAssembly',

    components: {
      NavigationLabel,
      EditAssembly,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        renderKey: 0,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      assemblies: get('assembly/assemblies'),
      displayAsSingular: get('assembly/displayAsSingular'),
      displayAsPlural: get('assembly/displayAsPlural'),
      colClass () {
        let newClass = 'text-no-wrap d-inline'
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm':
            newClass = 'd-none'
            break
        }
        return newClass
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disablePrevious () {
        let disabledButton = true

        if (this.assemblies.data) {
          if (this.assemblies.data.length > 0) {
            disabledButton = this.assemblies.isFirst

            if (disabledButton && this.divisions.data) {
              if (this.divisions.data.length > 0) {
                disabledButton = this.divisions.isFirst
              }
            }
          }
        }

        return disabledButton
      },
      disableNext () {
        let disabledButton = true

        if (this.assemblies.data) {
          if (this.assemblies.data.length > 0) {
            disabledButton = this.assemblies.isLast

            if (disabledButton && this.divisions.data) {
              if (this.divisions.data.length > 0) {
                disabledButton = this.divisions.isLast
              }
            }
          }
        }

        return disabledButton
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.description === oldVal.description) return

            this.$emit('input', this.internalValue)
          }
        },
      },
      value: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.description === oldVal.description) return

            this.internalValue = val
          }
        },
      },
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        val || this.cancelEdit()
      },
    },

    methods: {
      onChange (selectedItem) {
        this.$store.dispatch('assembly/setSelected', selectedItem)

        this.$emit('change', selectedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      async getUniqueId () {
        let uniqueId = parseInt(this.editedItem.assembly_id, 0)
        let originalData

        for (let step = 0; step < this.assemblies.data.length; step++) {
          uniqueId++
          originalData = await this.assemblies.data.filter(data => data.id.toString() === uniqueId.toString())

          if (originalData.length <= 0) {
            break
          }
        }

        return uniqueId
      },

      async duplicate_onClick () {
        this.editedIndex = -1
        this.editedItem = { ...this.internalValue }

        const newId = await this.getUniqueId()
        this.editedItem.id = newId
        this.editedItem.assembly_id = newId
        this.editedItem.description += ' - Copy'
        this.editedItem.selected_assembly = { ...this.internalValue }

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)

        this.$nextTick(() => {
          this.dialogEdit = true
        })
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.dialogEdit = false
      },

      previous_onClick () {
        this.$store.dispatch('assembly/previous')
      },

      next_onClick () {
        this.$store.dispatch('assembly/next')
      },
    },
  }
</script>

<style scoped>

</style>
