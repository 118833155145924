<template>
  <div>
    <v-select
      :id="`multi-select-${$attrs.id}`"
      v-model="internalValue"
      v-bind="$attrs"
      return-object
      multiple
      small-chips
      deletable-chips
      outlined
      @change="onChange"
      @click:clear="onChange"
    >
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @click="toggleSelectAll"
        >
          <v-list-item-action>
            <v-icon :color="internalValue.length > 0 ? 'secondary' : ''">
              {{ selectAllIcon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" />
      </template>
    </v-select>
  </div>
</template>

<script>
  export default {
    name: 'MultiSelect',

    props: {
      value: {
        type: Array,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      selectedAll () {
        const selectableOptions = this.getSelectableOptions()

        return this.internalValue.length === selectableOptions.length
      },
      selectedSome () {
        return this.internalValue.length > 0 && !this.selectedAll
      },
      selectAllIcon () {
        if (this.selectedAll) return 'mdi-close-box'
        if (this.selectedSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
      },
    },

    methods: {
      onChange () {
        this.$nextTick(() => {
          this.$emit('change', this.internalValue)
        })
      },

      getSelectableOptions () {
        const selectableOptions = []

        for (const thisItem of this.$attrs.items) {
          if (thisItem) {
            if ({}.hasOwnProperty.call(thisItem, 'disabled')) {
              if (!thisItem.disabled) {
                selectableOptions.push(thisItem)
              }
            } else {
              selectableOptions.push(thisItem)
            }
          }
        }

        return selectableOptions
      },

      // Toggle is fired whenever an item(s) is selected. It updates the internalValue array with the items that are checked.
      toggleSelectAll () {
        if (this.selectedAll) {
          this.internalValue = []
        } else {
          const selectableOptions = this.getSelectableOptions()
          this.internalValue = selectableOptions.slice()
        }

        this.onChange()
      },
    },
  }
</script>

<style scoped>

</style>
