<template>
  <!-- TODO: Deprecate this component or fix some of the complexities by using the forms and tables components -->
  <v-container
    :id="`${$attrs.singular.toLowerCase().replaceAll(' ', '-')}-maintenance-view`"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row>
                <v-col sm="8">
                  <h3>{{ $attrs.singular }} Maintenance</h3>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container
              class="py-0 pb-5"
            >
              <!-- Key Selection Slot -->
              <v-row
                v-if="hasKeySelection"
                align="end"
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="8"
                  md="7"
                  lg="6"
                  xl="5"
                >
                  <slot name="keyselection" />
                </v-col>
              </v-row>

              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-data-table
                    v-model="$attrs.items.selected"
                    :headers="$attrs.headers"
                    :items="$attrs.items.data"
                    :item-class="getRowClass"
                    sort-by="id"
                    class="elevation-2"
                    show-select
                    :show-expand="headersContains(this.$attrs.headers,'data-table-expand')"
                    :loading="$attrs.items.isBusy"
                    :search="search"
                    @contextmenu:row="openContextMenu"
                  >
                    <template v-slot:no-data>
                      <h2
                        class="primary--text"
                      >
                        No {{ $attrs.plural }} Available
                      </h2>
                    </template>

                    <template v-slot:no-results>
                      <h3
                        class="primary--text"
                      >
                        No matching {{ $attrs.singular }} data found containing '{{ search }}'
                      </h3>
                    </template>

                    <template v-slot:loading>
                      <h2
                        class="primary--text"
                      >
                        Loading {{ $attrs.plural }}... Please wait
                      </h2>
                    </template>

                    <template v-slot:top>
                      <v-container
                        class="pa-0 ma-0"
                      >
                        <v-row
                          no-gutters
                          class="pa-0 ma-0"
                        >
                          <v-col
                            cols="12"
                            sm="10"
                            class="pa-0 ma-0 text-center"
                          >
                            <v-text-field
                              v-if="$attrs.items.data.length > 0"
                              v-model="search"
                              prepend-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                              clearable
                              class="pl-10 pr-10"
                              @click="input_onClick"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="2"
                            class="pa-0 ma-0 text-right"
                          >
                            <v-btn
                              small
                              color="primary"
                              class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                              :disabled="disableNew"
                              @click="menuNew_onClick"
                            >
                              New {{ $attrs.singular }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>

                    <template v-slot:item.data-table-select="{ isSelected, select }">
                      <v-simple-checkbox
                        color="primary"
                        :value="isSelected"
                        @input="select($event)"
                      />
                    </template>

                    <template v-slot:item.id="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.id }}
                      </div>
                    </template>

                    <template v-slot:item.description="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.description }}
                      </div>
                    </template>

                    <template v-slot:item.unit="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.unit }}
                      </div>
                    </template>

                    <template v-slot:item.estimator_name="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.estimator_name }}
                      </div>
                    </template>

                    <template v-slot:item.salesperson_name="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.salesperson_name }}
                      </div>
                    </template>

                    <template v-slot:item.pv_id="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.pv_id }}
                      </div>
                    </template>

                    <template v-slot:item.prompt_description="{ item }">
                      <div
                        class="font-weight-medium text-body-1"
                        @dblclick="row_onDblClick(item)"
                      >
                        {{ item.prompt_description }}
                      </div>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        color="primary"
                        class="mr-2"
                        @click="openEditDialog(item)"
                      >
                        mdi-pencil
                      </v-icon>

                      <v-icon
                        small
                        color="primary"
                        @click="openDeleteDialog(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td
                        :colspan="headers.length"
                        @contextmenu:row="openContextMenu"
                      >
                        <v-container
                          class="py-0"
                        >
                          <v-row
                            no-gutters
                          >
                            <v-col
                              cols="12"
                              class="text-right pt-5 pb-5"
                            >
                              <v-data-table
                                :headers="$attrs.detailHeaders"
                                :items="[item]"
                                :hide-default-footer="true"
                                class="elevation-2"
                              >
                                <template v-slot:top>
                                  <h2
                                    class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                                  >
                                    {{ item.description }} - {{ $attrs.singular }} Details
                                  </h2>
                                </template>

                                <template v-slot:item.heated_sqft>
                                  <div
                                    class="font-weight-medium text-body-1 text-right"
                                  >
                                    {{ item.heated_sqft.toLocaleString() }}
                                  </div>
                                </template>

                                <template v-slot:item.unheated_sqft>
                                  <div
                                    class="font-weight-medium text-body-1 text-right"
                                  >
                                    {{ item.unheated_sqft.toLocaleString() }}
                                  </div>
                                </template>

                                <template v-slot:item.total_sqft>
                                  <div
                                    class="font-weight-medium text-body-1 text-right"
                                  >
                                    {{ (item.heated_sqft + item.unheated_sqft).toLocaleString() }}
                                  </div>
                                </template>

                                <template v-slot:item.address>
                                  <div
                                    class="font-weight-medium text-body-1"
                                  >
                                    {{ item.address1 }}
                                  </div>
                                  <div
                                    class="font-weight-medium text-body-1"
                                  >
                                    {{ item.address2 }}
                                  </div>
                                  <div
                                    class="font-weight-medium text-body-1"
                                  >
                                    {{ item.city }}, {{ item.state }} {{ item.zip }}
                                  </div>
                                </template>

                                <template v-slot:item.note>
                                  <div
                                    class="font-weight-medium text-body-1"
                                  >
                                    {{ item.note }}
                                  </div>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>

            <!-- Dialogs -->
            <v-dialog
              ref="editDialog"
              :key="renderKey"
              v-model="dialogEdit"
              :width="dialogWidth"
              scrollable
              @keydown.esc.prevent="cancelEdit"
              @keydown.enter.prevent="saveEdit"
            >
              <v-card>
                <v-card-title>
                  <v-container>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                      >
                        <h2
                          class="primary--text"
                        >
                          {{ formTitle }}
                        </h2>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col
                        cols="12"
                      >
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>

                <v-card-text>
                  <v-container
                    class="pl-1 pt-2 pr-1 pb-1"
                  >
                    <!-- ID -->
                    <v-row
                      v-if="headersContains(this.$attrs.headers,'id')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <maintain-id
                          ref="editedId"
                          v-model="editedItem"
                          :label="$attrs.headers[0].text"
                          :originalData="$attrs.items.originalData"
                          :autofocus="(editedIndex < 0)"
                          :disabled="(editedIndex >= 0)"
                          :required="true"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- Description -->
                    <v-row
                      v-if="headersContains(this.$attrs.headers,'description')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <maintain-description
                          ref="editedDescription"
                          v-model="editedItem"
                          :label="$attrs.headers[1].text"
                          :originalData="$attrs.items.originalData"
                          :autofocus="(editedIndex >= 0)"
                          :required="true"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- Unit -->
                    <v-row
                      v-if="headersContains(this.$attrs.headers,'unit')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <maintain-unit
                          ref="editedUnit"
                          v-model="editedItem"
                          :label="$attrs.headers[2].text"
                          :originalData="$attrs.items.originalData"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- Estimator -->
                    <v-row
                      v-if="headersContains(this.$attrs.headers,'estimator_name')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <maintain-estimator
                          :key="renderKey"
                          ref="editedEstimator"
                          v-model="editedItem"
                          :label="$attrs.headers[2].text"
                          :originalData="$attrs.items.originalData"
                          :combobox="true"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- Salesperson -->
                    <v-row
                      v-if="headersContains(this.$attrs.headers,'salesperson_name')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <maintain-salesperson
                          :key="renderKey"
                          ref="editedSalesperson"
                          v-model="editedItem"
                          :label="$attrs.headers[3].text"
                          :originalData="$attrs.items.originalData"
                          :combobox="true"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- SqFt -->
                    <v-row
                      v-if="headersContains(this.$attrs.detailHeaders,'heated_sqft')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="3"
                      >
                        <v-text-field
                          ref="editedHeatedSqFt"
                          v-model="editedItem.heated_sqft"
                          :label="$attrs.detailHeaders[0].text"
                          dense
                          clearable
                          class="font-weight-medium text-body-1 numeric-input"
                          @blur="sqft_onBlur(editedItem)"
                          @click="input_onClick"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        offset-sm="1"
                        sm="3"
                      >
                        <v-text-field
                          ref="editedUnheatedSqFt"
                          v-model="editedItem.unheated_sqft"
                          :label="$attrs.detailHeaders[1].text"
                          dense
                          clearable
                          class="font-weight-medium text-body-1 numeric-input"
                          @blur="sqft_onBlur(editedItem)"
                          @click="input_onClick"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="5"
                      >
                        <span
                          class="pl-6 pt-0 pr-0 pb-0 ma-0 text-body-1 font-weight-regular"
                        >
                          Total sqft:
                          <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
                            {{ ((editedItem.heated_sqft * 1) + (editedItem.unheated_sqft * 1)).toLocaleString() }}
                          </span>
                        </span>
                      </v-col>
                    </v-row>
                    <!-- Prompt Group -->
                    <v-row
                      v-if="headersContains(this.$attrs.headers,'prompt_description')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <maintain-prompt-group
                          :key="renderKey"
                          ref="editedPromptGroup"
                          v-model="editedItem"
                          :label="$attrs.headers[2].text"
                          :originalData="$attrs.items.originalData"
                          :combobox="true"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- Address -->
                    <v-row
                      v-if="headersContains(this.$attrs.detailHeaders,'address')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                      >
                        <maintain-address
                          ref="editedAddress"
                          v-model="editedItem"
                          :originalData="$attrs.items.originalData"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                    <!-- Note -->
                    <v-row
                      v-if="headersContains(this.$attrs.detailHeaders,'note')"
                      no-gutters
                      class="mb-3"
                    >
                      <v-col
                        cols="12"
                      >
                        <maintain-note
                          ref="editedNote"
                          v-model="editedItem"
                          label="Note"
                          :originalData="$attrs.items.originalData"
                          @change="disableEdit = getDisableEdit()"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="secondary"
                    @click="cancelEdit"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="disableEdit"
                    @click="saveEdit"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <delete-dialog
              ref="maintenanceDeleteDialog"
              :maintenance-items="$attrs.items"
              :display-as-singular="$attrs.singular"
              :display-as-plural="$attrs.plural"
              @delete="item_onDelete()"
              @cancel="cancelDelete()"
            />
          </v-form>
        </material-card>

        <!-- Menus -->
        <context-menu
          ref="maintenanceContextMenu"
          :maintenance-items="$attrs.items"
          :display-as-singular="$attrs.singular"
          :display-as-plural="$attrs.plural"
          @newitem="menuNew_onClick"
          @edititem="menuEdit_onClick"
          @deleteitem="menuDelete_onClick"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
  .rowClass {
    text-align: left;
  }
  .numeric-input >>> input {
    text-align: right;
    min-width: 150px;
  }
/*
  .tile:hover {
    background-color: var(--secondary-color);
  }
  .itemClass {
    @extend %font-weight-medium;
    @extend %text-body-1;
  }
 */
</style>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainUnit from '@/components/maintenance/controls/Unit'
  import MaintainPromptGroup from '@/components/maintenance/controls/PromptGroup'
  import MaintainEstimator from '@/components/maintenance/controls/Estimator'
  import MaintainSalesperson from '@/components/maintenance/controls/Salesperson'
  import MaintainAddress from '@/components/maintenance/controls/Address'
  import MaintainNote from '@/components/maintenance/controls/Note'

  export default {
    name: 'MaintenancePage',

    components: {
      MaintainId,
      MaintainDescription,
      MaintainUnit,
      MaintainPromptGroup,
      MaintainEstimator,
      MaintainSalesperson,
      MaintainAddress,
      MaintainNote,
    },

    data () {
      return {
        appItem: null,
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
        'getDataById',
      ]),
      appRules: get('app/rules'),
      hasKeySelection () {
        return !!(
          this.$slots.keyselection
        )
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.$attrs.singular}` : `Edit ${this.$attrs.singular}`
      },
      disableNew () {
        let disabled = true

        if (this.$attrs.selectedkey) {
          if ({}.hasOwnProperty.call(this.$attrs.selectedkey, 'id')) {
            disabled = this.$attrs.selectedkey.id.length <= 0
          }
        }

        return disabled
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.appItem = this.getAppItem(this.$attrs.optionsname)
      this.$store.dispatch('user/setPageName', this.$attrs.optionsname)
    },

    methods: {
      getRowClass () {
        return 'rowClass'
        // return 'font-weight-medium text-body-1'
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.$attrs.items.empty)
        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      sqft_onBlur (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.items.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.heated_sqft !== originalData[0].heated_sqft || dataItem.unheated_sqft !== originalData[0].unheated_sqft) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.$attrs.items.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedEstimator) {
          disabled = this.$refs.editedEstimator.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.editedSalesperson) {
          disabled = this.$refs.editedSalesperson.$refs.combobox.hasError
        }

        return disabled
      },

      headersContains (headers, containsValue) {
        if (headers) {
          for (const header of headers) {
            if (header.value === containsValue) {
              return true
            }
          }
        }

        return false
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$emit('delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      row_onDblClick (dataItem) {
        this.$emit('dblclick', dataItem)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.$attrs.items.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.$emit('update', this.editedItem)
          }
        }
      },
    },
  }
</script>
