// Pathify
import { make } from 'vuex-pathify'
import { importChunk } from '@/util/helpers'

const _ = require('lodash')

const endpoint = 'https://pjcc1m20o5.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Plan',
  displayAsPlural: 'Plans',
  plans: {
    archived: [],
    dataFrom: [],
    data: [],
    originalData: [],
    toImport: [],
    importData: [],
    isBusyFrom: false,
    isBusy: false,
    isFirst: false,
    isLast: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    selectedFrom: { id: '', description: '' },
    setLast: false,
    empty: {
      id: null,
      plan_id: null,
      client_id: null,
      job_id: null,
      description: null,
      note: null,
      salesperson_id: null,
      salesperson_name: null,
      estimator_id: null,
      estimator_name: null,
      due_date: null,
      submitted_date: null,
      received_date: null,
      start_date: null,
      finish_date: null,
      return_cd: 10,
      status_cd: null,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      project_id: '',
      folder_id: null,
      folder_description: '',
      documents_count: 0,
      price_level: 0,
      fixed_pricing: 0,
      profit: 0,
      check_usage: 0,
      cust_build_id: '',
      heated_sqft: 0,
      unheated_sqft: 0,
      total_sqft: 0,
      invsbl_coh: 0,
      invsbl_equ: 0,
      invsbl_lab: 0,
      invsbl_mat: 0,
      invsbl_oth: 0,
      invsbl_sub: 0,
      vsbl_coh: 0,
      vsbl_equ: 0,
      vsbl_lab: 0,
      vsbl_mat: 0,
      vsbl_oth: 0,
      vsbl_sub: 0,
      read_only: 0,
      update_program: null,
      update_user_id: null,
      notify: true,
    },
    headers: {
      headerCustomer: {
        text: 'Customer',
        align: 'start',
        value: 'client_id',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerId: {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerStatus: {
        text: 'Status',
        align: 'start',
        value: 'status_cd',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerReturnTime: {
        text: 'Return Time',
        align: 'start',
        value: 'return_cd',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerDueDate: {
        text: 'Due Date',
        align: 'start',
        value: 'due_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerSubmittedDate: {
        text: 'Submitted Date',
        align: 'start',
        value: 'submitted_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerReceivedDate: {
        text: 'Received Date',
        align: 'start',
        value: 'received_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerCompletedDate: {
        text: 'Completed Date',
        align: 'start',
        value: 'finish_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerEstimator: {
        text: 'Estimator',
        align: 'start',
        value: 'estimator_name',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerSalesperson: {
        text: 'Salesperson',
        align: 'start',
        value: 'salesperson_name',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerFolderId: {
        text: 'Folder ID',
        align: ' d-none',
        value: 'folder_id',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
      },
      headerFolderDescription: {
        text: 'Folder Description',
        align: ' d-none',
        value: 'folder_description',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
      },
      headerHeatedSqFt: {
        text: 'Heated',
        align: 'end',
        value: 'heated_sqft',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerUnheatedSqFt: {
        text: 'Unheated',
        align: 'end',
        value: 'unheated_sqft',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerTotalSqFt: {
        text: 'Total',
        align: 'end',
        value: 'total_sqft',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerAddress: {
        text: 'Address',
        align: 'start',
        value: 'address',
        sortable: false,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerNote: {
        text: 'Note',
        align: 'start',
        value: 'note',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
    treeBranchesSelected: [],
    assemblyTreeData: [
      {
        id: 0,
        text: 'All Selected Plans',
        checked: false,
        children: [],
        state: {
          open_icon: 'mdi-folder-open',
          close_icon: 'mdi-folder',
        },
      },
    ],
    tabbedColumns: {
      id_header: 'Plan ID',
      description_header: 'Plan Description',
    },
    StatusCode: {
      submitted: 'SUBMITTED',
      progress: 'IN PROGRESS',
      complete: 'COMPLETE',
      invoiced: 'INVOICED',
      hold: 'ON-HOLD',
      cancelled: 'CANCELLED',
    },
    ReturnCode: {
      10: 'STANDARD',
      5: 'RUSH',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusyFrom: (state, isBusy) => {
    state.plans.isBusyFrom = isBusy
  },

  setIsBusy: (state, isBusy) => {
    state.plans.isBusy = isBusy
  },

  setEmptyFrom: (state) => {
    state.plans.selectedFrom = { id: '', description: '' }
    state.plans.dataFrom = []
  },

  // TODO: All modules should do this rather than setting selected in the action
  setSelected: (state, payload) => {
    state.plans.selected = []
    state.plans.selectedItem = { ...payload }
  },

  setSelectedCustomerPlan: (state, payload) => {},

  setIsFirst: (state) => {
    let isFirst = false
    const selectedObject = state.plans.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.plans.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[0], 'id')) {
          const firstId = thisData[0].id

          if (firstId === selectedId) {
            isFirst = true
          }
        }
      } else {
        isFirst = true
      }
    } else {
      isFirst = true
    }

    state.plans.isFirst = isFirst
  },

  setIsLast: (state) => {
    let isLast = false
    const selectedObject = state.plans.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.plans.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[thisData.length - 1], 'id')) {
          const lastId = thisData[thisData.length - 1].id

          if (lastId === selectedId) {
            isLast = true
          }
        }
      } else {
        isLast = true
      }
    } else {
      isLast = true
    }

    state.plans.isLast = isLast
  },

  push2Data: (state, payload) => {
    state.plans.data = []
    state.plans.originalData = []

    for (const currentData of payload.dataFromDB) {
      currentData.notify = payload.users.isCustomer
      state.plans.data.push({ ...currentData })
      state.plans.originalData.push({ ...currentData })
    }

    state.plans.selected = []

    state.plans.isBusy = false
  },

  push2From: (state, dataFromDB) => {
    state.plans.dataFrom = []
    state.plans.dataFrom = [...dataFromDB]

    state.plans.isBusyFrom = false
  },

  push2Import: (state, data2Import) => {
    state.plans.toImport = []
    state.plans.toImport = data2Import
    state.plans.isBusy = false
  },

  initializeAssemblyTreeData: (state, dataFromDB) => {
    state.plans.assemblyTreeData[0].children = []

    let holdPlanId = ''
    let planTree = {}
    let holdDivisionId = ''
    let divisionTree = {}
    let assemblyTree = {}

    const defaultState = {
      open_icon: 'mdi-folder-open',
      close_icon: 'mdi-folder',
    }

    for (const thisItem of dataFromDB) {
      if (thisItem.plan_id && holdPlanId !== thisItem.plan_id) {
        holdPlanId = thisItem.plan_id
        planTree = {
          id: holdPlanId,
          plan_id: holdPlanId,
          text: thisItem.plan_description,
          checked: true,
          state: { ...defaultState },
          children: [],
        }
        planTree.state.open_icon = 'mdi-home-minus'
        planTree.state.close_icon = 'mdi-home-plus'

        state.plans.assemblyTreeData[0].children.push(planTree)
      }

      if (thisItem.phase_id && holdDivisionId !== thisItem.phase_id) {
        holdDivisionId = thisItem.phase_id
        divisionTree = {
          id: `${holdPlanId}_${holdDivisionId}`,
          plan_id: holdPlanId,
          phase_id: holdDivisionId,
          text: thisItem.phase_description,
          checked: true,
          state: { ...defaultState },
          children: [],
        }
        // mdi-layers-plus
        divisionTree.state.open_icon = 'mdi-tray-minus'
        divisionTree.state.close_icon = 'mdi-tray-plus'

        planTree.children.push(divisionTree)
      }

      if (thisItem.assembly_id) {
        assemblyTree = {
          id: `${holdPlanId}_${holdDivisionId}_${thisItem.assembly_id}`,
          plan_id: holdPlanId,
          phase_id: holdDivisionId,
          assembly_id: thisItem.assembly_id,
          text: thisItem.assembly_description,
          checked: true,
          state: { ...defaultState },
        }
        assemblyTree.state.open_icon = 'mdi-toy-brick'
        assemblyTree.state.close_icon = 'mdi-toy-brick'

        divisionTree.children.push({ ...assemblyTree })
      }
    }

    state.plans.isBusy = false
  },

  successfulUpdate: (state) => {},

  importCompleted: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmptyFrom: ({ commit }) => {
    commit('setEmptyFrom')
  },

  setEmpty: ({ commit, dispatch }) => {
    state.plans.selected = []
    state.plans.selectedItem = { id: '', description: '' }
    state.plans.data = []
    dispatch('division/setEmpty', null, { root: true })
    commit('setIsFirst')
    commit('setIsLast')
  },

  setDefaultFrom: ({ rootState }) => {
    if (state.plans.dataFrom.length > 0) {
      const selectedParent = rootState.customer.customers.selectedFrom
      const selectedItem = state.plans.selectedFrom

      // If we have a selected parent object and selectedItem is empty, select the first data item
      if (selectedParent && {}.hasOwnProperty.call(selectedParent, 'id') && selectedItem && {}.hasOwnProperty.call(selectedItem, 'id')) {
        if (selectedParent.id.trim().length > 0 && selectedItem.id.trim().length <= 0) {
          state.plans.selectedFrom = { ...state.plans.dataFrom[0] }
        }
      }
    }
  },

  setFrom: ({ commit, dispatch, rootState }, payload) => {
    if (payload) {
      state.plans.selectedFrom = { ...payload }
    } else {
      state.plans.selectedFrom = { id: '', description: '' }
    }
  },

  setSelected: ({ commit, dispatch }, payload) => {
    dispatch('division/setEmpty', null, { root: true })

    if (payload) {
      commit('setSelected', payload)
      dispatch('division/retrieve', payload, { root: true })
    } else {
      state.plans.selectedItem = { id: '', description: '' }
    }
    commit('setIsFirst')
    commit('setIsLast')
  },

  setSelectedCustomerPlan: async ({ commit, dispatch, rootGetters, rootState }, payload) => {
    const thisAction = `Set Selected ${rootState.customer.displayAsSingular} ${state.displayAsSingular}`
    const selectedCustomer = rootState.customer.customers.selectedItem
    const selectedPlan = state.plans.selectedItem

    if (selectedCustomer !== null) {
      if ({}.hasOwnProperty.call(selectedCustomer, 'client_id')) {
        if (selectedCustomer.client_id.toString().trim() !== payload.client_id.toString().trim()) {
          const setCustomer = await rootGetters['app/getDataById'](rootState.customer.customers.data, payload.client_id)

          if (setCustomer) {
            if (setCustomer.length > 0) {
              await dispatch('customer/setSelected', setCustomer[0], { root: true })
            }
          }
        }
      }
    }

    if (selectedPlan !== null) {
      if ({}.hasOwnProperty.call(selectedPlan, 'plan_id')) {
        const selectedPlanId = selectedPlan.plan_id.toString().trim()
        if (selectedPlanId.length > 0 && selectedPlanId !== payload.plan_id.toString().trim()) {
          const setPlan = await rootGetters['app/getDataById'](state.plans.data, payload.plan_id)
          if (setPlan) {
            if (setPlan.length > 0) {
              await dispatch('setSelected', setPlan[0])
            }
          }
        }
      }
    }

    commit('setSelectedCustomerPlan', payload)
  },

  previous: ({ dispatch, rootState }) => {
    let previousObject = null
    const selectedId = state.plans.selectedItem.id

    for (const existingObject of state.plans.data) {
      if (existingObject.id === selectedId) {
        break
      }

      previousObject = { ...existingObject }
    }

    if (previousObject) {
      rootState.assembly.assemblies.setLast = true
      rootState.division.divisions.setLast = true
      dispatch('setSelected', previousObject)
      // dispatch setSelected last division last assembly
    } else {
      dispatch('customer/previous', null, { root: true })
    }
  },

  next: ({ dispatch }) => {
    let nextObject = null
    const selectedId = state.plans.selectedItem.id

    for (let thisIndex = state.plans.data.length - 1; thisIndex >= 0; thisIndex--) {
      const existingObject = state.plans.data[thisIndex]

      if (existingObject.id === selectedId) {
        break
      }

      nextObject = { ...existingObject }
    }

    if (nextObject) {
      dispatch('setSelected', nextObject)
    }
  },

  determineSelected: ({ dispatch, rootState }, dataFromDB) => {
    const thisAction = `Determine Selected ${state.displayAsSingular}`

    // Previous/Next logic
    if (dataFromDB.length > 0) {
      if (state.plans.setLast) {
        state.plans.setLast = false
        dispatch('setSelected', dataFromDB[dataFromDB.length - 1])
      } else {
        const selectedParent = rootState.customer.customers.selectedItem
        const selectedItem = state.plans.selectedItem

        // If we have a selected parent object and selectedItem is empty, select the first data item
        if (selectedParent && {}.hasOwnProperty.call(selectedParent, 'id') && selectedItem && {}.hasOwnProperty.call(selectedItem, 'id')) {
          if (selectedParent.id.trim().length > 0 && selectedItem.id.trim().length <= 0) {
            dispatch('setSelected', dataFromDB[0])
          }
        }
      }
    }
  },

  retrieveAll: ({ commit, dispatch, rootGetters, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveAllPlans'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          let data2Push

          if (_.isEmpty(jsonResponse.error)) {
            data2Push = rootGetters['user/getEstimatorsData'](jsonResponse.data, rootState.user.users.default.estimator_id)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }

          return data2Push
        })
        .then(data2Push => {
          commit('push2Data', { users: rootState.user.users, dataFromDB: data2Push })

          return data2Push
        })
        .then(async dataFromDB => {
          dispatch('assembly/retrieveAssembliesByPlans', null, { root: true })
          dispatch('division/retrieveAll', null, { root: true })

          dispatch('determineSelected', dataFromDB)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieveFrom: ({ commit, dispatch, rootGetters, rootState }) => {
    const selectedFromCustomer = rootState.customer.customers.selectedFrom
    const selectedItemCustomer = rootState.customer.customers.selectedItem

    if (selectedFromCustomer && selectedItemCustomer) {
      if ({}.hasOwnProperty.call(selectedFromCustomer, 'client_id') &&
        {}.hasOwnProperty.call(selectedItemCustomer, 'client_id')) {
        const displayAction = 'Retrieve From Plans'
        const thisAction = 'retrieveAllPlans'
        commit('setIsBusyFrom', true)

        if (selectedFromCustomer.client_id === selectedItemCustomer.client_id) {
          commit('push2From', state.plans.data)
          dispatch('setDefaultFrom')
        } else {
          const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
          const url = `${actionEndPoint}/${rootState.customer.customers.selectedFrom.client_id}`

          const options = {
            method: 'get',
          }

          fetch(url, options)
            .then(response => {
              const statusMessage = `${response.status}: "${response.statusText}"`

              if (!response.ok) {
                throw Error(statusMessage)
              }

              return response.json()
            })
            .then(jsonResponse => {
              let data2Push

              if (_.isEmpty(jsonResponse.error)) {
                data2Push = rootGetters['user/getEstimatorsData'](jsonResponse.data, rootState.user.users.default.estimator_id)
              } else {
                // toastColor = 'danger'
                // toastMessage = 'was not updated successfully'
                console.error(`${displayAction} failed with url: ${url}`)
                dispatch('error/setError', { name: displayAction, details: jsonResponse.error }, { root: true })
                commit('setIsBusyFrom', false)
              }

              return data2Push
            })
            .then(data2Push => {
              commit('push2From', data2Push)
            })
            .then(() => {
              dispatch('setDefaultFrom')
            })
            .catch(error => {
              console.error(`${displayAction} failed with url: ${url}`)
              commit('setIsBusyFrom', false)
              dispatch('error/setError', { name: displayAction, details: error }, { root: true })
            })
        }
      }
    }
  },

  retrieveAssemblyTree: ({ commit, dispatch, rootState }) => {
    const thisAction = 'retrievePlansAssemblyTree'
    commit(setIsBusy, true)

    const clientId = rootState.customer.customers.selectedItem.client_id
    let errMsg = ''

    if (clientId.length <= 0) {
      errMsg += 'Failed with an empty client id'
    }

    if (state.plans.selected.length <= 0) {
      if (errMsg.length > 0) {
        errMsg += ' ,'
      }
      errMsg += 'Failed due to no plans selected'
    }

    if (errMsg.length > 0) {
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: errMsg }, { root: true })
    } else {
      let selectedPlans = ''

      for (const currentData of state.plans.selected) {
        if (selectedPlans.length > 0) {
          selectedPlans += ','
        }
        selectedPlans += currentData.plan_id
      }

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${clientId}/${selectedPlans}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('initializeAssemblyTreeData', jsonResponse.data)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          commit(setIsBusy, false)
        })
    }
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updatePlans'
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    // Copy payload to workPayload so that we can adjust formatting for MySQL
    const workPayload = { ...payload }

    if (workPayload.plan_id === null) {
      if (workPayload.id != null) {
        workPayload.plan_id = workPayload.id
      }
    } else {
      if (workPayload.plan_id !== workPayload.id) {
        workPayload.plan_id = workPayload.id
      }
    }

    // Ensure dates are formatted properly
    if (workPayload.submitted_date !== null) {
      workPayload.submitted_date = new Date(workPayload.submitted_date)
    }

    if (workPayload.due_date !== null) {
      workPayload.due_date = new Date(workPayload.due_date)
    }

    if (workPayload.received_date !== null) {
      workPayload.received_date = new Date(workPayload.received_date)
    }

    if (workPayload.finish_date !== null) {
      workPayload.finish_date = new Date(workPayload.finish_date)
    }

    const options = {
      method: 'post',
      body: JSON.stringify(workPayload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.plans.selectedItem && {}.hasOwnProperty.call(state.plans.selectedItem, 'id')) {
            if (workPayload.plan_id === state.plans.selectedItem.id) {
              dispatch('setSelected', workPayload)
            }

            if (workPayload.plan_id === state.plans.selectedFrom.id) {
              dispatch('setFrom', workPayload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${workPayload.description}' ${toastMessage}, variant: ${toastColor}`)
        dispatch('retrieveFrom')
        commit('successfulUpdate')
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${workPayload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
        dispatch('notification/createSubmitMessage', workPayload, { root: true })
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.plans.selected.length > 0) {
      const thisAction = 'deletePlans'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.plans.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.plans.selected.length > 1) {
        toastPrefix = `${state.plans.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.plans.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.plans.selectedItem && {}.hasOwnProperty.call(state.plans.selectedItem, 'id')) {
              for (const currentData of state.plans.selected) {
                if (currentData.plan_id === state.plans.selectedItem.id) {
                  dispatch('setSelected', state.plans.data[0])
                }

                if (currentData.plan_id === state.plans.selectedFrom.id) {
                  dispatch('setFrom', state.plans.dataFrom[0])
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
          */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  importAll: ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'updatePlans'

    if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const emptyItem = { ...state.plans.empty }
      dispatch('user/setStateAsUpdated', emptyItem, { root: true })

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(payload.length / 50)
      const maxSize = Math.ceil(payload.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Import = []
      let totalAttempted = 0
      let items2Import = []
      let item2Import = {}

      for (const currentItem of payload) {
        // Ensure we have all required data and the proper selected client
        item2Import = { ...emptyItem }

        item2Import.id = currentItem.id
        item2Import.plan_id = currentItem.id
        item2Import.description = currentItem.description

        items2Import.push(item2Import)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))

          totalAttempted += chunkSize
          chunkSize = 0
          items2Import = []
        }
      }

      if (chunkSize > 0) {
        chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))
        totalAttempted += chunkSize
      }

      let toastColor = 'success'
      let toastMessage = 'imported successfully'

      if (chunks2Import.length > 0) {
        // Once all promises are resolved we have a successful import, or display rejected error
        Promise.all(chunks2Import)
          .then(responses => {
            dispatch('importData/setImportCount', { sheetName: state.displayAsPlural, importCount: totalAttempted }, { root: true })
            dispatch('importData/setSelectedIsComplete', state.displayAsPlural, { root: true })
            commit('importCompleted')
            dispatch('retrieveAll')

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)
              } else {
                toastColor = 'danger'
                toastMessage = 'were not imported successfully'
                console.error(`${thisAction} failed with url: ${url}`)
                commit(setIsBusy, false)
                dispatch('importData/setIsBusy', false, { root: true })
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }
          })
          .catch(error => {
            console.error(`${thisAction} failed for ${state.displayAsSingular}`)
            commit(setIsBusy, false)
            dispatch('importData/setIsBusy', false, { root: true })
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        console.error(`${thisAction} failed for ${state.displayAsSingular} due to empty chunks`)
        commit(setIsBusy, false)
        dispatch('importData/setIsBusy', false, { root: true })

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `Import ${state.displayAsPlural}`,
            messages: [`No ${state.displayAsPlural.toLowerCase()} were imported`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } // client_id & payload length > 0
  },

  push2Import: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Push ${state.displayAsPlural} to Import`

    if (payload && {}.hasOwnProperty.call(payload, 'sheetData')) {
      if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.sheetData.length > 0) {
        commit(setIsBusy, true)

        const emptyItem = { id: null, plan_id: null, description: null, note: '' }
        const items2Import = []
        let item2Import = {}

        for (const currentItem of payload.sheetData) {
          item2Import = { ...emptyItem }

          item2Import.id = currentItem[state.plans.tabbedColumns.id_header]
          item2Import.plan_id = currentItem[state.plans.tabbedColumns.id_header]
          item2Import.description = currentItem[state.plans.tabbedColumns.description_header]

          items2Import.push(item2Import)
        }

        dispatch('importData/setRows', { sheetName: state.displayAsPlural, sheetRows: items2Import.length }, { root: true })
        commit('push2Import', items2Import)
      }
    }
  },

  executeImport: ({ dispatch }) => {
    dispatch('importAll', state.plans.toImport)
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
