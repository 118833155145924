<template>
  <div>
    <maintenance-page
      :optionsname="$options.name"
      :singular="displayAsSingular"
      :plural="displayAsPlural"
      :headers="headers"
      :items="maintenanceState"
      :selectedkey="customers.selectedItem"
      @update="onUpdate"
      @delete="onDelete"
      @dblclick="onDblClick"
    >
      <template #keyselection>
        <select-customer
          v-if="customers.data.length > 1"
          id="2-maintain"
          v-model="customers.selectedItem"
          :appendhint="`to view its ${displayAsPlural}`"
        />
      </template>
    </maintenance-page>

    <div style="display: none;">
      <v-list-item
        id="navToChildMaintenance"
        to="../parts"
      />
    </div>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CategoriesView',

    data () {
      return {
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      users: get('user/users'),
      customers: get('customer/customers'),
      displayAsSingular: get('category/displayAsSingular'),
      displayAsPlural: get('category/displayAsPlural'),
      maintenanceState: get('category/categories'),
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerId.width = '10%'
        this.maintenanceState.headers.headerDescription.width = '89%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerId)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        this.$store.dispatch('category/retrieveAll')

        this.setupHeaders()
      },

      onUpdate (dataItem) {
        this.$store.dispatch('category/update', dataItem)
      },

      onDelete () {
        this.$store.dispatch('category/delete')
      },

      onDblClick (dataItem) {
        this.$store.dispatch('category/setSelected', dataItem)

        document.getElementById('navToChildMaintenance').click()
      },
    },
  }
</script>
