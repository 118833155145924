// Pathify
import { make } from 'vuex-pathify'
import { API, DataStore } from 'aws-amplify'
import { Notification, SystemMessages } from '@/models'
import moment from 'moment'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Notification',
  displayAsPlural: 'Notifications',
  config: {
    stageEmail: 'simlow2018@gmail.com',
    prodEmail: 'estimating@detecsolutionsllc.com',
    submitToEmail: 'estimating@detecsolutionsllc.com',
  },
  notifications: {
    systemMessages: [],
    data: [],
    isBusy: false,
    lastReload: {
      datetime: null,
      thresholdSeconds: 2,
    },
    empty: {
      id: null,
      email: null,
      type: null,
      icon: null,
      message: null,
      client_id: null,
      plan_id: null,
      route: null,
      read_by: false,
      details: {
        environment: 'Stage',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.notifications.isBusy = isBusy
  },

  push2Data: (state, dataFromDB) => {
    state.notifications.data = []

    for (const currentData of dataFromDB) {
      state.notifications.data.push({ ...currentData })
    }

    state.notifications.isBusy = false
  },

  push2SystemMessages: (state, dataFromDB) => {
    state.notifications.systemMessages = []

    for (const currentData of dataFromDB) {
      const messageStart = moment(currentData.start)
      const messageEnd = moment(currentData.end)

      if (messageStart !== null && messageEnd !== null) {
        if (moment().isAfter(messageStart) && moment().isBefore(messageEnd)) {
          state.notifications.systemMessages.push({ ...currentData })
        }
      }
    }

    state.notifications.lastReload.datetime = moment()

    state.notifications.isBusy = false
  },

  successfulUpdate: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    // create at 5:15
  },

  create: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`

    try {
      payload.details.environment = rootState.app.build_environment

      await DataStore.save(
        new Notification({
          email: payload.email,
          type: payload.type,
          icon: payload.icon,
          message: payload.message,
          client_id: payload.client_id,
          plan_id: payload.plan_id,
          route: payload.route,
          read_by: [],
          details: JSON.stringify(payload.details),
        }),
      )

      console.info(`${thisAction} successful, variant: success`)
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  createSubmitMessage: ({ dispatch, rootState }, payload) => {
    if (payload) {
      if ({}.hasOwnProperty.call(payload, 'notify')) {
        if (rootState.user.users.isCustomer && payload.notify) {
          const newNotification = { ...state.notifications.empty }

          if (rootState.app.build_environment.toString() === 'Prod') {
            newNotification.email = state.config.prodEmail
          } else {
            newNotification.email = state.config.stageEmail
          }

          newNotification.icon = 'mdi-floor-plan'
          newNotification.message = `New Plan Submitted from ${rootState.customer.customers.selectedItem.description}`
          newNotification.client_id = payload.client_id
          newNotification.plan_id = payload.plan_id
          newNotification.route = '/components/plans/'
          newNotification.details.environment = rootState.app.build_environment

          dispatch('create', newNotification)
        }
      }
    }
  },

  retrieveAll: async ({ commit, rootState }) => {
    const thisAction = `Retrieve All ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const dataFromDB = await DataStore.query(Notification)
    const filteredData = []
    const buildEnvironment = rootState.app.build_environment
    let userEmail = null

    if (typeof rootState.user.users.user !== 'undefined') {
      if (rootState.user.users.user.attributes !== null) {
        if ({}.hasOwnProperty.call(rootState.user.users.user.attributes, 'email')) {
          userEmail = rootState.user.users.user.attributes.email.toString().trim()
        }
      }
    }

    let newData

    if (dataFromDB !== null) {
      for (const currentData of dataFromDB) {
        if (userEmail !== null) {
          if (userEmail === currentData.email.toString().trim() && {}.hasOwnProperty.call(currentData, 'details')) {
            if (currentData.details) {
              if ({}.hasOwnProperty.call(currentData.details, 'environment')) {
                if (currentData.details.environment.toString() === buildEnvironment.toString()) {
                  newData = { ...state.notifications.empty }
                  newData.id = currentData.id
                  newData.email = currentData.email
                  newData.type = currentData.type
                  newData.icon = currentData.icon
                  newData.message = currentData.message
                  newData.client_id = currentData.client_id
                  newData.plan_id = currentData.plan_id
                  newData.route = currentData.route
                  newData.read_by = currentData.read_by
                  newData.details = JSON.parse(currentData.details)

                  filteredData.push({ ...newData })
                }
              }
            }
          }
        }
      }
    }

    commit('push2Data', filteredData)
  },

  retrieveAllSystemMessages: async ({ commit }) => {
    const thisAction = 'Retrieve All System Messages'
    commit(setIsBusy, true)

    const dataFromDB = await DataStore.query(SystemMessages)

    commit('push2SystemMessages', dataFromDB)
  },

  update: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`

    try {
      const original = await DataStore.query(Notification, payload.id)
      const readBy = [...original.read_by]

      if (!readBy.includes(rootState.user.users.user.attributes.email)) {
        readBy.push(rootState.user.users.user.attributes.email)
      }

      await DataStore.save(
        Notification.copyOf(original, updated => {
          updated.read_by = readBy
        }),
      )

      commit('successfulUpdate')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
