<template>
  <quick-quote-page
    id="Folders"
    :optionsname="$options.name"
  >
    <template #keyselection>
      <select-customer
        v-if="customers.data.length > 1"
        id="2-maintain"
        v-model="customers.selectedItem"
        :appendhint="`to view its ${displayAsPlural}`"
      />
    </template>

    <template #keyactions>
      <v-col
        cols="12"
        sm="7"
      >
        <v-btn
          small
          color="secondary"
          class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
          :disabled="maintenanceState.selected.length<=0"
          @click="menuDelete_onClick"
        >
          Delete Selected {{ displayAsSingular }}(s)
        </v-btn>
        <v-btn
          small
          color="primary"
          class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
          @click="menuNew_onClick"
        >
          New {{ displayAsSingular }}
        </v-btn>
      </v-col>
    </template>

    <template #content>
      <v-overlay :value="maintenanceState.isBusy">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        />
      </v-overlay>
      <!-- Search -->
      <v-row
        v-if="getLengthOf(maintenanceState.data) > 0"
        no-gutters
        class="pa-0 ma-0"
      >
        <v-col
          cols="12"
          sm="7"
          class="pa-0 ma-0 text-center"
        >
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            class="pl-10 pr-10"
            @click="input_onClick"
          />
        </v-col>
      </v-row>
      <!-- Folder Tree -->
      <v-row
        no-gutters
        class="pa-0 ma-0"
      >
        <v-col
          cols="12"
          sm="7"
        >
          <h2
            v-if="getLengthOf(maintenanceState.data) <= 0"
            class="primary--text pt-5 pl-5"
          >
            No {{ displayAsPlural }} Available
          </h2>
          <v-treeview
            v-else
            v-model="maintenanceState.selected"
            return-object
            selectable
            selected-color="primary"
            selection-type="independent"
            item-disabled="locked"
            :items="maintenanceState.data"
            item-text="description"
            :search="search"
            shaped
            hoverable
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon
                color="secondary"
              >
                {{ displayIcon(item, open) }}
              </v-icon>
            </template>

            <template v-slot:label="{ item }">
              <div
                v-if="item.plan_id"
                class="d-inline"
                @dblclick="row_onDblClick(item)"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.description }}
                    </span>
                  </template>
                  <span>Double Click to Takeoff with {{ item.description }}</span>
                </v-tooltip>
              </div>
              <div
                v-else
                class="d-inline"
                @contextmenu="openContextMenu($event, item)"
              >
                {{ item.description }}
              </div>
              <div
                v-if="getLengthOf(item.note) > 0"
                class="d-inline pl-4 pt-0 pr-0 pb-0 ma-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="white--text"
                      color="primary"
                      x-small
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-notebook-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.note }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-treeview>
        </v-col>
      </v-row>
      <!-- Dialogs -->
      <v-dialog
        ref="editDialog"
        :key="renderKey"
        v-model="dialogEdit"
        :width="dialogWidth"
        scrollable
        @keydown.esc.prevent="cancelEdit"
        @keydown.enter.prevent="saveEdit"
      >
        <v-card>
          <v-card-title>
            <v-container>
              <v-row no-gutters>
                <v-col
                  cols="12"
                >
                  <h2
                    class="primary--text"
                  >
                    {{ formTitle }}
                  </h2>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  cols="12"
                >
                  <v-divider />
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-text>
            <v-container
              class="pl-1 pt-2 pr-1 pb-1"
            >
              <!-- Estimator -->
              <v-row
                no-gutters
                class="mb-3"
              >
                <v-col
                  cols="12"
                  sm="6"
                >
                  <maintain-estimator
                    :key="renderKey"
                    ref="editedEstimator"
                    v-model="editedItem"
                    label="Estimator"
                    :originalData="maintenanceState.originalData"
                    :combobox="true"
                    :autofocus="true"
                    @change="estimator_onChange"
                  />
                </v-col>
              </v-row>
              <!-- Id -->
              <v-row
                no-gutters
                class="mb-3"
              >
                <v-col
                  cols="12"
                  sm="4"
                >
                  <maintain-id
                    ref="editedId"
                    v-model="editedItem"
                    label="ID"
                    :originalData="maintenanceState.originalData"
                    :disabled="(editedIndex >= 0)"
                    :required="true"
                    maxlength="20"
                    @change="disableEdit = getDisableEdit()"
                  />
                </v-col>
              </v-row>
              <!-- Description -->
              <v-row
                no-gutters
                class="mb-3"
              >
                <v-col
                  cols="12"
                  sm="12"
                >
                  <maintain-description
                    ref="editedDescription"
                    v-model="editedItem"
                    label="Description"
                    :originalData="maintenanceState.originalData"
                    :required="true"
                    @change="disableEdit = getDisableEdit()"
                  />
                </v-col>
              </v-row>
              <!-- Note -->
              <v-row
                no-gutters
                class="mb-3"
              >
                <v-col
                  cols="12"
                >
                  <maintain-note
                    ref="editedNote"
                    v-model="editedItem"
                    label="Note"
                    :originalData="maintenanceState.originalData"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              @click="cancelEdit"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="disableEdit"
              @click="saveEdit"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <delete-dialog
        ref="maintenanceDeleteDialog"
        :maintenance-items="maintenanceState"
        :display-as-singular="displayAsSingular"
        :display-as-plural="displayAsPlural"
        @delete="item_onDelete()"
        @cancel="cancelDelete()"
      />

      <!-- Menus -->
      <context-menu
        ref="maintenanceContextMenu"
        :maintenance-items="maintenanceState"
        :display-as-singular="displayAsSingular"
        :display-as-plural="displayAsPlural"
        @newitem="menuNew_onClick"
        @edititem="menuEdit_onClick"
        @deleteitem="menuDelete_onClick"
      />

      <div style="display: none;">
        <v-list-item
          id="navToChildMaintenance"
          to="../takeoff"
        />
      </div>
    </template>
  </quick-quote-page>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import QuickQuotePage from '@/components/QuickQuotePage'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainEstimator from '@/components/maintenance/controls/Estimator'
  import MaintainNote from '@/components/maintenance/controls/Note'

  export default {
    name: 'FoldersView',

    components: {
      QuickQuotePage,
      DeleteDialog,
      ContextMenu,
      SelectCustomer,
      MaintainId,
      MaintainDescription,
      MaintainEstimator,
      MaintainNote,
    },

    data () {
      return {
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        /*
        items: [
          {
            id: 1,
            description: 'Folder 1',
            children: [
              { id: 2, description: 'Folder 1A' },
              { id: 3, description: 'Folder 1B' },
              { id: 4, description: 'Folder 1C' },
            ],
          },
          {
            id: 5,
            description: 'Folder 2',
            children: [
              {
                id: 6,
                description: 'Folder 2A',
                children: [
                  {
                    id: 7,
                    description: 'Folder 2A-1',
                    children: [
                      { id: 8, description: 'Folder 2A-1a' },
                      { id: 9, description: 'Folder 2A-1b' },
                    ],
                  },
                ],
              },
              {
                id: 10,
                description: 'Folder 2B',
                children: [
                  {
                    id: 11,
                    description: 'Folder 2B-1',
                    children: [
                      { id: 12, description: 'Folder 2B-1a' },
                      { id: 13, description: 'Folder 2B-1b' },
                      { id: 14, description: 'Folder 2B-1c' },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 15,
            description: 'Folder 3',
            children: [
              { id: 16, description: 'Folder 3A' },
              { id: 17, description: 'Folder 3B' },
              { id: 18, description: 'Folder 3C' },
            ],
          },
          {
            id: 19,
            description: 'Folder 4',
            children: [
              {
                id: 20,
                description: 'Folder 4A',
                children: [
                  { id: 21, description: 'Folder 4A-1' },
                  { id: 22, description: 'Folder 4A-2' },
                  { id: 23, description: 'Folder 4A-3' },
                ],
              },
              { id: 24, description: 'Folder 4B' },
              { id: 25, description: 'Folder 4C' },
            ],
          },
        ],
         */
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      ...mapGetters('folder', [
        'createFolderId',
        'createFolderDescription',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      estimators: get('estimator/estimators'),
      displayAsSingular: get('folder/displayAsSingular'),
      displayAsPlural: get('folder/displayAsPlural'),
      maintenanceState: get('folder/folders'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'folder/push2Data':
            this.$store.dispatch('folder/initializeTreeData')
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      initialize () {
        this.$store.dispatch('folder/retrieveAll')
      },

      displayIcon (item, open) {
        if (!item.file) {
          if (item.plan_id) {
            return 'mdi-floor-plan'
          }

          return open ? 'mdi-folder-open' : 'mdi-folder'
        }

        return 'mdi-folder'
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = { ...this.maintenanceState.empty }

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedEstimator) {
          disabled = this.$refs.editedEstimator.$refs.combobox.hasError
        }

        return disabled
      },

      async createEstimatorsFolder () {
        const folderId = await this.createFolderId(this.estimators.selectedItem.estimator_id)
        const selectedItems = await this.getDataById(this.maintenanceState.data, folderId)

        if (selectedItems.length > 0) {
          // TODO: Increment ID & Desc
          // this.setSelected(selectedItems[0])
          // this.internalValue.folder_id = folderId
        } else {
          this.editedItem.id = folderId
          this.editedItem.folder_id = folderId
          this.editedItem.description = await this.createFolderDescription(this.estimators.selectedItem.name)
          this.disableEdit = false
        }
      },

      estimator_onChange () {
        if (this.$refs.editedEstimator) {
          if (this.$refs.editedEstimator.$refs.combobox.hasError) {
            this.disableEdit = true
            this.editedItem.id = ''
            this.editedItem.folder_id = ''
            this.editedItem.description = ''
          } else {
            this.createEstimatorsFolder()
          }
        }
      },

      item_onDelete () {
        this.$store.dispatch('folder/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedItem) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedItem)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(this.maintenanceState.selected.length > 1)
        if (this.maintenanceState.selected.length > 1) {
          this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
        } else {
          this.$refs.maintenanceDeleteDialog.openDeleteDialog(this.maintenanceState.selected[0])
        }
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = { ...this.maintenanceState.empty }
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          if (this.editedItem.updated) {
            this.$store.dispatch('folder/update', this.editedItem)
          }
        }
      },

      row_onDblClick (dataItem) {
        this.$store.dispatch('plan/setSelected', dataItem)

        this.$nextTick(() => {
          document.getElementById('navToChildMaintenance').click()
        })
      },
    },
  }
</script>
