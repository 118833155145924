<template>
  <div>
    <v-combobox
      v-if="$attrs.combobox"
      ref="combobox"
      v-model="maintenanceState.selectedItem"
      :label="$attrs.label"
      :items="maintenanceState.data"
      item-text="description"
      item-value="id"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :loading="maintenanceState.isBusy"
      :rules="[appRules.validOption(maintenanceState.selectedItem, maintenanceState.data, `${displayAsSingular} does not exist in the list.`)]"
      @change="onChange"
      @click:clear="onClear"
    />
    <v-text-field
      v-else
      ref="textField"
      v-model="internalValue.pv_id"
      :autofocus="$attrs.autofocus"
      :disabled="$attrs.disabled"
      :required="$attrs.required"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :rules="prepRules()"
      @blur="onBlur(internalValue)"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PromptGroup',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      displayAsSingular: get('promptgroup/displayAsSingular'),
      maintenanceState: get('promptgroup/promptGroups'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.pv_id === oldVal.pv_id) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.pv_id === oldVal.pv_id) return

          this.internalValue = val
          this.load()
        },
        deep: true,
      },
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        const selectedItem = this.getDataById(this.maintenanceState.data, this.internalValue.pv_id)
        if (selectedItem && selectedItem.length > 0) {
          this.maintenanceState.selectedItem = selectedItem[0]
        } else {
          this.maintenanceState.selectedItem = null
        }
      },

      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.pv_id !== originalData[0].pv_id) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
        this.$nextTick(() => {
          this.emitChange(dataItem)
        })
      },

      onChange (dataItem) {
        if (dataItem) {
          this.internalValue.pv_id = dataItem.pv_id
          this.prepareToUpdate(this.internalValue)
        } else {
          this.internalValue.pv_id = ''
          this.emitChange(dataItem)
        }
      },

      onClear () {
        this.internalValue.pv_id = null
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)
      },

      prepRules () {
        if (this.$attrs.required && !this.$attrs.disabled) {
          return [this.appRules.required]
        } else {
          return []
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
</style>
