export const errorMessage = {
  tryAgain: [
    { line: 'Please try again or refresh the page.' },
    { line: 'If the error persists contact the help desk at (864) 987-1007' },
  ],
  contactCustomerSupport: [
    { line: 'We apologize, but a serious error has occurred.' },
    { line: 'Please contact customer support at (864) 987-1007.' },
  ],
}
