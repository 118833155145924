// Pathify
import { DataStore } from '@aws-amplify/datastore'
import { make } from 'vuex-pathify'
const _ = require('lodash')

// Data
const state = {
  app_name: 'Quick Quote',
  node_environment: null,
  build_environment: null,
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
      componentName: 'DashboardView',
      admin: false,
      guest: true,
      visible: true,
    },
    {
      title: 'Admin',
      icon: 'mdi-account',
      admin: true,
      guest: false,
      visible: true,
      items: [
        {
          title: 'Customers',
          icon: 'mdi-account-multiple',
          to: '/components/customers/',
          componentName: 'CustomersView',
          admin: true,
          guest: false,
          visible: true,
        },
        {
          title: 'Archived Plans',
          icon: 'mdi-archive-arrow-down',
          to: '/components/archived-plans/',
          componentName: 'PlansArchivedView',
          admin: true,
          guest: false,
          visible: true,
        },
        {
          title: 'Imports',
          icon: 'mdi-database-import',
          to: '/components/imports/',
          componentName: 'ImportsView',
          admin: true,
          guest: false,
          visible: true,
        },
        {
          title: 'Exports',
          icon: 'mdi-database-export',
          to: '/components/exports/',
          componentName: 'ExportsView',
          admin: true,
          guest: false,
          visible: true,
        },
      ],
    },
    {
      title: 'Maintenance',
      icon: 'mdi-auto-fix',
      admin: false,
      guest: false,
      visible: true,
      items: [
        {
          title: 'Folders',
          icon: 'mdi-folder-edit-outline',
          to: '/components/folders/',
          componentName: 'FoldersView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Plans',
          icon: 'mdi-floor-plan',
          to: '/components/plans/',
          componentName: 'PlansView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Divisions',
          icon: 'mdi-division',
          to: '/components/divisions/',
          componentName: 'DivisionsView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Assemblies',
          icon: 'mdi-toy-brick-outline',
          to: '/components/assemblies/',
          componentName: 'AssembliesView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Prompt Groups',
          icon: 'mdi-variable',
          to: '/components/prompt-groups/',
          componentName: 'PromptGroupsView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Product Categories',
          icon: 'mdi-bulletin-board',
          to: '/components/categories/',
          componentName: 'CategoriesView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Parts/SKUs',
          icon: 'mdi-factory',
          to: '/components/parts/',
          componentName: 'PartsView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Delivery Loads',
          icon: 'mdi-truck-delivery-outline',
          to: '/components/delivery-loads/',
          componentName: 'DeliveryLoadsView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Usages',
          icon: 'mdi-wall',
          to: '/components/usages/',
          componentName: 'UsagesView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Estimators',
          icon: 'mdi-head-cog-outline',
          to: '/components/estimators/',
          componentName: 'EstimatorsView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Salespeople',
          icon: 'mdi-head-plus-outline',
          to: '/components/salespeople/',
          componentName: 'SalespeopleView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Projects',
          icon: 'mdi-folder-star-multiple-outline',
          to: '/components/projects/',
          componentName: 'ProjectsView',
          admin: true,
          guest: false,
          visible: false,
        },
      ],
    },
    {
      title: 'Tools',
      icon: 'mdi-tools',
      admin: false,
      guest: false,
      visible: true,
      items: [
        {
          title: 'Plan Builder',
          icon: 'mdi-floor-plan',
          to: '/components/plan-builder',
          componentName: 'PlanBuilderView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Copy',
          icon: 'mdi-content-copy',
          to: '/components/copy/',
          componentName: 'CopyView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Takeoff',
          icon: 'mdi-rocket-launch-outline',
          to: '/components/takeoff/',
          componentName: 'TakeoffView',
          admin: false,
          guest: false,
          visible: true,
        },
        {
          title: 'Global Replace',
          icon: 'mdi-file-replace-outline',
          to: '/components/global-replace',
          componentName: 'GlobalReplaceView',
          admin: false,
          guest: false,
          visible: true,
        },
      ],
    },
    {
      title: 'Reports',
      icon: 'mdi-animation',
      to: '/components/reports/',
      componentName: 'ReportsView',
      admin: false,
      guest: true,
      visible: true,
    },
    {
      title: 'Google Maps',
      icon: 'mdi-map-marker',
      to: '/maps/google/',
      componentName: 'GoogleMapsView',
      admin: true,
      guest: false,
      visible: false,
    },
    /*
    {
      title: 'Regular Tables',
      icon: 'mdi-clipboard-outline',
      to: '/tables/regular/',
    },
    {
      title: 'Typography',
      icon: 'mdi-format-font',
      to: '/components/typography/',
    },
    {
      title: 'Icons',
      icon: 'mdi-chart-bubble',
      to: '/components/icons/',
    },
    {
      title: 'Notifications',
      icon: 'mdi-bell',
      to: '/components/notifications/',
    },
     */
  ],
  rules: {
    required: value => !!value || 'Required.',
    isPrimaryId: value => {
      const pattern = /^[a-zA-Z0-9-_]+$/
      return pattern.test(value) || 'Invalid primary id.'
    },
    noSpaces: value => {
      const pattern = /^[^\s-]+$/
      return pattern.test(value) || 'Must not contain spaces.'
    },
    isNumeric: value => {
      let isValid = true

      if (value) {
        if (value.length > 0) {
          const pattern = /^[0-9]+$/
          isValid = pattern.test(value)
        }
      }

      return isValid || 'Must be numeric.'
    },
    counter: value => value.length <= 20 || 'Max 20 characters',
    idExists (value, existingData, errorMessage = 'ID already exists.') {
      let isValid = true

      if (value && value.length > 0) {
        for (const thisOption of existingData) {
          if (thisOption.id === value) {
            isValid = false
            break
          }
        }
      }

      return isValid || errorMessage
    },
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'Invalid e-mail.'
    },
    validAddress1: value => {
      let isValid = true
      isValid = true
      return isValid || 'Invalid Address 1.'
    },
    validCity: value => {
      let isValid = true

      if (value) {
        if (value.length > 0) {
          const pattern = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/
          isValid = pattern.test(value)
        }
      }

      return isValid || 'Invalid City.'
    },
    validState: value => {
      let isValid = true

      if (value) {
        if (value.length > 0) {
          const pattern = /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/
          isValid = pattern.test(value)
        }
      }

      return isValid || 'Invalid U.S. State.'
    },
    validZip: value => {
      let isValid = true

      if (value) {
        if (value.length > 0) {
          const pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/
          isValid = pattern.test(value)
        }
      }

      return isValid || 'Invalid U.S. Zip Code.'
    },
    isPhone: value => {
      let isValid = true

      if (value) {
        if (value.length > 0) {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
          isValid = pattern.test(value)
        }
      }

      return isValid || 'Invalid U.S. Phone Number.'
    },
    compareTimes (time1, time2, errorMessage = 'Invalid time.') {
      let validTime = true
      if (time1 && time2) {
        let parsedTime1 = 0
        let parsedTime2 = 0
        if (time1.length > 0 && time1.toString().includes(':') &&
          time2.length > 0 && time2.toString().includes(':')) {
          parsedTime1 = time1.split(':')[0] * 3600 + time1.split(':')[1] * 60
          parsedTime2 = time2.split(':')[0] * 3600 + time2.split(':')[1] * 60

          if (parsedTime1 >= parsedTime2) {
            validTime = false
          }
        }
      }
      return validTime || errorMessage
    },
    isCurrency (value, errorMessage = 'Invalid Currency Value.') {
      let validCurrency = true
      if (value && value.length > 0) {
        const pattern = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/
        validCurrency = pattern.test(value)
      }
      return validCurrency || errorMessage
    },
    validOption (selectedOption, availableOptions, errorMessage = 'Option does not exist in the list.') {
      let valid = true

      if (selectedOption) {
        let selectedDescription = ''
        if ({}.hasOwnProperty.call(selectedOption, 'description')) {
          selectedDescription = selectedOption.description
        } else {
          selectedDescription = selectedOption
        }

        if (selectedDescription.length > 0) {
          valid = false
          for (const thisOption of availableOptions) {
            if (thisOption.description === selectedDescription) {
              valid = true
              break
            }
          }
        }
      }

      if (valid) {
        return valid
      } else {
        return errorMessage
      }
    },
    imageSize (image, imageType = 'Image') {
      return !image || image.size < 2000000 || `${imageType} size should be less than 2 MB!`
    },
  },
  headers: {
    headerActions: {
      text: 'Actions',
      align: 'end',
      value: 'actions',
      sortable: false,
      filterable: false,
      groupable: false,
      width: '1%',
      class: 'primary--text font-weight-bold',
    },
    headerExpandDetail: {
      text: '',
      value: 'data-table-expand',
      sortable: false,
      filterable: false,
      groupable: false,
      width: '1%',
    },
  },
}

const mutations = {
  ...make.mutations(state),
}

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    state.node_environment = process.env.NODE_ENV

    if (state.node_environment.toLowerCase() === 'production') {
      state.build_environment = process.env.VUE_APP_BUILD_ENV
    } else {
      state.build_environment = 'Stage'
    }
  },

  refreshKeys: async ({ dispatch, rootState }) => {
    // TODO: retrieveAll?  Do we need to put all the selected items back?
    const selectedDivision = { ...rootState.division.divisions.selectedItem }
    const selectedAssembly = { ...rootState.assembly.assemblies.selectedItem }

    dispatch('plan/retrieveAll', null, { root: true })
    dispatch('folder/retrieveAll', null, { root: true })
    dispatch('category/retrieveAll', null, { root: true })

    await dispatch('plan/setSelected', rootState.plan.plans.selectedItem, { root: true })
    await dispatch('division/setSelected', selectedDivision, { root: true })
    await dispatch('assembly/setSelected', selectedAssembly, { root: true })
  },

  refreshSupport: async ({ dispatch }) => {
    dispatch('part/retrieveAll', null, { root: true })
    dispatch('promptgroup/retrieveAll', null, { root: true })
    dispatch('deliveryload/retrieveAll', null, { root: true })
    dispatch('usage/retrieveAll', null, { root: true })
    dispatch('salesperson/retrieveAll', null, { root: true })
    dispatch('estimator/retrieveAll', null, { root: true })
  },

  retrieveAll: async ({ dispatch }) => {
    dispatch('refreshKeys')
    dispatch('refreshSupport')
  },

  fetch: ({ commit }) => {
    const local = localStorage.getItem('vuetify@app') || '{}'
    const app = JSON.parse(local)

    for (const key in app) {
      commit(key, app[key])
    }

    if (app.dark === undefined) {
      commit('dark', window.matchMedia('(prefers-color-scheme: dark)'))
    }
  },

  // http://maps.googleapis.com/maps/api/geocode/json?address=29212&sensor=true
  retrieveCityState: ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'ziptasticapi'

    const actionEndPoint = `https://${thisAction}.com`
    const url = `${actionEndPoint}/${payload.zip}`

    const options = {
      method: 'get',
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if ({}.hasOwnProperty.call(jsonResponse, 'city')) {
          payload.city = jsonResponse.city
        }
        if ({}.hasOwnProperty.call(jsonResponse, 'state')) {
          payload.state = jsonResponse.state
        }
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },
}

const getters = {
  getAdminPages: () => () => {
    const adminPages = []

    // TODO: This won't work if app.items ever go more than 1 level deep
    for (const appItem of state.items) {
      if (appItem.items) {
        for (const childItem of appItem.items) {
          if (childItem.admin) {
            adminPages.push(childItem)
          }
        }
      } else {
        if (appItem.admin) {
          adminPages.push(appItem)
        }
      }
    }

    return adminPages
  },

  getIndexById: () => (payload, getId) => {
    let index = -1

    for (const currentData of payload) {
      index += 1
      if (currentData.id === getId) {
        break
      }
    }

    return index
  },

  getDataById: () => (payload, getId) => {
    return payload.filter(data => data.id === getId)
  },

  getUpdated: () => (payload) => {
    return payload.filter(thisItem => thisItem.updated === true)
  },

  getOptions: () => (payload) => {
    const options = []

    for (const currentData of payload) {
      options.push(`${currentData.id} - ${currentData.description}`)
    }

    return options
  },

  getAppItem: () => (componentName) => {
    let thisItem = null

    for (const item of state.items) {
      if (item.componentName === componentName) {
        thisItem = { ...item }
        break
      }
    }

    if (!thisItem) {
      const parentItems = _.filter(state.items, { items: [{ componentName: componentName }] })

      for (const parentItem of parentItems) {
        for (const item of parentItem.items) {
          if (item.componentName === componentName) {
            thisItem = item
            break
          }
        }
      }
    }

    return thisItem
  },

  formatCurrency: () => (value) => {
    let formattedValue = value
    if (value && value.length > 0) {
      const validCurrency = state.rules.isCurrency(value)
      if (validCurrency === true) {
        // const val = (value / 1).toFixed(2)
        formattedValue = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    }
    return formattedValue
  },

  formatPhone: () => (value) => {
    let formattedValue = value

    if (value) {
      const x = value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)

      formattedValue = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
    }

    return formattedValue
  },

  formatDate: () => (thisDate) => {
    if (thisDate === null) return ''

    const d = new Date(thisDate)
    const year = d.getFullYear().toString()
    const month = ('0' + (d.getMonth() + 1).toString()).slice(-2)
    const day = ('0' + d.getDate().toString()).slice(-2)

    return `${month}/${day}/${year}`
  },

  addDays: () => (toDate, numberOfDays) => {
    if (numberOfDays === null) return null
    let workDate = new Date()

    // Using getTime instead of getDate does not take DST into consideration. Does it matter in this instance?
    // However, this will account for end of month and leap year AFAIK
    if (toDate !== null) workDate = new Date(toDate)
    workDate.setTime(workDate.getTime() + (numberOfDays * 24 * 60 * 60 * 1000))

    return workDate
  },

  getValidDate: () => (dateString) => {
    let thisDate = dateString

    if (thisDate === null) {
      thisDate = new Date(Date.now())
    }

    const thisTime = new Date(thisDate.toString()).getTime()

    if (Number.isNaN(thisTime)) {
      return null
    } else {
      return new Date(thisTime)
    }
  },

  generatePlanId: (state) => (originalData) => {
    const d = new Date()
    const idYear = d.getFullYear().toString().slice(-2)
    const idMonth = ('0' + (d.getMonth() + 1).toString()).slice(-2)
    const idDay = ('0' + d.getDate().toString()).slice(-2)
    let validId = false
    let idSeq = 0
    let newId = ''

    while (validId !== true) {
      idSeq++
      newId = idYear.toString() + idMonth.toString() + idDay.toString() + '-' + idSeq.toString()
      validId = state.rules.idExists(newId, originalData)
    }

    return newId
  },

  getDocumentsPath: (state) => (thisPlan) => {
    return `${state.build_environment}/customer/${thisPlan.client_id}/plans/${thisPlan.id}`
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
